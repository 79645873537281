import React, {useContext} from 'react'
import {ItemJasaContext} from "../context/ContextProvider"
import TableColumns from "./Columns"
import {PageLayout} from "../../../../templates"
import {Link} from "react-router-dom"
import {FeatureButton, Search, Table} from "../../../../../components/atoms"
import {Pagination} from "antd"
import FilterItem from "../filteritem/FilterItem"
import Category from "../category/Category"

import "./list.scss"

const List: React.FC = () => {
    const context = useContext(ItemJasaContext)
    const {
        fields,
        loading,
        visible,
        hideMenu,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handleHideMenu,
        handlePageChange,
        toggle,
        handleUpdateStatus,
        handleDelete
    } = context.useModelList()

    const {columns} = TableColumns(
        loading,
        visible,
        toggle,
        handleDelete,
        handleUpdateStatus
    )

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Link to="/item/tambah">
                            <FeatureButton label={<div><i className="fas fa-plus-square" /> Tambah</div>} /> 
                        </Link>
                        <FilterItem handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                        {hideMenu ? (
                            <span>
                                <Category />
                                <i 
                                    className="fas fa-chevron-circle-left"
                                    style={{cursor: "pointer", color: "#009b00"}}
                                    onClick={handleHideMenu} 
                                />
                            </span>
                         ) : (
                            <i 
                                className="fas fa-chevron-circle-right" 
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={handleHideMenu}
                            />
                         )}
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.payload}
                            rowKey="id"
                            pagination={false}
                            // scroll={{y: 550}}
                            loading={loading === "fetch" ? true : false}
                        />
                        <div style={{padding: "20px 0px", textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={fields.limit} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                            />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default List
