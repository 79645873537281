import React, {useState} from 'react'
import {Row, Col, Input} from "antd"

interface Props {
    name:string,
    id:string,
    handleEdit: (id:string, value:string) => void,
    handleDelete: (id:string) => void,
}

const CategoryBox: React.FC<Props> = (props) => {
    const [editStat, setEditStat] = useState<boolean>(false)
    const [name, setName] = useState<string>("")

    const handleChangeEditStat = () => {
        setEditStat(!editStat)
    }

    const handleChangeInput = (e:any) => {
        setName(e.target.value)
    }

    const handleSaveEdit = () => {
        setEditStat(false)
        props.handleEdit(props.id, name)
    }

    return (
        <Row className="category-box">
            <Col span={21}>
                {editStat ? <Input defaultValue={props.name} onChange={handleChangeInput} /> : props.name}
            </Col>
            <Col span={3} className="action-section">
                {editStat ? (
                    <div>
                        <i className="far fa-save save-icon" onClick={handleSaveEdit} /> <i className="fas fa-times cancel-icon" onClick={handleChangeEditStat} />
                    </div>
                ) : (
                    <div>
                        <i className="far fa-edit edit-icon" onClick={handleChangeEditStat} /> <i className="far fa-trash-alt delete-icon" onClick={() => props.handleDelete(props.id)} />
                    </div>
                )}
            </Col>
        </Row>
    )
}

export default CategoryBox
