import React from 'react'
import {Form, DatePicker, Button} from "antd"

import "./formfilterexport.scss"
const { RangePicker } = DatePicker;

interface Props {
    onFinish: (values: {}) => void,
    toggle: () => void
}

const FormFilterExport: React.FC<Props> = (props) => {
    return (
        <Form 
            layout="vertical" 
            requiredMark={false}
            onFinish={props.onFinish}
            preserve={false}
        >
             <Form.Item 
                label="Tanggal Topup" 
                name="topupDate"
            >
                <RangePicker 
                    className="date-create-update" 
                    format="DD MMMM YYYY" 
                    placeholder={["Pilih tanggal", "Pilih tanggal"]}
                />
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
                <div style={{ textAlign: "center" }}>
                    <Button key="back" className="cancel-button" onClick={props.toggle}>
                        Batal
                    </Button>{" "}
                    <Button
                        key="submit"
                        type="primary"
                        className="ok-button"
                        htmlType="submit"
                        // loading={loading}
                    >
                        Oke
                    </Button>
                </div>
            </Form.Item>
        </Form>
    )
}

export default FormFilterExport
