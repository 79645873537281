import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {DetailPageLayout} from "../../../templates"
import {Row, Col, Upload, Spin, Form, Input, Select, Switch, Button, notification} from "antd"
import { LoadingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
// import UploadPhoto from "./uploadPhoto/UploadPhoto"
import Schedule from "./schedule/Schedule"
import {Link} from "react-router-dom"

import "./formservice.scss"

interface Props {
    onFinish:(values:{
        merchantID:string|null,
        picURL:string[],
        name: string, 
        address:string,
        cancelationFee:any,
        category:string|null, 
        description:string,
        durationBfrReservation:number|null,
        isActive:boolean,
        isOpen:boolean,
        isCancelationPercentage:any, 
        maxBooking:any, 
        maxDuration:any, 
        minDuration:any,
        phone:string
    }) => void
    fields:any,
    addUploadPhoto:() => void,
    handleSetImgURL:(url:string, index:number) => void,
    handleCheckedDay:(index:number, active:boolean) => void,
    operations:{day:number, active?:boolean, timeRate:{}[]}[],
    handleSubmitTimeRate:(values:{
        deposit:any, 
        discount:any, 
        timeStart:string,
        timeEnd:string,
        isDiscPercentage:any,
        rate:any,
        hour:any
    }, indextime:number, indexday:number) => void,
    handleSubmitSchedule:() => void
}

const FormService: React.FC<Props> = (props) => {
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [hours, setHours] = useState<number[]>([])
    const [fields, setFields] = useState<{
        id:number,
        category:string
    }[]>([])

    const loopingHour = () => {
        const grouphours = []
        for (let i:number = 0; i < 13; i++) {
            grouphours.push(i)
        }
        setHours(grouphours)
    }

    const handleUploadImage = async (info:any, key:number) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "venue")
        try {
            const res = await axios.post(`${base_url}/v3/utility/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            if (res.status === 200) {
                props.handleSetImgURL(res.data.payload.fullpath, key)
                setLoadingUpload(false)
            }
        } catch (error) {
            console.log(error.response);
            setLoadingUpload(false)
        }
    }

    const fetchData = async() => {
        // setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/hobby/category`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            // console.log(res);
            setFields(res.data.payload)
            // setLoading(false)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            // setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
        loopingHour()
    }, [])

    return (
        <DetailPageLayout>
            <Form onFinish={props.onFinish}>
                <Row className="form-service-container">
                    <Col span={24} className="left-section-form-service">
                        <Row style={{paddingBottom: "5px"}}>
                            {props.fields.venue.picURL.map((eachUrl:string, key:number) => (
                                <Col className="container-upload-photo-venue" key={key}>
                                    {/* <UploadPhoto 
                                        index={key}
                                        handleSetImgURL={props.handleSetImgURL}
                                        url={eachUrl}
                                    /> */}
                                    <Upload 
                                        showUploadList={false} 
                                        onChange={(info) => handleUploadImage(info, key)}
                                    >
                                        <div className="upload-photo">
                                            {loadingUpload ? (
                                                <div className="container-loading-upload-photo-venue">
                                                    <Spin indicator={<LoadingOutlined className="loading-icon-upload-photo-venue" spin />} />
                                                </div>
                                            ) : (
                                                eachUrl === "" ? (
                                                    <div className="container-icon-photo-venue">
                                                        <i className="far fa-image fa-6x" />
                                                    </div>
                                                ) : (
                                                    <img src={eachUrl} alt="merchant-hobby-logo" className="venue-photo-preview" />
                                                )
                                            )}
                                        </div>
                                    </Upload>
                                </Col>
                            ))}
                            {props.fields.venue.picURL.length < 5 ? (
                                <Col>
                                    <div className="add-photo" onClick={props.addUploadPhoto}>
                                        <div className="container-icon-add-photo">
                                            <i className="fas fa-plus fa-4x" />
                                        </div>
                                    </div>
                                </Col>
                            ) : null}
                        </Row>
                        <Row>
                            <Col span={6}>Nama Service</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="name"
                                    initialValue={props.fields.venue.name}
                                    rules={[{ required: true, message: 'Nama service wajib diisi!' }]}
                                >
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input nama service" 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Alamat</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="address"
                                    initialValue={props.fields.venue.address}
                                    rules={[{ required: true, message: 'Alamat service wajib diisi!' }]}
                                >
                                    <Input.TextArea
                                        className="form-input" 
                                        placeholder="Input alamat service" 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>No. Telepon</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="phone"
                                    initialValue={props.fields.venue.phone}
                                    rules={[
                                        { required: true, message: 'Nomor Telepon merchant wajib diisi!'},
                                        { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                    ]}
                                >
                                    <Input
                                        className="form-input" 
                                        placeholder="Input nomor telepon" 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Kategori</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="category"
                                    initialValue={props.fields.venue.category}
                                    rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                                >
                                    <Select placeholder="Pilih kategori venue">
                                        {fields.map((field, key) => (
                                            <Select.Option key={key} value={field.category}>{field.category}</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Batas Waktu Sebelum Booking</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="durationBfrReservation"
                                    initialValue={props.fields.venue.durationBfrReservation}
                                    rules={[{ required: true, message: 'Batas Waktu Sebelum Booking wajib dipilih!' }]}
                                >
                                    <Select placeholder="Pilih batas waktu">
                                        {hours.map((hour, key) => (
                                            <Select.Option key={key} value={hour * 3600}>{hour} Jam</Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Deskripsi</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="description"
                                    initialValue={props.fields.venue.description}
                                    rules={[{ required: true, message: 'Deskripsi wajib diisi!' }]}
                                >
                                    <Input.TextArea
                                        className="form-input" 
                                        placeholder="Input deskripsi service" 
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Maksimal User Memesan</Col>
                            <Col span={7} style={{paddingRight: "10px"}}>
                                <Form.Item
                                    name="maxBooking"
                                    initialValue={props.fields.venue.maxBooking}
                                    rules={[
                                        { required: true, message: 'Maksimal user memesan wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,3}$"), message: 'Maksimal user yang memesan melebihi batas!'}
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        className="form-input" 
                                        placeholder="Input maksimal user memesan"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Input
                                    className="form-input" 
                                    value="Orang"
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Minimal Durasi Booking</Col>
                            <Col span={7} style={{paddingRight: "10px"}}>
                                <Form.Item
                                    name="minDuration"
                                    initialValue={props.fields.venue.minDuration !== null ? props.fields.venue.minDuration/3600 : null}
                                    rules={[
                                        { required: true, message: 'Minimal durasi booking wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,3}$"), message: 'Minimal durasi booking melebihi batas!'}
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        className="form-input" 
                                        placeholder="Input minimal durasi booking" 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Input
                                    className="form-input" 
                                    value="Jam"
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Maksimal Durasi Booking</Col>
                            <Col span={7} style={{paddingRight: "10px"}}>
                                <Form.Item
                                    name="maxDuration"
                                    initialValue={props.fields.venue.maxDuration !== null ? props.fields.venue.maxDuration/3600 : null}
                                    rules={[
                                        { required: true, message: 'Maksimal durasi booking wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,3}$"), message: 'Maksimal durasi booking melebihi batas!'}
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        className="form-input" 
                                        placeholder="Input maksimal durasi booking"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Input
                                    className="form-input" 
                                    value="Jam"
                                    disabled
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Biaya Pembatalan</Col>
                            <Col span={7} style={{paddingRight: "10px"}}>
                                <Form.Item
                                    name="cancelationFee"
                                    initialValue={props.fields.venue.cancelationFee}
                                    rules={[
                                        { required: true, message: 'Biaya Pembatalan wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Biaya pembatalan melebihi batas!'}
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        className="form-input" 
                                        placeholder="Input biaya pembatalan" 
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item 
                                    name="isCancelationPercentage"
                                    initialValue={props.fields.venue.isCancelationPercentage === true ? "%" : props.fields.venue.isCancelationPercentage === false ? "idr" : null}
                                    rules={[{ required: true, message: 'Wajib diisi!' }]}
                                >
                                    <Select
                                        placeholder="satuan"
                                    >
                                        <Select.Option value="%">%</Select.Option>
                                        <Select.Option value="idr">IDR</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Status Aktif</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="isActive"
                                    valuePropName= "checked"
                                    initialValue={props.fields.venue.isActive}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Status Buka/Tutup</Col>
                            <Col span={9}>
                                <Form.Item
                                    name="isOpen"
                                    valuePropName= "checked"
                                    initialValue={props.fields.venue.isOpen}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={6}>Jadwal dan Harga</Col>
                            <Col span={9}>
                                <Schedule 
                                    handleCheckedDay={props.handleCheckedDay}
                                    operations={props.operations}
                                    handleSubmitTimeRate={props.handleSubmitTimeRate}
                                    handleSubmitSchedule={props.handleSubmitSchedule}
                                />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "30px"}}>
                            <Col>
                                <Form.Item>
                                    <Button
                                        htmlType="submit" 
                                        className="button-save-form-venue"
                                    >
                                        Simpan
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Link to="/service">
                                    <Button 
                                        className="button-cancel-form-venue"
                                    >
                                        Batal
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </DetailPageLayout>
    )
}

export default FormService
