import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../config/url-config"
import {FormatDate, FormatRp} from "../../../helpers/Formatter"
import {PageLayout} from "../../templates"
import FilterVoucher from "./filterVoucher/FilterVoucher"
import {Search, Table, FeatureButton} from "../../../components/atoms"
import {Pagination, notification} from "antd"

import "./voucher.scss"

const Voucher: React.FC = () => {
    const [fields, setFields] = useState<{
        totalData:number,
        data:{}[]
    }>({
        totalData: 0,
        data:[]
    })
    const [queryParams, setQueryParams] = useState({
        accountID: localStorage.getItem("accountID"),
        pageNumber: 1,
        pageLimit: 10,
    })
    const [loading, setLoading] = useState<boolean>(false)


    // FUNCTION FOR GET LIST OF VOUCHER HOBBY //
    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v2/mopayment/deposit/transactions`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data voucher',
                placement:"topRight"
            });
            setLoading(false)
        }
    }
    // ---------------------------------- //


    useEffect(() => {
        fetchData(queryParams)
    }, [queryParams])


    // FUNCTION FOR CHANGE PAGE //
    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setQueryParams({...queryParams, pageNumber: page})
    }
    // ---------------------------------- //


    // FUNCTION FOR SEARCH //
    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            if (e.target.value === "") {
                setQueryParams({
                    accountID: localStorage.getItem("accountID"),
                    pageNumber: 1,
                    pageLimit: 10,
                })
            } else {
                setQueryParams({...queryParams, ...{transactionNumber: e.target.value}})
            }
         }
    }
    // ---------------------------------- //

    const handleFilter = (values:{}) => {
        setQueryParams({...queryParams, ...values})
    }

    const handleReset = () => {
        setQueryParams({
            accountID: localStorage.getItem("accountID"),
            pageNumber: 1,
            pageLimit: 10,
        })
    }


    console.log(fields);
    
    const columns = [
        {
            title: "Nomor Transaksi",
            render: (data:{transactionNumber:string}) => (
                <div style={{fontWeight: "bold", color: "black"}}>{data.transactionNumber}</div>
            ),
            width: "11%"
        },
        {
            title: "Tanggal Transaksi",
            render: (data:{transactionDate:string}) => (
                <div>{FormatDate(data.transactionDate)}</div>
            ),
            width: "15%"
        },
        {
            title: "Jenis Transaksi",
            dataIndex: "type",
            width: "10%"
        },
        {
            title: "Deposit Sebelum",
            render: (data:{balanceBefore:number}) => (
                <div>{FormatRp(data.balanceBefore)}</div>
            ),
            width: "15%"
        },
        {
            title: "Jumlah",
            render: (data:{amount:number}) => (
                <div>{FormatRp(data.amount)}</div>
            )
        },
        {
            title: "Deposit Sesudah",
            render: (data:{balanceAfter:number}) => (
                <div>{FormatRp(data.balanceAfter)}</div>
            ),
            width: "15%"
        },
        {
            title: "Asal Transaksi",
            dataIndex: "transactionType"
        },
        {
            title: "Status",
            dataIndex: "status"
        },
    ]

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search onKeyPress={(e:any) => handleSubmitSearch(e)} />}
                leftTop={
                    <div>
                        <FilterVoucher handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="transactionID"
                            pagination={false}
                            loading={loading}
                            // scroll={{y: 550}}
                        />
                        <div style={{padding: "20px 0px", textAlign: "right"}}>
                            <Pagination 
                                current={queryParams.pageNumber} 
                                pageSize={10} 
                                total={fields.totalData} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default Voucher
