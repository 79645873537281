import React from 'react'
import {Bar} from "react-chartjs-2"

import "./barchart.scss"

interface Props {
    chartTitle: string,
    labels?: string[],
    datasets?: {label: string, data: number[], backgroundColor: string}[],
    stepSizeY?: number
}

const BarChart: React.FC<Props> = (props) => {
    return (
            <Bar 
                data={{
                    labels: props.labels,
                    datasets: props.datasets
                }}
                options={{
                    aspectRatio: 1,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                stepSize: props.stepSizeY
                            },
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }
                        }],
                        xAxes: [{
                            gridLines: {
                                color: "rgba(0, 0, 0, 0)",
                            }   
                        }]
                    },
                    title: {
                        display: true,
                        text: props.chartTitle,
                        position: "top",
                        fontSize: 18
                    },
                    legend: {
                        display: true,
                        position: "bottom"
                    }
                }}
            />
    )
}

export default BarChart
