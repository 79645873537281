import React, {useEffect, useState} from 'react'
import {base_url} from "../../../../../config/url-config"
import axios from "axios"
import {Row, Col, Pagination, notification} from "antd"
// import {data} from "./MerchantModel"
import {MerchantBox} from "../../../../../components/molecules"

const ListMerchantJasa: React.FC = () => {
    const [fields, setFields] = useState<{currentPage:number, totalPage:number, limit:number, data:[]}>({
        currentPage: 1,
        totalPage: 1,
        limit: 10,
        data: []
    })
    const [params, setParams] = useState<{}>({
        limit: 10,
        page: 1
    })

    const fetchData = async (queryparams:{}) => {
        try {
            const res = await axios.get(`${base_url}/v3/merchant/me`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: queryparams
            })

            if (res.status === 200) {
                setFields(res.data.payload)
            }
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number|undefined, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleClick = (id:string, accountID:string) => {
        localStorage.setItem("merchantID", id)
        localStorage.setItem("accountID", accountID)
    }    
    
    return (
        <div>
            <Row gutter={[0, 32]} style={{paddingTop: "10px"}}>
                {fields.data.map((field:{image: string, name: string, id: string, paymentAccountID:string}, key:number) => (
                    <Col key={key} span={4} offset={key !== 0 && key !== 5 ? 1 : 0}>
                        <MerchantBox 
                            logo={field.image} 
                            name={field.name} 
                            link="/" 
                            editLink={`/merchant/edit?id=${field.id}`}
                            onClick={() => handleClick(field.id, field.paymentAccountID)} 
                        />
                    </Col>
                ))}
                {/* <Col span={4} offset={fields.length % 5 !== 0 ? 1 : 0}>
                    <div className="box-create-merchant-container">
                        <div style={{textAlign: "center", paddingTop: "45px"}}>
                            <i className="fas fa-store fa-4x"  />
                        </div>
                        <div style={{textAlign: "center", fontSize: "18px", paddingTop: "20px"}}>
                            <div>Tambah</div>
                            <div>Merchant</div>
                        </div>
                    </div>
                </Col> */}
            </Row>
            {fields.data.length > 10 ? (
                <div className="pagination-container">
                    <Pagination current={fields.currentPage} pageSize={10} total={fields.totalPage * fields.limit} onChange={handlePageChange} />
                </div>
            ) : null}
        </div>
    )
}

export default ListMerchantJasa
