export interface IPagination {
    page: number
    limit: number
    status: string
    totalPage: number
    totalData: number
}

export interface IList {
    message: string
    pagination: IPagination
    payload: IDetail[]
    statusCode: number
}

export interface IDetail {
    id: string
    orderItems: IOrderItem[]
    merchantId: string
    merchantName: string
    userId: string
    username: string
    userAddress: string
    userEmail: string
    userPhone: string
    userNote: string
    email: string
    phone: string
    invoiceNumber: string
    latitude: string
    longitude: string
    address: string
    totalPrice: number
    totalRaw: number
    totalDiscount: number
    totalOrder: number
    deliveryCost: number
    adminFee: number
    totalAmount: number
    totalPromo: number
    totalBayar: number
    deliveryState: string
    driverName: string
    driverPhone: string
    driverNPhonee: string
    status: string
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    rating: null | number
    paymentDetail: IPaymentDetail
}

export interface IPaymentDetail {
    adminFee: number
    bank: string
    bankName: string
    checkoutURL: string
    duration: number
    expiredAt: string
    invoiceAmount: number
    invoiceID: string
    invoiceNumber: string
    paymentID: string
    paymentMethod: string
    paymentProvider: string
    paymentState: string
    token: string
    totalAmount: number
    transactionDate: string
    trxID: string
    userPayNumber: string
}

export interface IOrderItem {
    ID: string
    orderId: string
    productId: string
    productName: string
    qty: number
    price: number
    discount: number
    discountIsInPercentage: boolean
    note: string
    unit: string
    createdAt: string
    updatedAt: string
}

export const initialDataList = {
    message: "",
    pagination: {
        page: 1,
        limit: 10,
        status: "",
        totalPage: 1,
        totalData: 0
    },
    payload: [],
    statusCode: 0
}

export const initialDataDetail = {
    id: "",
    orderItems: [],
    merchantId: "",
    merchantName: "",
    userId: "",
    username: "",
    userAddress: "",
    userEmail: "",
    userPhone: "",
    userNote: "",
    email: "",
    phone: "",
    invoiceNumber: "",
    latitude: "",
    longitude: "",
    address: "",
    totalPrice: 0,
    totalRaw: 0,
    totalDiscount: 0,
    totalOrder: 0,
    deliveryCost: 0,
    adminFee: 0,
    totalAmount: 0,
    totalPromo: 0, 
    totalBayar: 0,
    deliveryState: "",
    driverName: "",
    driverPhone: "",
    driverNPhonee: "",
    status: "",
    createdAt: "",
    updatedAt: "",
    createdBy: "",
    updatedBy: "",
    rating: 0,
    paymentDetail: {
        adminFee: 0,
        bank: "",
        bankName: "",
        checkoutURL: "",
        duration: 0,
        expiredAt: "",
        invoiceAmount: 0,
        invoiceID: "",
        invoiceNumber: "",
        paymentID: "",
        paymentMethod: "",
        paymentProvider: "",
        paymentState: "",
        token: "",
        totalAmount: 0,
        transactionDate: "",
        trxID: "",
        userPayNumber: ""
    }
}