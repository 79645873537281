import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router"
import {useLocation, Link} from "react-router-dom"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {notification, Form, Row, Col, Upload, Input, Button, Select, Switch, Spin} from "antd"
import { LoadingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {DetailPageLayout} from "../../../../templates"
import {Loading, OkButton, BackButton} from "../../../../../components/atoms"
import Tag from "./tag/Tag"
import FormOperationalHour from "./formoperationalhour/FormOperationalHour"
import FormBankAccount from "./formbankaccount/FormBankAccount"

import "./editmerchantjasa.scss"

const EditMerchantJasa: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const merchantId = location.search.split("=")[1]
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        id:string,
        image:string,
        name:string,
        description:string,
        tags:string[],
        maxDistance:number|null,
        merchantFee:number|null,
        merchantFeeType:string,
        address:string,
        email:string,
        phone:string,
        latitude:string,
        longitude:string,
        isActive:boolean,
        status:string,
        operational:{},
        bankDetail: {
            accountName:string,
            accountNumber:string,
            bankName:string
        }
    }>({
        id: "",
        image: "",
        name: "",
        description: "",
        tags: [],
        maxDistance: null,
        merchantFee: null,
        merchantFeeType: "FIXED",
        address: "",
        email: "",
        phone: "",
        latitude: "",
        longitude: "",
        isActive: false,
        status: "CLOSE",
        operational: {},
        bankDetail: {
            accountName: "",
            accountNumber: "",
            bankName: ""
        }
    })
    const [newTag, setNewTag] = useState<string>("")
    const [operational, setOperational] = useState<{
        active?:boolean, 
        day:number, 
        open:string|null, 
        close:null|string
    }[]>([
        {active: false, day: 0, open: null, close: null},
        {active: false, day: 1, open: null, close: null},
        {active: false, day: 2, open: null, close: null},
        {active: false, day: 3, open: null, close: null},
        {active: false, day: 4, open: null, close: null},
        {active: false, day: 5, open: null, close: null},
        {active: false, day: 6, open: null, close: null},
    ])
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)

    // FUNCTION FOR GET MERCHANT DETAIL //
    const fetchData = async(id:string) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/merchant/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {isAll: 1}
            })
            setFields(res.data.payload)
            createNewOperational(res.data.payload.operational)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(merchantId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [merchantId])
    // ---------------------------------- // 


    // FUNCTION FOR MANIPULATE STRUCTUR DATA OF OPERATIONAL //
    const createNewOperational = (operationals:{}) => {
        const weekdays:string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const keys = Object.keys(operationals)
        const values:{open:string, close:string}[] = Object.values(operationals)
        const operationalCopy = [...operational]

        for (let i = 0; i < keys.length; i++) {
            operationalCopy[weekdays.indexOf(keys[i])].active = true
            operationalCopy[weekdays.indexOf(keys[i])].open = values[i].open
            operationalCopy[weekdays.indexOf(keys[i])].close = values[i].close
        }

        console.log(keys);
        console.log(values);
        setOperational(operationalCopy)
    }
    // ---------------------------------- //


    // FUNCTION FOR TAG //
    const handleDeleteTag = (tag:string) => {
        const newarr = fields.tags.filter(oldtag => oldtag !== tag)
        setFields({...fields, tags: newarr})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...fields.tags]
        newarr[index] = tag
        setFields({...fields, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...fields.tags, newTag]
            setFields({...fields, tags: newarr})
            setNewTag("")
        }
    }
    // ---------------------------------- //


    // FUNCTION FOR BANK ACCOUNT //
    const submitBank = (values:{bankName: string, accountName: string, accountNumber: string}) => {
        setFields({...fields, bankDetail: values})
    }
    // ---------------------------------- //


    // FUNCTION FOR FORM OPERATIONAL HOUR //
    const submitOperationHourStatus = (e:any, index:number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].active = e.target.checked
        setOperational(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time:any, timeString:any, index:number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].open = timeString[0]
        newOperationHours[index].close = timeString[1]
        setOperational(newOperationHours)
    }

    const submitOperationalHour = () => {
        const weekdays:string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newOperationHours = (operational.filter(operationHour => operationHour.active === true))
        const newFixOperational:any = {}
        for (let i = 0; i < newOperationHours.length; i++) {
            newFixOperational[weekdays[newOperationHours[i].day]] = {open: newOperationHours[i].open, close: newOperationHours[i].close}
        }
        setFields({...fields, operational: newFixOperational})
    }
    // ---------------------------------- //


    // FUNCTION FOR SUBMIT FORM EDIT MERCHANT //
    const handleSubmit = async(values:{
        image:string, 
        isActive:boolean,
        status:any,
        tags:string[], 
        operational:{},
        merchantFee:any,
        maxDistance:any,
        latitude:any,
        longitude:any,
        bankDetail:{}
    }) => {
        // values["ID"] = fields.ID
        values["image"] = fields.image
        values["merchantFee"] = parseInt(values.merchantFee)
        values["maxDistance"] = parseInt(values.maxDistance)
        values["latitude"] = parseFloat(values.latitude)
        values["longitude"] = parseFloat(values.longitude)
        values["tags"] = fields.tags
        values["operational"] = fields.operational
        values["bankDetail"] = fields.bankDetail
        values["status"] = values.status ? "OPEN" : "CLOSE"
        console.log(values);

        setLoading(true)
        try {
            const res = await axios.put(`${base_url}/v3/merchant/${fields.id}`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                      'Data merchant berhasil diperbaharui.',
                });
                setLoading(false)
                history.goBack()
            }
        } catch (error) {
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan pada saat memperbaharui data merchant.',
            });
            setLoading(false)
        }
    }
    // ---------------------------------- //

    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/v3/utility/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            setFields({...fields, image: res.data.payload.fullpath})
            setLoadingUpload(false)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat upload foto merchant.',
            });
        }
    }

    console.log(fields);
    console.log(operational);

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <Form 
                        onFinish={handleSubmit}
                    >
                        <Row>
                            <Col span={12} className="left-section">
                                <Row className="row-edit-hobby">
                                    <Col>
                                        <Form.Item
                                            name="image"
                                            valuePropName="file"
                                            initialValue={fields.image}
                                        >
                                            <Upload 
                                                showUploadList={false} 
                                                onChange={handleUpload}
                                            >
                                                <div className="upload-photo">
                                                    {loadingUpload ? (
                                                        <div style={{paddingTop: "25px"}}>
                                                            <Spin indicator={<LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />} />
                                                        </div>
                                                    ) : (
                                                        fields.image === "" ? (
                                                            <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                                <i className="far fa-image fa-6x" />
                                                            </div>
                                                        ) : (
                                                            <img src={fields.image} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                                        )
                                                    )}
                                                </div>
                                            </Upload>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Nama Merchant</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="name"
                                            initialValue={fields.name}
                                            rules={[{ required: true, message: 'Nama merchant wajib diisi!' }]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                placeholder="Input nama merchant"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Deskripsi Merchant</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="description"
                                            initialValue={fields.description}
                                            rules={[{ required: true, message: 'Deskripsi merchant wajib diisi!' }]}
                                        >
                                            <Input.TextArea 
                                                rows={3} 
                                                className="form-input" 
                                                placeholder="Input deskripsi merchant"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Tags</Col>
                                    <Col span={14}>
                                        <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                            {fields.tags.map((tag:string, key:number) => (
                                                <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                                    <Tag
                                                        index={key}
                                                        tag={tag}
                                                        handleEditTag={handleEditTag}
                                                        handleDeleteTag={handleDeleteTag}
                                                    />
                                                </Col>
                                            ))}
                                        </Row>
                                        <Row>
                                            <Col span={19}>
                                                <Input 
                                                    className="form-input" 
                                                    placeholder="Input tag baru" 
                                                    onChange={handleChangeTag} 
                                                    value={newTag}
                                                />
                                            </Col>
                                            <Col span={5} style={{paddingLeft: "12px"}}>
                                                <Button
                                                    className="button-save-edit" 
                                                    style={{width: "100%"}} 
                                                    onClick={submitNewTag}
                                                >
                                                    +
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Jarak Maksimal</Col>
                                    <Col span={10}>
                                        <Form.Item
                                            name="maxDistance"
                                            initialValue={fields.maxDistance}
                                            rules={[
                                                { required: true, message: 'Jarak maksimal wajib diisi!' },
                                                { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Jarak yang anda input melebihi batas!'}
                                            ]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                type="number"
                                                placeholder="Input jarak maksimal"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{paddingLeft: "10px"}}>
                                        <Input 
                                            className="form-input"
                                            value="meter"
                                            disabled
                                        />
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Sharing Fee</Col>
                                    <Col span={10}>
                                        <Form.Item
                                            name="merchantFee"
                                            initialValue={fields.merchantFee}
                                            rules={[
                                                { required: true, message: 'Sharing fee wajib diisi!' },
                                                { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!'}
                                            ]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                type="number"
                                                placeholder="Input sharing fee"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4} style={{paddingLeft: "10px"}}>
                                        <Form.Item
                                            name="merchantFeeType"
                                            initialValue={fields.merchantFeeType}
                                            rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                        >
                                            <Select>
                                                <Select.Option value="PERCENTAGE">%</Select.Option>
                                                <Select.Option value="FIXED">idr</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Alamat</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="address"
                                            initialValue={fields.address}
                                            rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                        >
                                            <Input.TextArea 
                                                rows={2} 
                                                className="form-input" 
                                                placeholder="Input alamat merchant"
                                                value={fields.address}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Email</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="email"
                                            initialValue={fields.email}
                                            rules={[
                                                { required: true, message: 'Email merchant wajib diisi!' },
                                                { type: "email", message: 'Format email yang kamu isi salah!' },
                                            ]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                placeholder="Input email merchant"
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>No. Telepon</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="phone"
                                            initialValue={fields.phone}
                                            rules={[
                                                { required: true, message: 'Nomor Telepon merchant wajib diisi!' },
                                                { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                            ]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                placeholder="Input nomor telepon merchant"
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>  
                            </Col>
                            <Col span={12} className="right-section">
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Latitude</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="latitude"
                                            initialValue={fields.latitude}
                                            rules={[{ required: true, message: 'Latitude wajib diisi!' }]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                type="number"
                                                placeholder="Input latitude"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Longitude</Col>
                                    <Col span={14}>
                                        <Form.Item
                                            name="longitude"
                                            initialValue={fields.longitude}
                                            rules={[{ required: true, message: 'Longitude wajib diisi!' }]}
                                        >
                                            <Input 
                                                className="form-input" 
                                                type="number"
                                                placeholder="Input jarak longitude"
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Jam Operasional</Col>
                                    <Col span={12}>
                                        <FormOperationalHour 
                                            data={operational}
                                            submitOperationHourStatus={submitOperationHourStatus}
                                            submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                                            submitOperationalHour={submitOperationalHour}
                                        />
                                    </Col>
                                </Row>
                                <Row className="row-edit-hobby">
                                    <Col span={7}>Info Akun Bank</Col>
                                    <Col span={12}>
                                        <FormBankAccount
                                            data={fields.bankDetail}
                                            submitBank={submitBank}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>Status Aktif</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="isActive"
                                            valuePropName= "checked"
                                            initialValue={fields.isActive}
                                        >
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />}
                                                // defaultChecked={data.IsActive} 
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={7}>Buka/Tutup</Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="status"
                                            valuePropName= "checked"
                                            initialValue={fields.status === "OPEN" ? true : false}
                                        >
                                            <Switch
                                                checkedChildren={<CheckOutlined />}
                                                unCheckedChildren={<CloseOutlined />} 
                                                // defaultChecked={data.Status === "OPEN" ? true : false} 
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                <Form.Item>
                                    <OkButton>Simpan</OkButton>
                                    <Link to="/merchant">
                                        <BackButton>Kembali</BackButton>
                                    </Link>
                                </Form.Item>
                            </Col>
                        </Row> 
                    </Form>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default EditMerchantJasa
