import React, {useState} from 'react'
import axios from "axios"
import {Button, Form, Input, Row, Col, Spin, notification} from "antd"
import { LoadingOutlined } from '@ant-design/icons';
import {Modal} from "../../../../../components/atoms"

interface Props {
    id:any
    name:any
}

const FormNewBankAccount:React.FC<Props> = (props) => {
    const [modal, setModal] = useState(false)
    const [loading, setLoading] = useState(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{bankname:string, accountname:string, accountnumber:string}) => {
        console.log(values);
        const data = new FormData()
        data.append("id", props.id)
        data.append("merchant-name", props.name)
        data.append("bank-name", values.bankname)
        data.append("account-name", values.accountname)
        data.append("account-number", values.accountnumber)

        setLoading(true)
        try {
            const res = await axios.post("https://resource.manyoption.co.id/wp-json/contact-form-7/v1/contact-forms/1368/feedback",
                data,
                {
                  headers: { "Content-Type": "application/x-www-form-urlencoded" },
                }
            )

            console.log(res);
            if (res.status === 200) {
                setLoading(false)
                notification.success({
                    message: "Yuhuuu",
                    description:
                      'Request perubahan akun bank berhasil!',
                    placement: "bottomRight",
                });
                toggle()
            }
        } catch (error) {
            if (error.response.status !== 200) {
                setLoading(false)
                notification.error({
                    message: "Ups",
                    description:
                      'Terjadi kesalahan pada request perubahan akun bank!',
                    placement: "bottomRight",
                });
            }
        }
    }

    console.log(props.id);
    console.log(props.name);

    return (
        <React.Fragment>
            <Button onClick={toggle} style={{borderRadius: "10px", backgroundColor: "#009b00", color: "white"}}>
                Requset Ganti
            </Button>
            <Modal 
                title="Form Ganti Akun Bank"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    layout="vertical"
                    onFinish={handleSubmit}
                    requiredMark={false}
                >
                    <Form.Item
                        label="Nama Bank"
                        name="bankname"
                        rules={[{ required: true, message: 'Nama bank wajib diisi!' }]}
                    >
                        <Input 
                            className="form-input" 
                            placeholder="Input nama bank"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nama Akun Bank"
                        name="accountname"
                        rules={[{ required: true, message: 'Nama akun wajib diisi!' }]}
                    >
                        <Input 
                            className="form-input" 
                            placeholder="Input nama pemegang akun bank"
                        />
                    </Form.Item>
                    <Form.Item
                        label="Nomor Rekening"
                        name="accountnumber"
                        rules={[{ required: true, message: 'Nomor rekening wajib diisi!' }]}
                    >
                        <Input 
                            className="form-input" 
                            placeholder="Input nomor rekening"
                        />
                    </Form.Item>
                    <Row>
                        <Col span={12} style={{textAlign: "right", paddingRight: "5px"}}>
                            <Button 
                                className="button-cancel-form-venue"
                                onClick={toggle}
                            >
                                Batal
                            </Button>
                        </Col>
                        <Col span={12} style={{textAlign: "left", paddingRight: "10px"}}> 
                            <Form.Item style={{marginBottom: "0px"}}>
                                <Button
                                    htmlType="submit" 
                                    className="button-save-form-venue"
                                >
                                    Simpan {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 20, color: "white" }} spin />} /> : null}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FormNewBankAccount
