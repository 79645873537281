import React from 'react'
import {DetailPageLayout} from "../../../templates"
import {data} from "./DetailTopupModel"
import {Row, Col} from "antd"
import {ItemDetail} from "../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {CancelButton} from "../../../../components/atoms"

import "./detailtopup.scss"

const DetailTopup: React.FC = () => {
    return (
        <React.Fragment>
            <DetailPageLayout>
                <div className="detail-topup-title">
                    {data.topupNumber}
                </div>
                <Row className="detail-topup-section">
                    <Col span={15}>
                        <ItemDetail label="Jumlah Topup" value={FormatRp(data.totalTopup)} />
                        <ItemDetail label="Biaya Admin" value={FormatRp(data.adminFee)} />
                        <ItemDetail label="Nama Bank" value={data.bank} />
                        <ItemDetail label="Nomor VA" value={data.vaNumber} />
                        <ItemDetail label="Status" value={data.isSuccess ? "Sukses" : "Gagal"} />
                        <ItemDetail label="Di Request Oleh" value={data.requestedBy} />
                        <ItemDetail label="Di Request Tanggal" value={FormatDate(data.requestedAt)} />
                    </Col>
                </Row>
                <div className="top-backpage-section">
                    <Link to="/topup">
                        <CancelButton label="Kembali" />
                    </Link>
                </div>
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailTopup
