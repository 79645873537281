import React, {useState, useEffect} from 'react'
import {Form, Row, Col, TimePicker, Input, Select, Button} from "antd"
import moment from "moment"

const { RangePicker } = TimePicker;

interface Props {
    day:number
    timeRate:any
    indexTimeRate:number
    formActive:boolean
    addRateAmounts:any
    timeRates:number[]
    handleSubmitTimeRate:(values:any, indextime:number, indexday:number) => void
}

const TimeRates: React.FC<Props> = (props) => {
    const [disable, setDisable] = useState<boolean>(true)

    useEffect(() => {
        props.formActive && props.timeRate.timeStart === "" && props.timeRate.timeEnd === "" && props.timeRate.deposit === null && props.timeRate.rate === null && props.timeRate.discount === null && props.timeRate.isDiscPercentage === "" ? setDisable(false) : setDisable(true)
        // props.timeRate === {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: false} && props.formActive === true ? setDisable(false) : setDisable(true)
    }, [props.formActive, props.timeRate.timeStart, props.timeRate.timeEnd, props.timeRate.deposit, props.timeRate.rate, props.timeRate.discount, props.timeRate.isDiscPercentage ])

    const onSave = (values:{}) => {
        console.log(values);
        setDisable(true)
        props.handleSubmitTimeRate(values, props.indexTimeRate, props.day)
    }

    // console.log(props.formActive)
    // console.log(props.timeRate);
    // console.log(props.day);
    // console.log(disable);
    
    return (
        <Form 
            onFinish={onSave}
        >
            <Row>
                <Col span={4} className="body-col-hourpicker">
                    <Form.Item 
                        name="hour" 
                        initialValue={props.timeRate === undefined ?
                            [null, null] :
                            [
                                props.timeRate.timeStart === "" ? null : moment(props.timeRate.timeStart, "HH:mm"), 
                                props.timeRate.timeEnd === "" ? null : moment(props.timeRate.timeEnd, "HH:mm")
                            ]
                        }
                        rules={[{ required: true, message: 'Wajib diisi!' }]}
                    >
                        <RangePicker
                            id={`hour${props.day}${props.indexTimeRate}`}
                            placeholder={["Awal", "Akhir"]}
                            format={"HH:mm"} 
                            disabled={disable} 
                        />
                    </Form.Item>
                </Col>
                <Col span={5} className="body-col-depositform">
                    <Form.Item 
                        name="deposit"
                        initialValue={props.timeRate.deposit}
                        rules={[
                            { required: true, message: 'Wajib diisi!' },
                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Deposit yang anda input melebihi batas!'}
                        ]}
                    >
                        <Input
                            id={`deposit${props.day}${props.indexTimeRate}`}
                            type="number"
                            className="form-input" 
                            placeholder="Input jumlah deposit"
                            disabled={disable}
                        />
                    </Form.Item>
                </Col>
                <Col span={5} className="body-col-priceform">
                    <Form.Item 
                        name="rate"
                        initialValue={props.timeRate.rate}
                        rules={[
                            { required: true, message: 'Wajib diisi!' },
                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Harga yang anda input melebihi batas!'}
                        ]}
                    >
                        <Input
                            id={`rate${props.day}${props.indexTimeRate}`}
                            type="number"
                            className="form-input" 
                            placeholder="Input harga"
                            disabled={disable}
                        />
                    </Form.Item>
                </Col>
                <Col span={10} className="body-col-discount">
                    <Row>
                        <Col span={12} className="col-discount-form-section">
                            <Form.Item 
                                name="discount"
                                initialValue={props.timeRate.discount}
                                rules={[
                                    { required: true, message: 'Wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Harga yang anda input melebihi batas!'}
                                ]}
                            >
                                <Input
                                    id={`discount${props.day}${props.indexTimeRate}`}
                                    className="form-input" 
                                    placeholder="Input discount"
                                    disabled={disable}
                                    // value={data.Name} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={7} className="col-unit-form-section">
                            <Form.Item 
                                name="isDiscPercentage"
                                initialValue={props.timeRate.isDiscPercentage === true ? "%" : props.timeRate.isDiscPercentage === false ? "idr" : null}
                                rules={[{ required: true, message: 'Wajib diisi!' }]}
                            >
                                <Select
                                    id={`isDiscPercentage${props.day}${props.indexTimeRate}`}
                                    placeholder="satuan" 
                                    disabled={disable}
                                >
                                    <Select.Option value="%">%</Select.Option>
                                    <Select.Option value="idr">IDR</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={5} className="col-action-section">
                            <Row>
                                <Col style={{paddingRight: "4px"}}>
                                    <Form.Item>
                                        <Button htmlType="submit" style={{padding: "0px", border: "0px"}}>
                                            <i className="far fa-save add-icon" />
                                        </Button>
                                    </Form.Item>
                                </Col>
                                <Col style={{paddingRight: "3px"}}>
                                    <Button 
                                        style={{padding: "0px", border: "0px"}} 
                                        onClick={() => setDisable(false)}
                                    >
                                        <i className="far fa-edit add-icon" style={{fontSize: "18px"}} />
                                    </Button>
                                </Col>
                                <Col style={{paddingRight: "3px"}}>
                                    <Button 
                                        style={{padding: "0px", border: "0px"}} 
                                        onClick={() => props.addRateAmounts(props.timeRates[props.timeRates.length - 1] + 1)}
                                    >
                                        <i 
                                            className="far fa-plus-square add-icon" 
                                        />
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default TimeRates
