import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {FormatDate} from "../../../../helpers/Formatter"
import {PageLayout} from "../../../templates"
import FilterReviewHobby from "./filterreviewhobby/FilterReviewHobby"
import {Search, FeatureButton, Loading, Table} from "../../../../components/atoms"
import {Pagination, notification} from "antd"

import "./reviewhobby.scss"

const ReviewHobby: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        page:number,
        limit:number,
        total_page:number,
        records:{
            reviewMessage:string,
            merchantRating:number,
            CreatedAt:string,
            userName:string,
            invoiceNumber:string
        }[]
    }>({
        page: 1,
        limit: 10,
        total_page: 1,
        records:[]
    })
    const [params, setParams] = useState<{
        merchantID:string|null,
        pageNumber:number,
        search:string
    }>({
        merchantID: localStorage.getItem("merchantID"),
        pageNumber: 1,
        search: ""
    })

    const fetchData = async(params:{}) => {
        try {
            const res = await axios.get(`${base_url}/v3/hobby/merchant/review`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setFields(res.data.payload)
            setLoading(false) 
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data voucher',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value})
         }
    }

    const handleFilter = (values:{}) => {
        setParams({...params, ...values})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantID"),
            pageNumber: 1,
            search: ""
        })
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    console.log(fields);
    

    const columns = [
        {
            title: "No Order",
            render: (data:{invoiceNumber:string}) => (
                <div>{data.invoiceNumber === "" ? "-" : data.invoiceNumber}</div>
            ),
            width: "23%"
        },
        {
            title: "Review",
            dataIndex: "reviewMessage",
            width: "23%"
        },
        {
            title: "Rating",
            render: (data: {merchantRating: number}) => {
                const renderStar = () => {
                    const stars = []
                    for (var i = 0; i < data.merchantRating; i++) {
                        stars.push(<i key={i} className="fas fa-star" style={{color: "green"}} />)
                    }
                    
                    return stars
                }
                return (
                    <div>
                        {renderStar()}
                    </div>
                )
            },
            width: "15%"
        },
        {
            title: "Customer",
            render: (data:{userName:string}) => (
                <div>{data.userName === "" ? "-" : data.userName}</div>
            )
        },
        {
            title: "Tanggal Review",
            render: (data: {CreatedAt: string}) => (
                <div>
                    {FormatDate(data.CreatedAt)}
                </div>
            )
        },
    ];

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                leftTop={
                    <div>
                        <FilterReviewHobby handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        {loading ? <Loading /> : (
                            <div>
                                <Table 
                                    columns={columns} 
                                    data={fields.records} 
                                    rowKey="ID"
                                    pagination={false}
                                    // scroll={{y: 550}}
                                />
                                <div style={{padding: "20px 0px", textAlign: "right"}}>
                                    <Pagination 
                                        current={fields.page} 
                                        pageSize={10} 
                                        total={fields.total_page * fields.limit} 
                                        onChange={handlePageChange} 
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default ReviewHobby
