import React from 'react'
import {Table} from "antd"

import "./tablemodule.scss"

interface Props {
    columns?: {}[];
    data?: {}[];
    pagination?: any;
    rowKey?:any
    scroll?:{}
    rowClassName?:any
    loading?: boolean
}

const TableModule: React.FC<Props> = (props) => {
    return <Table 
        columns={props.columns} 
        dataSource={props.data} 
        pagination={props.pagination}
        rowKey={props.rowKey}
        scroll={props.scroll}
        rowClassName={props.rowClassName} 
        loading={props.loading}
    />;
}

export default TableModule
