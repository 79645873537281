import React from 'react'
import TransactionHobby from "./transactionhobby/TransactionHobby"
import TransactionJasa from "./transactionjasa/TransactionJasa"
import TransactionFood from "./transactionfood/TransactionFood"

const Transaction: React.FC = () => {
    return (
        <React.Fragment>
            {localStorage.getItem("merchantType") === "hobi" ? <TransactionHobby /> : localStorage.getItem("merchantType") === "food" ? <TransactionFood /> : <TransactionJasa />}
        </React.Fragment>
    )
}

export default Transaction
