import React, {useEffect, useState} from 'react'
import {base_url} from "../../../../../config/url-config"
import axios from "axios"
import {Row, Col, notification} from "antd"
import {MerchantBox} from "../../../../../components/molecules"

const ListMerchantFood: React.FC = () => {
    const [fields, setFields] = useState<{
        id: string
        urlPhoto: string
        name: string

    }>({
        id: "",
        urlPhoto: "",
        name: ""
    })

    const fetchData = async () => {
        try {
            const res = await axios.get(`${base_url}/v3/food/merchant/detil/${localStorage.getItem("merchantId")}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                // params: queryparams
            })
            if (res.status === 200) {
                setFields(res.data.payload)
            }
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleClick = (id:string) => {
        localStorage.setItem("merchantID", id)
    }    
    
    return (
        <div>
            <Row gutter={[0, 32]} style={{paddingTop: "10px"}}>
                <Col 
                    span={4} 
                >
                    <MerchantBox 
                        logo={fields.urlPhoto} 
                        name={fields.name} 
                        link="/" 
                        editLink={`/merchant/edit?id=${fields.id}`}
                        onClick={() => handleClick(fields.id)} 
                    />
                </Col>
            </Row>
        </div>
    )
}

export default ListMerchantFood
