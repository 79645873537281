import React from 'react'
import {Switch} from "antd"

import "./switchmodule.scss"

interface Props {
    checked?: boolean,
    checkedChildren?: string,
    unCheckedChildren?: string,
    onChange?: () => any
}

const SwitchModule: React.FC<Props> = (props) => {
    return (
        <div>
            <Switch 
                checkedChildren={props.checkedChildren} 
                unCheckedChildren={props.unCheckedChildren} 
                checked={props.checked}
                onChange={props.onChange}
            />
        </div>
    )
}

export default SwitchModule
