import React, {useState} from 'react'
import {Modal, BackButton, OkButton} from "../../../../../../components/atoms"
import axios from 'axios'
import {base_url} from "../../../../../../config/url-config"
import {Button, Form, Input, Row, Col, notification} from "antd"

import "./createcategory.scss"

interface Props {
    fetchData:() => void
}

const CreateCategory: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = async(values:{}) => {
        // console.log(values);
        
        try {
            const res = await axios.post(`${base_url}/v3/merchant/item/category`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            console.log(res);
            if (res.status === 201) {
                form.resetFields();
                toggle()
                props.fetchData()
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan pada saat membuat kategori.',
            });
        }
    }

    return (
        <React.Fragment>
            <Button 
                className="btn-create-category"
                onClick={toggle}
            >
                Tambah Kategori
            </Button>

            <Modal
                title="Tambah Kategori"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="name"
                                label="Nama Kategori"
                                rules={[{ required: true, message: 'Nama kategori wajib diisi!' }]}
                            >
                                <Input 
                                    placeholder="Input nama kategori" 
                                    className="form-input-category"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Form.Item>
                                <BackButton onClick={toggle}>Kembali</BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default CreateCategory
