import React, { useEffect, useState } from 'react'
import { Form, Row, Col, Input, Select, Switch } from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom"
import { BackButton, OkButton, UploadPhoto } from "../../../../../components/atoms"
// import { ICategories, IModelItem } from '../ModelItem'
import { Tags } from '../../../../organisms'
// import { useQuery } from '../../../../../helpers/Formatter'

interface Props {
    submitPhoto: (path: string) => void
    data: any //IModelItem
    category: any//ICategories[]
    handleSubmit: (values: any) => void
    setData: (data: any) => void
    // handleSubmit: (values:{}) => void
}

const { Option } = Select;

const FormItem: React.FC<Props> = (props) => {
    // const query = useQuery();
    const [disabled, setDisabled] = useState<boolean>(props.data.harga === null ? true : false)
    const [price, setPrice] = useState<string>(props.data.harga === null ? "" : String(props.data.harga))
    const [discount, setDiscount] = useState<string>(props.data.discount === null ? "0" : `${props.data.discount}`)
    const [isPercentage, setIsPercentage] = useState<string>(props.data.discountIsInPercentage ? "true" : "false")

    useEffect(() => {
        var isRendered = true;
        if (isRendered) {
            // setIsPercentage(props.data.discountIsInPercentage);
            setPrice(String(props.data.harga));
        }

        return () => {
            isRendered = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handlePrice = (e: any) => {
        setPrice(e.target.value)
        e.target.value === "" ? setDisabled(true) : setDisabled(false)
    }

    const handleDiscount = (e: any) => {
        setDiscount(e.target.value)
    }

    const handleDiscountUnit = (value: string) => {
        setIsPercentage(value)
    }

    const handleSubmit = (values: any) => {
        console.log(values);
        
        values["discount"] = discount;
        values["categories"] = values.categories !== undefined ? values.categories.map((value: any) => JSON.parse(value)) : [];
        values["harga"] = Number(values.harga);
        values['urlphoto'] = props.data.urlphoto;
        values['discountIsInPercentage'] = values.discountIsInPercentage === "true" ? true : false
        console.log(values);
        props.handleSubmit(values)

    }

    const handleTags = (tags: string[]) => {
        props.setData({
            ...props.data,
            tags: tags
        })
    }

    return (
        <Form onFinish={handleSubmit}>
            <Row>
                <Col span={16}>
                    <Row className="row-edit-hobby">
                        <Col>
                            <Form.Item
                                name="urlphoto"
                                valuePropName="file"
                                initialValue={props.data.urlphoto}
                            >
                                <UploadPhoto
                                    featureName="food"
                                    getLink={(url) => props.setData({ ...props.data, urlphoto: url })}
                                    imageURL={props.data.urlphoto}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Nama Item</Col>
                        <Col span={14}>
                            <Form.Item
                                name="name"
                                initialValue={props.data.name}
                                rules={[{ required: true, message: 'Nama item wajib diisi!' }]}
                            >
                                <Input
                                    className="form-input"
                                    placeholder="Input nama item"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Deskripsi</Col>
                        <Col span={14}>
                            <Form.Item
                                name="description"
                                initialValue={props.data.description}
                                rules={[{ required: true, message: 'Deskripsi wajib diisi!' }]}
                            >
                                <Input.TextArea
                                    className="form-input"
                                    placeholder="Input deskripsi"
                                    rows={3}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Kategori Item</Col>
                        <Col span={14}>
                            <Form.Item 
                                name="categories" 
                                initialValue={props.data.categories?.map((value:any) => JSON.stringify(value))}
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Pilih Kategori"
                                    style={{ width: '100%' }}
                                >
                                    {props.category.map((value:any, key:number) => <Option value={JSON.stringify(value)} key={key}>{value.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Harga Item</Col>
                        <Col span={14}>
                            <Form.Item
                                name="harga"
                                initialValue={props.data.harga}
                                rules={[
                                    { required: true, message: 'Harga wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Harga yang anda input melebihi batas!' }
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input"
                                    placeholder="Input harga"
                                    onChange={e => handlePrice(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Diskon</Col>
                        <Col span={11}>
                            <Form.Item
                                name="discount"
                                initialValue={props.data.discount}
                                rules={[
                                    { required: true, message: 'Diskon wajib diisi!' },
                                    {
                                        validator: (_, value) =>
                                            isPercentage === "false" ? parseInt(value) >= parseInt(price) ? Promise.reject('Diskon tidak boleh lebih dari harga!') : Promise.resolve() : value > 100 ? Promise.reject('Persen tidak boleh lebih dari 100!') : Promise.resolve()
                                    },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input"
                                    placeholder="Input diskon"
                                    disabled={disabled}
                                    onChange={(e: any) => handleDiscount(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{ paddingLeft: "7px" }}>
                            <Form.Item
                                name="discountIsInPercentage"
                                initialValue={props.data.discountIsInPercentage ? "true" : "false"}
                            >
                                <Select
                                    value={isPercentage.toString()}
                                    onChange={handleDiscountUnit}
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option value="true">%</Select.Option>
                                    <Select.Option value="false">IDR</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    {discount !== "0" ? (
                        <Row className="row-edit-hobby">
                            <Col span={7}></Col>
                            <Col span={14}>
                                <Input
                                    className="form-input"
                                    value={isPercentage === "false" ?
                                        `Diskon dalam persen : ${parseInt(discount) / parseInt(price) * 100}%`
                                        :
                                        `Diskon dalam Rupiah : Rp ${parseInt(discount) / 100 * parseInt(price)}`
                                    }
                                    style={{ color: "#009b00" }}
                                    disabled
                                />
                            </Col>
                        </Row>
                    ) : null}

                    <Row className="row-edit-hobby">
                        <Col span={7}>Tags</Col>
                        <Col span={14}>
                            <Tags
                                tags={props.data.tags}
                                add={handleTags}
                                remove={handleTags}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={7}>Status</Col>
                        <Col span={14}>
                            <Form.Item
                                name="status"
                                valuePropName="checked"
                                initialValue={props.data.status}
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={7}>Promo</Col>
                        <Col span={14}>
                            <Form.Item
                                name="isPromo"
                                valuePropName="checked"
                                initialValue={props.data.isPromo}
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row style={{ paddingTop: "20px" }}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to={`/item`}>
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default FormItem
