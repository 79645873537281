import React from 'react'

import "./detailpagelayout.scss"

const DetailPageLayout: React.FC = (props) => {
    return (
        <div className="detail-page-layout-container">
            {props.children}
        </div>
    )
}

export default DetailPageLayout
