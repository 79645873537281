import React from 'react'
import {Input} from "antd"

import "./search.scss"

interface Props {
    name?: string,
    onChange?: () => any
    onKeyPress?:any
}

const Search: React.FC<Props> = (props) => {
    return (
        <div>
            <Input
                name={props.name}
                placeholder="Cari..."
                className="search-input"
                onChange={props.onChange}
                onKeyPress={props.onKeyPress}
                prefix={<i className="fas fa-search" style={{ color: "#cecece" }}></i>}
            />
        </div>
    )
}

export default Search
