import React, {useState} from 'react'
import {FeatureButton, Modal} from "../../../../components/atoms"
import FormFilterExport from "../formfilterexport/FormFilterExport"

const ExportTopup: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmit = (values: {}) => {
        console.log(values);
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-file-export" /> Export</div>}
                onClick={toggle}
            />

            <Modal 
                visible={visible} 
                title="Export Topup" 
                onCancel={toggle}
            >
                <FormFilterExport onFinish={handleSubmit} toggle={toggle} />
            </Modal>
        </React.Fragment>
    )
}

export default ExportTopup
