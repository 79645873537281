import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {notification, Form, Select, Row, Col} from "antd"
import {FeatureButton, Modal, Loading, OkButton, BackButton} from "../../../../../components/atoms"

import "./filteritem.scss"

interface Props {
    handleFilter: (values:{}) => void
}

const FilterItem: React.FC<Props> = (props) => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [categories, setCategories] = useState<{
        name:string,
        id:string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const fetchDataCategory = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/merchant/item/category`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            // console.log(res);
            setCategories(res.data.payload)
            setLoading(false)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchDataCategory()
    }, [])

    const handleSubmit = (values:{}) => {
        props.handleFilter(values)
        toggle()
        form.resetFields()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Filter Item"
                onCancel={toggle}
                visible={modal}
            >
                {loading ? <Loading /> : (
                    <div>
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={handleSubmit}
                        >
                            <Row style={{paddingBottom: "20px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="categoryID"
                                        label="Kategori Item"
                                    >
                                        <Select placeholder="Pilih kategori" style={{width: "100%"}}>
                                            {categories.map((category, key) => (
                                                <Select.Option key={key} value={category.id}>{category.name}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{textAlign: "center"}}>
                                <Col span={24}>
                                    <Form.Item>
                                        <BackButton 
                                            onClick={toggle}
                                        >
                                            Kembali
                                        </BackButton>{" "}
                                        <OkButton>Simpan</OkButton>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                )}
            </Modal>
        </React.Fragment>
    )
}

export default FilterItem
