import React from 'react'
import {Modal} from "antd"

import "./modalmodule.scss"

interface Props {
    visible: boolean,
    title: string,
    onCancel: () => any,
}

const ModalModule: React.FC<Props> = (props) => {
    return ( 
        <Modal
            visible={props.visible}
            title={props.title}
            onCancel={props.onCancel}
            className="custom-modal"
            footer={[<div key="footer"></div>]}
        >
            {props.children}
        </Modal>
    )
}

export default ModalModule
