import React, {useState} from 'react'
import {Row, Col, Input} from "antd"

import "./tag.scss"

interface Props {
    tag:string,
    index:number,
    handleEditTag:(tag:string, index:number) => void,
    handleDeleteTag:(tag:string) => void
}

const Tag: React.FC<Props> = (props) => {
    const [tags, setTags] = useState<boolean>(false)

    const toggleTags = () => {
        setTags(!tags)
    }

    const handleSubmitEdit = (e:any) => {
        if (e.which === 13) {
            props.handleEditTag(e.target.value, props.index)
            toggleTags()
        }
    }

    return (
        <React.Fragment>
            <Row className="tag-container">
                <Col span={24}>
                    {tags ? (
                        <Input
                            defaultValue={props.tag}
                            bordered={false}
                            onKeyPress={(e:any) => handleSubmitEdit(e)}
                        />
                    ) : props.tag}
                </Col>
                {tags ? null : (
                    <Col span={24} className="tag-action-container">
                        <i 
                            className="far fa-edit icon-action-tag" 
                            style={{marginRight: "3px"}}
                            onClick={toggleTags}
                        />
                        <i 
                            className="far fa-trash-alt icon-action-tag" 
                            onClick={() => props.handleDeleteTag(props.tag)}
                        />
                    </Col>
                )}
            </Row>
        </React.Fragment>
    )
}

export default Tag
