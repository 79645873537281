import React from 'react'
import {PageLayout} from "../../../templates"
import {Table, FeatureButton, CancelButton} from "../../../../components/atoms"
import {data} from "./DetailSettlementModel"
import {Link} from "react-router-dom"

import "./detailSettlement.scss"

const DetailSettlement: React.FC = () => {
    const columns = [
        {
            title: "Nomor Order",
            dataIndex: "orderNumber",
        },
        {
            title: "Tanggal Transaksi",
            dataIndex: "transactionDate",
        },
        {
            title: "Total Settlement",
            dataIndex: "totalSettlement",
        },
        {
            title: "Provider",
            dataIndex: "provider",
        },
        {
            title: "Status Transaksi",
            render: (data: {isCompleted: boolean}) => (
                <div>{data.isCompleted ? "Completed" : "Cancel"}</div>
            )
        },
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <FeatureButton 
                        label={<div><i className="fas fa-file-export" /> Export</div>}
                    />
                }
                content={
                    <div>
                        <div className="settlement-number">
                            DSB-BGM-000010020920
                        </div>
                        <Table columns={columns} data={data} />
                        <Link to="/settlement">
                            <CancelButton label="Kembali" />
                        </Link>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default DetailSettlement
