import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {useLocation} from "react-router-dom"
import {Loading} from "../../../../../components/atoms"
import {Row, Col, Form, Upload, Input, Switch, Button, Spin, notification} from "antd"
import { LoadingOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons';
import Tag from "./tag/Tag"
import OperationalHourHobby from "./OperationalHourHobby"
import ModalBankAccountHobby from "./ModalBankAccountHobby"
import {FormatDate} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"

import "./editmerchanthobby.scss"

const EditMerchantHobby: React.FC = () => {
    const history = useHistory()
    const antIcon = <LoadingOutlined style={{ fontSize: 40, color: "#009b00" }} spin />;
    const id = useLocation().search.split("=")[1]
    const [loading, setLoading] = useState<boolean>(false)
    const [data, setData] = useState<{
        ID:string,
        Image:string,
        Name:string, 
        Description:string, 
        Type:string,
        tags:string[],
        MaxDistance:number,
        SharingFee:number,
        Address:string,
        Email:string,
        PhoneNumber:string,
        Latitude:number,
        Longitude:number,
        avgRating:number,
        operationHour:{}[],
        merchantBank:{bankName:string, accountName:string, accountNumber:string}|null
        IsActive:boolean,
        Status:string
        CreatedAt:string,
        UpdatedAt:string,
        UpdatedBy:string
    }>({
        ID: "",
        Image: "",
        Name: "",
        Description: "",
        Type: "",
        tags: [],
        MaxDistance: 0,
        SharingFee: 0,
        Address: "",
        Email: "",
        PhoneNumber: "",
        Latitude: 0,
        Longitude: 0,
        avgRating: 0,
        operationHour: [],
        merchantBank: null,
        IsActive: false,
        Status: "CLOSED",
        CreatedAt: "",
        UpdatedAt: "",
        UpdatedBy: ""
    })
    const [newTag, setNewTag] = useState<string>("")
    const [loadingUpload, setLoadingUpload] = useState<boolean>(false)
    const [operationHours, setOperationHours] = useState<{active?:boolean, day:number, openHour:string|null, closeHour:null|string}[]>([
        {active: false, day: 0, openHour: null, closeHour: null},
        {active: false, day: 1, openHour: null, closeHour: null},
        {active: false, day: 2, openHour: null, closeHour: null},
        {active: false, day: 3, openHour: null, closeHour: null},
        {active: false, day: 4, openHour: null, closeHour: null},
        {active: false, day: 5, openHour: null, closeHour: null},
        {active: false, day: 6, openHour: null, closeHour: null},
    ])

    useEffect(() => {
        const fetchData = async (id:string) => {
            setLoading(true)
            try {
                const res = await axios.get(`${base_url}/v3/hobby/merchant/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
                console.log(res);
    
                if (res.status === 200) {
                    setData(res.data.payload)
                    setLoading(false)
                    if (res.data.payload.operationHour !== []) {
                        for (let i = 0; i < res.data.payload.operationHour.length; i++) {
                            operationHours[res.data.payload.operationHour[i].day].active = true
                            operationHours[res.data.payload.operationHour[i].day].openHour = res.data.payload.operationHour[i].openHour
                            operationHours[res.data.payload.operationHour[i].day].closeHour = res.data.payload.operationHour[i].closeHour
                        }
                    }
                }
            } catch (error) {
                notification["error"]({
                    message: 'Ups!',
                    description:
                      'Terjadi kesalahan dalam permintaan data.',
                });
            }
        }

        fetchData(id)
    }, [id, operationHours])
    
    const handleUpload = async (info:any) => {
        setLoadingUpload(true)
        const formData = new FormData()
        formData.append("file", info.file.originFileObj)
        formData.append("directory", "merchant-hobby")
        try {
            const res = await axios.post(`${base_url}/v3/utility/upload`, formData, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            console.log(res);
            if (res.status === 200) {
                setData({...data, Image: res.data.payload.fullpath})
                setLoadingUpload(false)
            }
        } catch (error) {
            console.log(error.response);
        }
    }


    // FUNCTION FOR TAG //
    const handleDeleteTag = (tag:string) => {
        const newarr = data.tags.filter(oldtag => oldtag !== tag)
        setData({...data, tags: newarr})
    }

    const handleEditTag = (tag:string, index:number) => {
        const newarr = [...data.tags]
        newarr[index] = tag
        setData({...data, tags: newarr})
    }

    const handleChangeTag = (e:any) => {
        setNewTag(e.target.value)
    }

    const submitNewTag = () => {
        if (newTag !== "") {
            const newarr = [...data.tags, newTag]
            setData({...data, tags: newarr})
            setNewTag("")
        }
    }
    // ---------------------------------- //


    const submitOperationHourStatus = (e:any, index:number) => {
        const newOperationHours = [...operationHours]
        newOperationHours[index].active = e.target.checked
        setOperationHours(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time:any, timeString:any, index:number) => {
        // console.log(time);
        // console.log(timeString);
        // console.log(index);
        operationHours[index].openHour = timeString[0]
        operationHours[index].closeHour = timeString[1]
    }

    const submitOperationalHour = () => {
        const newOperationHours = operationHours.filter(operationHour => operationHour.active === true)
        newOperationHours.forEach(newOperationHour => delete newOperationHour.active)
        setData({...data, operationHour: newOperationHours})
    }

    const handleSubmit = async(values:{ID:string, Status:boolean|string, Image:{}|string, tags:string[], operationHour:{}[], MaxDistance:any}) => {
        values["ID"] = data.ID
        values["Status"] = values.Status === true ? "OPEN" : "CLOSED"
        values["Image"] = data.Image
        values["tags"] = data.tags
        values["operationHour"] = data.operationHour
        values["MaxDistance"] = parseInt(values.MaxDistance)
        // console.log(values);

        setLoading(true)
        try {
            const res = await axios.patch(`${base_url}/v3/hobby/merchant`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })

            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                      'Data merchant berhasil diperbaharui.',
                });
                setLoading(false)
                history.goBack()
            }
        } catch (error) {
            if (error.response.status !== 200) {
                notification["error"]({
                    message: 'Ups!',
                    description:
                      'Terjadi kesalahan pada saat memperbaharui data merchant.',
                });
                setLoading(false)
            }
        }
    }

    console.log(data);
    console.log(operationHours);
    
    return (
        <div className="form-edit-merchant-hobby">
            {loading ? <Loading /> : (
                <Form onFinish={handleSubmit}>
                    <Row>
                        <Col span={12} className="left-section">
                            <Row>
                                <Col>
                                    <Form.Item
                                        name="Image"
                                        valuePropName="file"
                                        initialValue={data.Image}
                                    >
                                        <Upload showUploadList={false} onChange={handleUpload}>
                                            <div className="upload-photo">
                                                {loadingUpload ? (
                                                    <div style={{paddingTop: "25px"}}>
                                                        <Spin indicator={antIcon} />
                                                    </div>
                                                ) : (
                                                    data.Image === "" ? (
                                                        <div style={{position: "absolute", top: "8%", width: "100%"}}>
                                                            <i className="far fa-image fa-6x" />
                                                        </div>
                                                    ) : (
                                                        <img src={data.Image} alt="merchant-hobby-logo" style={{width: "100px", height: "80px", marginTop: "10px"}} />
                                                    )
                                                )}
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Nama Merchant</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Name"
                                        initialValue={data.Name}
                                        rules={[{ required: true, message: 'Nama merchant wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama merchant" 
                                            // value={data.Name} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Deskripsi Merchant</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Description"
                                        initialValue={data.Description}
                                        rules={[{ required: true, message: 'Deskripsi merchant wajib diisi!' }]}
                                    >
                                        <Input.TextArea 
                                            rows={2} 
                                            className="form-input" 
                                            placeholder="Input deskripsi merchant"
                                            // value={data.Description}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Jenis Merchant</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        value={data.Type} 
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Tags</Col>
                                <Col span={12}>
                                    <Row style={{paddingBottom: "5px", paddingLeft: "2px"}}>
                                        {data.tags.map((tag:string, key:number) => (
                                            // <Col span={10} key={key}>
                                            //     <Checkbox name={tag} value={tag} defaultChecked={true}>{tag}</Checkbox>
                                            // </Col>
                                            <Col span={12} key={key} style={{paddingRight: "8px", paddingBottom: "10px"}}>
                                                <Tag
                                                    index={key}
                                                    tag={tag}
                                                    handleEditTag={handleEditTag}
                                                    handleDeleteTag={handleDeleteTag}
                                                />
                                            </Col>
                                        ))}
                                    </Row>
                                    <Row>
                                        <Col span={19}>
                                            <Input className="form-input" placeholder="Input tag baru" onChange={handleChangeTag} value={newTag} />
                                        </Col>
                                        <Col span={5} style={{paddingLeft: "12px"}}>
                                            <Button className="button-save-edit" style={{width: "100%", borderRadius: "8px"}} onClick={submitNewTag}>+</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Jarak Maksimal</Col>
                                <Col span={10} style={{paddingRight: "10px"}}>
                                    <Form.Item
                                        name="MaxDistance"
                                        initialValue={data.MaxDistance}
                                        rules={[
                                            { required: true, message: 'Jarak maksimal wajib diisi!' },
                                            { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Jarak yang anda input melebihi batas!'}
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            type="number"
                                            // value={data.MaxDistance} 
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={2}>
                                    <Input 
                                        className="form-input"
                                        value="Km"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Sharing Fee</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        value={data.SharingFee}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Alamat</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Address"
                                        initialValue={data.Address}
                                        rules={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                    >
                                        <Input.TextArea 
                                            rows={2} 
                                            className="form-input" 
                                            placeholder="Input alamat merchant"
                                            value={data.Address}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Email</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Email"
                                        initialValue={data.Email}
                                        rules={[
                                            { required: true, message: 'Email merchant wajib diisi!' },
                                            { type: "email", message: 'Format email yang kamu isi salah!' },
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input email merchant"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>No. Telepon</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="PhoneNumber"
                                        initialValue={data.PhoneNumber}
                                        rules={[
                                            { required: true, message: 'Nomor Telepon merchant wajib diisi!'},
                                            { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!'}
                                        ]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nomor telepon merchant"
                                            disabled
                                        />
                                    </Form.Item>
                                </Col>
                            </Row> 
                        </Col>
                        <Col span={12} className="right-section">
                            <Row className="row-edit-hobby">
                                <Col span={7}>Latitude</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        value={data.Latitude}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Longitude</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        value={data.Longitude}
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Rating</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        value={data.avgRating}
                                        placeholder="Input rating"
                                        disabled
                                    />
                                </Col>
                            </Row>
                            {/* <Row className="row-edit-hobby">
                                <Col span={7}>Biaya Pembatalan</Col>
                                <Col span={11}>
                                    <Input 
                                        className="form-input" 
                                        type="number"
                                        // value={data.Longitude}
                                        placeholder="Input biaya pembatalan"
                                        disabled
                                    />
                                </Col>
                            </Row> */}
                            <Row className="row-edit-hobby">
                                <Col span={7}>Jam Operasional</Col>
                                <Col span={12}>
                                    <OperationalHourHobby 
                                        data={operationHours}
                                        submitOperationHourStatus={submitOperationHourStatus}
                                        submitOperationalHour={submitOperationalHour}
                                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                                    />
                                    {/* <Button className="button-modal-edit-hobby">Atur</Button> */}
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Info Akun Bank</Col>
                                <Col span={12}>
                                    <ModalBankAccountHobby 
                                        data={data.merchantBank}
                                        name={data.Name}
                                        id={data.ID}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Status Aktif</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="IsActive"
                                        valuePropName= "checked"
                                        initialValue={data.IsActive}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />}
                                            // defaultChecked={data.IsActive} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={7}>Buka/Tutup</Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="Status"
                                        valuePropName= "checked"
                                        initialValue={data.Status === "OPEN" ? true : false}
                                    >
                                        <Switch
                                            checkedChildren={<CheckOutlined />}
                                            unCheckedChildren={<CloseOutlined />} 
                                            // defaultChecked={data.Status === "OPEN" ? true : false} 
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Tanggal Dibuat</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input tanggal buat"
                                        value={FormatDate(data.CreatedAt)} 
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Tanggal Diubah</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        placeholder="Input tanggal buat"
                                        value={FormatDate(data.UpdatedAt)} 
                                        disabled
                                    />
                                </Col>
                            </Row>
                            <Row className="row-edit-hobby">
                                <Col span={7}>Diubah Oleh</Col>
                                <Col span={12}>
                                    <Input 
                                        className="form-input" 
                                        placeholder="-"
                                        value={data.UpdatedBy} 
                                        disabled
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <Button htmlType="submit" className="button-save-edit">Simpan</Button>
                                <Link to="/merchant">
                                    <Button className="button-cancel-edit">Kembali</Button>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Form>
            )}
        </div>
    )
}

export default EditMerchantHobby
