import React from 'react'
import Jasa from "./ItemJasa/ItemJasa"
import Food from "./ItemFood/ItemFood"

import "./item.scss"

const Item: React.FC = () => {
    return (
        <React.Fragment>
            {localStorage.getItem("merchantType") === "jasa" ? <Jasa /> : <Food />}
        </React.Fragment>
    )
}

export default Item
