import React from 'react'
import ReviewJasa from "./reviewjasa/ReviewJasa"
import ReviewHobby from "./reviewhobby/ReviewHobby"

import "./review.scss"

const Review: React.FC = () => {
    return (
        <React.Fragment>
            {localStorage.getItem("merchantType") === "jasa" ? <ReviewJasa /> : <ReviewHobby /> }
        </React.Fragment>
    )
}

export default Review
