import React from 'react'
import {Button} from "antd"

import "./featurebutton.scss"

interface Props {
    label: any
    onClick?: () => any
}

const FeatureButton: React.FC<Props> = (props) => {
    return (
        <Button
            onClick={props.onClick}
            className="feature-button"
        >
            {props.label}
        </Button>
    )
}

export default FeatureButton
