import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {orderItemJasa} from "../../../../../config/edpoint"
import {Row, Col, Pagination, notification} from "antd"
import FilterTransactionJasa from "./filtertransactionjasa/FilterTransactionJasa"
import {FeatureButton, Search, Table} from "../../../../../components/atoms"
import {PageLayout} from "../../../../templates"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"

import "./transactionjasa.scss"

const TransactionJasa: React.FC = () => {
    const [fields, setFields] = useState<{
        currentPage:number,
        limit: 10,
        totalPage: 1,
        data: {}[]
    }>({
        currentPage: 1,
        limit: 10,
        totalPage: 1,
        data: []
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<{
        // merchantID:string|null,
        page:number,
        q:string
    }>({
        // merchantID: localStorage.getItem("merchantID"),
        page: 1,
        q: ""
    })
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
    
    const fetchData = async (params:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderItemJasa}/byMerchant`

            const res = await axios.get(url, {
                headers: headers,
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
       fetchData(params)
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handleFilter = (values:{}) => {
        console.log(values);
        setParams({...params, ...values, page: 1})
    }

    const handleReset = () => {
        setParams({
            page: 1,
            q: ""
        })
    }

    const columns = [
        {
            title: "No. Order",
            render: (data:{invoiceNumber:string, merchantName: string, orderDate:string}) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div>{data.merchantName}</div>
                    <div>{FormatDate(data.orderDate)}</div>
                </div>
            ),
            width: "25%"
        },
        {
            title: "Total Pesanan",
            render: (data:{totalPrice:number}) => (
                <div>{FormatRp(data.totalPrice)}</div>
            ),
            width: "17%"
        },
        {
            title: "Biaya Layanan",
            render: (data:{deliveryFee:number}) => (
                <div>{FormatRp(data.deliveryFee)}</div>
            ),
            width: "15%"
        },
        {
            title: "Provider Pembayaran",
            render: (data:{paymentData:{paymentProvider:string}}) => (
                <div>{data.paymentData !== null ? data.paymentData.paymentProvider : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Status",
            render: (data:{status:string, paymentData:null|{paymentState:string}}) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.paymentData !== null ? data.paymentData.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            render: (data:{id:string}) => (
                <Link to={`/transaksi/detail?bookingID=${data.id}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        }
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <FilterTransactionJasa handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                rightTop={<Search onKeyPress={(e:any) => handleSubmitSearch(e)} />}
                content={
                    <div>
                        <Table
                            columns={columns}
                            data={fields.data}
                            rowKey="id"
                            pagination={false}
                            rowClassName={(record:{status:string}, index:number) => record.status === "UNPAID" || record.status === "PAID" || record.status === "PROCESS" ? 'new-transaction' :  'read-transaction'}
                            // scroll={{y: 550}}
                            loading={loading}
                        />
                        <div style={{padding: "20px 0px", textAlign: "right"}}>
                            <Pagination 
                                current={fields.currentPage} 
                                pageSize={10} 
                                total={fields.totalPage * fields.limit} 
                                onChange={handlePageChange} 
                                showSizeChanger={false}
                            />
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default TransactionJasa
