import React from 'react'
import {FormatDate, FormatRp} from "../../../helpers/Formatter"
import {Row, Col} from "antd"
import {Link} from "react-router-dom"
import {PageLayout} from "../../templates"
import {Search, Table} from "../../../components/atoms"
import ExportSettlement from "./exportSettlement/ExportSettlement"
import {data} from "./SettlementModel"

import "./settlement.scss"

const Settlement: React.FC = () => {
    const columns = [
        {
            title: "Nomor Pencairan",
            width: "20%",
            dataIndex: "settlementNumber",
        },
        {
            title: "Tanggal Buat",
            render: (data: {createdAt: string}) => (
                <div>{FormatDate(data.createdAt)}</div>
            )
        },
        {
            title: "Nominal",
            width: "15%",
            render: (data: {settlementNominal: number}) => (
                <div>{FormatRp(data.settlementNominal)}</div>
            )
        },
        {
            title: "Detail Merchant",
            width: "20%",
            render: (data: {merchantName: string, bank: string, bankAccount: string, accountNumber: string}) => (
                <Row>
                    <Col span={24}>
                        <Row>
                            <Col span={12}>
                                Merchant
                            </Col>
                            <Col span={12}>
                                {data.merchantName}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                Bank
                            </Col>
                            <Col span={12}>
                                {data.bank}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                Nama Akun
                            </Col>
                            <Col span={12}>
                                {data.bankAccount}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                No. Akun
                            </Col>
                            <Col span={12}>
                                {data.accountNumber}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )
        },
        {
            title: "Tanggal Transfer",
            render: (data: {transferDate: string}) => (
                <div>{FormatDate(data.transferDate)}</div>
            )
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Aksi",
            render: () => (
                <Link to="/settlement/detail">
                    <i className="far fa-eye" style={{cursor: "pointer", color: "green"}} />
                </Link>
            )
        },
    ];

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search />}
                leftTop={<ExportSettlement />}
                content={<Table columns={columns} data={data} />}
            />
        </React.Fragment>
    )
}

export default Settlement
