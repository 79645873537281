import React, {useState} from 'react'
import {Search, Table, Switch, ModalConfirmation} from "../../../components/atoms"
import {PageLayout} from "../../templates"
import {Row, Col, Dropdown, Menu} from "antd"
import EditAccount from "./editAccount/EditAccount"
import CreateAccount from "./createAccount/CreateAccount"
import {data} from "./AkunModel"

import "./account.scss"

const Account: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(false)

  const toggle = () => {
    setVisible(!visible)
  }

  const menu = (
    <Menu style={{width: "90px"}}>
      <Menu.Item>
        <EditAccount />
      </Menu.Item>
      <Menu.Item>
        <div onClick={toggle}>
          <Row>
            <Col span={8}>
              <i
                className="far fa-trash-alt"
              />
            </Col>
            <Col span={16}>
              Hapus
            </Col>
          </Row>
        </div>
        <ModalConfirmation visible={visible} toggle={toggle} />
      </Menu.Item>
    </Menu>
  );

  const columns = [
      {
        title: "Nama",
        dataIndex: "name",
      },
      {
        title: "No. Handphone",
        dataIndex: "phone",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Status",
        render: (data:{isActive: boolean}) => (
          <div>
            <Switch 
              checkedChildren="On" 
              unCheckedChildren="Off" 
              checked={data.isActive} 
            />
          </div>
        )
      },
      {
        title: "Role",
        dataIndex: "role",
      },
      {
        title: "Aksi",
        render: () => (
          <Dropdown overlay={menu} placement="bottomCenter">
            <div className="button-delete">
              <i className="fas fa-list-ul" />
            </div>
          </Dropdown>
        ),
      },
    ];

    return (
      <React.Fragment>
          <PageLayout 
              leftTop={<CreateAccount />}
              rightTop={<Search />} 
              content={<Table columns={columns} data={data} />}
          />
      </React.Fragment>
    )
}

export default Account
