import React from 'react'
import DetailTransactionHobby from "./detailtransactionhobby/DetailTransactionHobby"
import DetailTransactionJasa from "./detailtransactionjasa/DetailTransactionJasa"
import DetailTransactionFood from "./detailtransactionfood/DetailTransactionFood"

const DetailTransaction: React.FC = () => {
    return (
        <React.Fragment>
            {localStorage.getItem("merchantType") === "hobi" ? <DetailTransactionHobby /> : localStorage.getItem("merchantType") === "food" ? <DetailTransactionFood /> : <DetailTransactionJasa />}
        </React.Fragment>
    )
}

export default DetailTransaction
