import React, {useState} from 'react'
import { Layout } from "antd";
import MoLogo from "../../../assets/logo.png";
import {SideMenu} from "../../../components/molecules"
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined
  } from '@ant-design/icons';

import "./sidebar.scss"
const { Sider } = Layout;

const Sidebar: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false)

    const toggle = () => {
        setCollapsed(!collapsed)
    }

    return (
        <React.Fragment>
            <div className="sidebar">
                <Sider
                    theme="light"
                    collapsible
                    collapsed={collapsed}
                    className="sidebar-container"
                    trigger={null}
                >
                    <div className="brand-logo-container">
                        <img src={MoLogo} alt="mo-logo" className={collapsed ? "brand-logo-small" : "brand-logo-big"} />
                    </div>
                    <SideMenu />
                    <div className="collapse-menu">
                        {collapsed ? <MenuUnfoldOutlined className="collapse-icon" onClick={toggle} /> : <MenuFoldOutlined className="collapse-icon" onClick={toggle} />}
                    </div>
                </Sider>
            </div>
        </React.Fragment>
    )
}

export default Sidebar
