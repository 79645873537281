import React, {useState} from 'react'
import {FeatureButton, Modal} from "../../../../components/atoms"
import FormFilterExport from "../formfilterexport/FormFilterExport"

import "./filtertopup.scss"

const FilterTopUp: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmit = (values: {}) => {
        console.log(values);   
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>} 
                onClick={toggle}
            />

            <Modal 
                visible={visible} 
                title="Filter Topup" 
                onCancel={toggle}
            >
               <FormFilterExport onFinish={handleSubmit} toggle={toggle} />
            </Modal>
        </React.Fragment>
    )
}

export default FilterTopUp
