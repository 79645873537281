import React, {useEffect, useState} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {useLocation, Link} from "react-router-dom"
import {Loading} from "../../../../components/atoms"
import {DetailPageLayout} from "../../../templates"
import {Row, Col} from "antd"
import {ItemDetail, DetailTitleSection} from "../../../../components/molecules"
import {FormatRp, FormatDate} from "../../../../helpers/Formatter"
import {CancelButton} from "../../../../components/atoms"
// import {data} from "./DetailServiceModel"

import "./detailservice.scss"

const DetailService: React.FC = () => {
    const id = parseInt(useLocation().search.split("=")[1])
    const [fields, setFields] = useState<{
        venue:{
            picURL:string[],
            name:string,
            address:string,
            phone:string,
            maxBooking:number,
            durationBfrReservation:number,
            description:string,
            isActive:boolean,
            isOpen:boolean,
            createdAt:string,
            createBy:string,
            updatedAt:string,
            updatedBy:string
        },
        operation:{day:number, timeRate:{timeStart:string, timeEnd:string, deposit:number, rate:number, discount:number, isDiscPercentage:boolean}[]}[]
    }>({
        venue:{
            picURL: [],
            name: "",
            address: "",
            phone: "",
            maxBooking: 0,
            durationBfrReservation: 0,
            description: "",
            isActive: false,
            isOpen: false,
            createdAt: "",
            createBy: "",
            updatedAt: "",
            updatedBy: ""
        },
        operation:[]
    })
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async (id:number) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/hobby/venue/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            if (res.status === 200) {
                setFields(res.data.payload)
                setLoading(false)
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    useEffect(() => {
        fetchData(id)
    }, [id])

    console.log(fields);

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <DetailPageLayout>
                    <Row>
                        {fields.venue.picURL.map((pict, key) => (
                            <Col key={key} style={{paddingRight: "10px"}}>
                                <img src={pict} alt="venue-pict" className="venue-photo" />
                            </Col>
                        ))}
                    </Row>
                    <Row style={{paddingTop: "10px"}}>
                        <Col span={15}>
                            <ItemDetail label="Nama Service" value={fields.venue.name} />
                            <ItemDetail label="Deskripsi" value={fields.venue.description} />
                            <ItemDetail label="Alamat" value={fields.venue.address} />
                            <ItemDetail label="Nomor Telp" value={fields.venue.phone} />
                            <ItemDetail label="Maksimal User Yang Memesan" value={fields.venue.maxBooking} />
                            <ItemDetail label="Batas Waktu Sebelum Booking" value={`${fields.venue.durationBfrReservation/3600} Jam`} />
                            <ItemDetail 
                                label="Status" 
                                value={fields.venue.isActive ? "Aktif" : "Tidak Aktif"} 
                            />
                            <ItemDetail 
                                label="Status Buka/Tutup" 
                                value={fields.venue.isActive ? "Buka" : "Tutup"} 
                            />
                            <ItemDetail label="Dibuat Tanggal" value={FormatDate(fields.venue.createdAt)} />
                            <ItemDetail label="Dibuat Oleh" value={fields.venue.createBy === "" ? "-" : fields.venue.createBy} />
                            <ItemDetail label="Diubah Tanggal" value={FormatDate(fields.venue.updatedAt)} />
                            <ItemDetail label="Diubah Oleh" value={fields.venue.updatedBy === "" ? "-" : fields.venue.updatedBy} />
                        </Col>
                    </Row>

                    <DetailTitleSection title="Schedule & Harga" />
                    <Row>
                        <Col span={15}>
                            <Row className="custom-table-head-section">
                                <Col span={24}>
                                    <Row className="custom-table-head-container">
                                        <Col span={4}>Hari</Col>
                                        <Col span={5}>Jam</Col>
                                        <Col span={5}>Deposit</Col>
                                        <Col span={5}>Harga</Col>
                                        <Col>Discount</Col>
                                    </Row>
                                </Col>
                            </Row>
                            {fields.operation.map((datum, key) => (
                                <Row className="custom-table-body-section" key={key}>
                                    <Col span={24}>
                                        <Row className="custom-table-body-container">
                                            <Col span={4}>{datum.day === 0 ? "Minggu" : datum.day === 1 ? "Senin" : datum.day === 2 ? "Selasa" : datum.day === 3 ? "Rabu" : datum.day === 4 ? "Kamis" : datum.day === 5 ? "Jumat" : datum.day === 6 ? "Sabtu" : "-" }</Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.timeStart} - {timerate.timeEnd}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{FormatRp(timerate.deposit)}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{FormatRp(timerate.rate)}</div>
                                                ))}
                                            </Col>
                                            <Col span={5}>
                                                {datum.timeRate.map((timerate, key) => (
                                                    <div key={key}>{timerate.isDiscPercentage ? `${timerate.discount}%` : FormatRp(timerate.discount)}</div>
                                                ))}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            ))}
                        </Col>
                    </Row>

                    <Row style={{paddingTop: "20px"}}>
                        <Col>
                            <Link to="/service">
                                <CancelButton label="Kembali" />
                            </Link>
                        </Col>
                    </Row>
                </DetailPageLayout>
            )}
        </React.Fragment>
    )
}

export default DetailService
