import React, {useState, useEffect} from 'react'
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, Select, } from "antd"

interface Props {
    handleFilter: (values:{}) => void
}

const FilterReviewHobby: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)
    const [rates, setRates] = useState<number[]>([])

    const toggle = () => {
        setModal(!modal)
    }

    const addRate = () => {
        const groupRates = []
        for (let i:number = 1; i < 6; i++) {
            groupRates.push(i)
        }

        setRates(groupRates)
    }

    useEffect(() => {
        addRate()
    }, [])

    const handleSubmit = (values:{}) => {
        props.handleFilter(values)
        form.resetFields()
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-filter" /> Filter</div>}
                onClick={toggle}
            />

            <Modal
                title="Fiter Review"
                onCancel={toggle}
                visible={modal}
            >
                 <Form
                    onFinish={handleSubmit}
                    layout="vertical"
                    form={form}
                >
                    <Row style={{paddingBottom: "20px"}}>
                        <Col span={24}>
                            <Form.Item
                                label="Rate Review"
                                name="ratingMerchant"
                            >
                                <Select placeholder="Pilih rate">
                                    {rates.map((rate, key) => {
                                        const renderStar = (data:number) => {
                                            const stars = []
                                            for (var i = 0; i < data; i++) {
                                                stars.push(<i key={i} className="fas fa-star" style={{color: "green"}} />)
                                            }
                                            
                                            return stars
                                        }

                                        return (
                                            <Select.Option key={key} value={rate}>
                                                {renderStar(rate)}
                                            </Select.Option>
                                        )
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{textAlign: "center"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterReviewHobby
