import React from 'react'
import {Button} from "antd"

import "./cancelButton.scss"

interface Props {
    onClick?: () => void,
    label: string
}

const CancelButton: React.FC<Props> = (props) => {
    return (
        <Button
            onClick={props.onClick}
            className="cancel-button"
        >
            {props.label}
        </Button>
    )
}

export default CancelButton
