import React from 'react'
import {Layout, Row, Col} from "antd"
import {Sidebar, Header, MainContent} from "../../organisms"

import "./mainlayout.scss"
const {Content} = Layout

const MainLayout: React.FC = () => {
    return (
        <React.Fragment>
            <Layout>
                <Sidebar />
                <Layout>
                    <Content>
                        <Header />
                        <Row className="page-container">
                            <Col span={24} className="page">
                                <MainContent />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </React.Fragment>
    )
}

export default MainLayout
