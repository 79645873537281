import React, {useState} from 'react'
import {Row, Col} from "antd"
import {Modal} from "../../../../components/atoms"
import AccountForm from "../accountForm/AccountForm"

const EditAccount: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmit = (values: any) => {
        console.log(values);
    }

    return (
        <React.Fragment>
            <div onClick={toggle}>
                <Row>
                    <Col span={8}>
                        <i className="far fa-edit" />
                    </Col>
                    <Col span={16}>
                        Edit
                    </Col>
                </Row>
            </div>

            <Modal
                visible={visible}
                title="Edit Akun"
                onCancel={toggle}
            >
                <AccountForm onFinish={handleSubmit} toggle={toggle} />
            </Modal>
        </React.Fragment>
    )
}

export default EditAccount
