import React from 'react'
import {Dropdown, Menu, Row, Col, Button} from "antd"
import {Link} from "react-router-dom"

import "./merchantbox.scss"

interface Props {
    logo: string,
    name: string,
    link: string,
    onClick: () => void,
    editLink: string,
    saveMerchantId?: () => void
}

const MerchantBox: React.FC<Props> = (props) => {
    const menu = (
        <Menu style={{width: "90px"}}>
            <Menu.Item>
                <Link to={props.editLink} >
                    <Row>
                        <Col span={10}>
                            <i className="fas fa-edit" />
                        </Col>
                        <Col span={14}>
                            Edit
                        </Col>
                    </Row>
                </Link>
            </Menu.Item>
        </Menu>
      );

    return (
        <div className="box-merchant-container">
            <div className="edit-merchant-container" >
                <Dropdown overlay={menu} placement="bottomCenter">
                    <i className="fas fa-ellipsis-v edit-merchant-icon" />
                </Dropdown>
            </div>
            <div className="merchant-logo-container">
                <img src={props.logo} alt="merchant logo" className="merchant-logo" />
            </div>
            <div className="merchant-name-container">
                {props.name}
            </div>
            <div className="dashboard-button-container">
                <Link to={props.link}>
                    <Button className="dashboard-button" onClick={props.onClick}>Dashboard</Button>
                </Link>
            </div>
        </div>
    )
}

export default MerchantBox
