import React, {useState} from 'react'
import axios from "axios"
import {base_url} from "../../../config/url-config"
import {Row, Col, Form, Input, notification} from "antd"
import {Modal, OkButton, BackButton} from "../../atoms"

import "./changepassword.scss"

const ChangePassword: React.FC = () => {
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
        form.resetFields()
    }

    const handleSubmit = async(values:{confirm?:string, userID?:string|null}) => {
        delete values.confirm
        values["userID"] = localStorage.getItem("userId")
        console.log(values);

        setLoading(true)
        try {
            await axios.put(`${base_url}/v3/client/user/password`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            setLoading(false)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Mengubah password berhasil!',
                placement: "topRight",
            });
            toggle()
        } catch(error) {
            setLoading(false)
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengubah password',
                placement: "topRight",
            });
        }
    }

    return (
        <React.Fragment>
            <Row onClick={toggle}>
                <Col span={6}>
                    <i className="fas fa-user-lock" />
                </Col>
                <Col span={18}>
                    Ubah Password
                </Col>
            </Row>

            <Modal
                title="Ubah Password"
                onCancel={toggle}
                visible={modal}
            >
                <Form
                    layout="vertical"
                    requiredMark={false}
                    onFinish={handleSubmit}
                    form={form}
                >
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="oldPassword"
                                label="Password Lama"
                                rules={[
                                {
                                    required: true,
                                    message: 'Password lama wajib diisi!',
                                },
                                ]}
                            >
                                <Input.Password 
                                    placeholder="Input password lama"
                                    className="form-input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="newPassword"
                                label="Password Baru"
                                rules={[
                                {
                                    required: true,
                                    message: 'Password baru wajib diisi!',
                                },
                                ]}
                                hasFeedback
                            >
                                <Input.Password 
                                    placeholder="Input password baru"
                                    className="form-input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={24}>
                            <Form.Item
                                name="confirm"
                                label="Konfirmasi Password Baru"
                                dependencies={['newPassword']}
                                hasFeedback
                                rules={[
                                {
                                    required: true,
                                    message: 'Konfirmasi password baru wajib diisi!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('Ups! password baru dan konfirmasi password baru tidak sama!'));
                                    },
                                }),
                                ]}
                            >
                                <Input.Password 
                                    placeholder="Input ulang password baru"
                                    className="form-input"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{textAlign: "center", marginTop: "10px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton
                                    loading={loading}
                                >
                                    Simpan
                                </OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default ChangePassword
