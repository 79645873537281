import React, {useState} from 'react'
import {Form, Row, Col,Input, Select, Switch} from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {Link} from "react-router-dom"
import {OkButton, BackButton, UploadPhoto} from "../../../../../components/atoms"

interface Props {
    submitPhoto: (path:string) => void
    data: {
        image:string,
        name:string,
        maxQty:number|null,
        description:string,
        price:number|null,
        discount:number|null,
        itemCategories:string[]|null,
        isActive:boolean
    }
    category:{
        label:string, 
        value:string
    }[]
    handleSubmit: (values:{image:string, maxQty:any, price:any, discount:any, itemCategories: any}) => void
}

const { Option } = Select;

const FormItem: React.FC<Props> = (props) => {
    const [disabled, setDisabled] = useState<boolean>(props.data.price === null ? true : false)
    const [price, setPrice] = useState<string>(props.data.price === null ? "" : String(props.data.price))
    const [discount, setDiscount] = useState<string>(props.data.discount === null ? "0" : `${props.data.discount}`)
    const [isPercentage, setIsPercentage] = useState<string>("FIXED")

    const handlePrice = (e:any) => {
        setPrice(e.target.value)
        e.target.value === "" ? setDisabled(true) : setDisabled(false)
    }

    const handleDiscount = (e:any) => {
        setDiscount(e.target.value)
    }

    const handleDiscountUnit = (value:string) => {
        setIsPercentage(value)
    }

    const handleSubmit = (values:{image:string, maxQty:any, price:any, discount:number, itemCategories: any}) => {
        values["discount"] = isPercentage === "FIXED" ? parseInt(discount) : parseInt(discount) / 100 * parseInt(price)
        // console.log(values);
        props.handleSubmit(values)
    }

    return (
        <Form onFinish={handleSubmit}>
            <Row>
                <Col span={16}>
                    <Row className="row-edit-hobby">
                        <Col>
                            <Form.Item
                                name="image"
                                valuePropName="file"
                                initialValue={props.data.image}
                            >
                                <UploadPhoto 
                                    featureName="jasa"
                                    imageURL={props.data.image}
                                    getLink={(url) => props.submitPhoto(url)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Nama Item</Col>
                        <Col span={14}>
                            <Form.Item
                                name="name"
                                initialValue={props.data.name}
                                rules={[{ required: true, message: 'Nama item wajib diisi!' }]}
                            >
                                <Input 
                                    className="form-input" 
                                    placeholder="Input nama item"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Maksimal Pesan</Col>
                        <Col span={14}>
                            <Form.Item
                                name="maxQty"
                                initialValue={props.data.maxQty}
                                rules={[
                                    { required: true, message: 'Maksimal pesan wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]{0,9}$"), message: 'Maksimal pesan yang anda input melebihi batas!'}
                                ]}
                            >
                                <Input
                                    type="number" 
                                    className="form-input" 
                                    placeholder="Input maksimal pesan"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Deskripsi</Col>
                        <Col span={14}>
                            <Form.Item
                                name="description"
                                initialValue={props.data.description}
                                rules={[{ required: true, message: 'Deskripsi wajib diisi!' }]}
                            >
                                <Input.TextArea 
                                    className="form-input" 
                                    placeholder="Input deskripsi"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Kategori</Col>
                        <Col span={14}>
                            <Form.Item
                                name="itemCategories"
                                initialValue={props.data.itemCategories !== null ? props.data.itemCategories[0] : null}
                                rules={[{ required: true, message: 'Kategori wajib dipilih!' }]}
                            >   
                                <Select
                                    placeholder="Pilih kategori"
                                >
                                    {props.category.map((datum, key) => (
                                        <Option value={datum.value} key={key}>{datum.label}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Harga</Col>
                        <Col span={14}>
                            <Form.Item
                                name="price"
                                initialValue={props.data.price}
                                rules={[
                                    { required: true, message: 'Harga wajib diisi!' },
                                    { pattern: new RegExp("^[0-9]{0,8}$"), message: 'Harga yang anda input melebihi batas!'}
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input" 
                                    placeholder="Input harga"
                                    onChange={e => handlePrice(e)}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="row-edit-hobby">
                        <Col span={7}>Diskon</Col>
                        <Col span={11}>
                            <Form.Item
                                name="discount"
                                initialValue={props.data.discount}
                                rules={[
                                    { required: true, message: 'Diskon wajib diisi!' },
                                    {
                                        validator: (_, value) =>
                                            isPercentage === "FIXED" ? parseInt(value) >= parseInt(price) ? Promise.reject('Diskon tidak boleh lebih dari harga!') : Promise.resolve() : value >= 100 ? Promise.reject('Persen tidak boleh lebih dari 100!') : Promise.resolve()
                                    },
                                ]}
                                
                            >
                                <Input
                                    type="number"
                                    className="form-input" 
                                    placeholder="Input diskon"
                                    disabled={disabled}
                                    onChange={(e:any) => handleDiscount(e)}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={3} style={{paddingLeft: "7px"}}>
                            <Select
                                value={isPercentage}
                                onChange={handleDiscountUnit}
                                disabled={disabled}
                                style={{width: "100%"}}
                            >
                                <Select.Option value="PERCENTAGE">%</Select.Option>
                                <Select.Option value="FIXED">IDR</Select.Option>
                            </Select>
                        </Col>
                    </Row>
                    {discount  !== "" ? (
                        <Row className="row-edit-hobby">
                            <Col span={7}></Col>
                            <Col span={14}>
                                <Input 
                                    className="form-input"
                                    value={isPercentage === "FIXED" ? 
                                        `Diskon dalam persen : ${parseInt(discount) / parseInt(price) * 100}%` 
                                        : 
                                        `Diskon dalam Rupiah : Rp ${parseInt(discount) / 100 * parseInt(price)}`
                                    }
                                    style={{color: "#009b00"}}
                                    disabled
                                />
                            </Col>
                        </Row>
                    ) : null}
                    <Row>
                        <Col span={7}>Tersedia</Col>
                        <Col span={14}>
                            <Form.Item
                                name="isActive"
                                valuePropName= "checked"
                                initialValue={props.data.isActive}
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />} 
                                    // defaultChecked={data.Status === "OPEN" ? true : false} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {/* <Row className="row-edit-hobby">
                        <Col span={7}></Col>
                        <Col span={14}>
                            <Form.Item
                                name="discount"
                                initialValue={props.data.discount}
                                rules={[
                                    { required: true, message: 'Diskon wajib diisi!' },
                                ]}
                            >
                                <Input
                                    type="number"
                                    className="form-input" 
                                    placeholder="Input diskon"
                                    // value={diskon/price*100}
                                />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    <Row style={{paddingTop: "20px"}}>
                        <Col span={24}>
                            <Form.Item>
                                <OkButton>Simpan</OkButton>
                                <Link to="/item">
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Form.Item>
                        </Col>
                    </Row> 
                </Col>
            </Row>
        </Form>
    )
}

export default FormItem
