import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Calendar, Select, Spin, Col, Row, Typography, Card } from 'antd';
import useSchedule from './use-schedule';
import moment from 'moment';
// const { Option } = Select;

function useQuery() {
    return new URLSearchParams(useLocation().search);
}


function Schedule() {
    const query = useQuery();
    const history = useHistory();
    const { response, loading } = useSchedule(query.toString());

    const onSelect = (value: any) => {

        query.set('startDate', value.hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'));
        query.set('endDate', value.hours(23).minutes(59).seconds(59).format('YYYY-MM-DDTHH:mm:ss'));
        history.push({ pathname: `/service/jadwal`, search: query.toString() });
    };

    function disabledDate(current?: any) {
        let customDate = moment().format('YYYY-MM-DD');
        return current && current < moment(customDate, "YYYY-MM-DD");
    }

    return (
        <React.Fragment>
            <div className="pagelist-container">
                <Card style={{ marginBottom: '20px', borderRadius: '10px' }}>
                    <Calendar
                        fullscreen={false}
                        onSelect={onSelect}
                        disabledDate={disabledDate}
                        headerRender={({ value, type, onChange, onTypeChange }) => {
                            const start = 0;
                            const end = 12;
                            const monthOptions = [];

                            const current = value.clone();
                            const localeData = value.localeData();
                            const months = [];
                            for (let i = 0; i < 12; i++) {
                                current.month(i);
                                months.push(localeData.monthsShort(current));
                            }

                            for (let index = start; index < end; index++) {
                                monthOptions.push(
                                    <div className="month-item" key={`${index}`}>
                                        {months[index]}
                                    </div>,
                                );
                            }
                            const month = value.month();

                            const year = value.year();
                            const options = [];
                            for (let i = year - 10; i < year + 10; i += 1) {
                                options.push(
                                    <Select.Option key={i} value={i} className="year-item">
                                        {i}
                                    </Select.Option>,
                                );
                            }
                            return (
                                <div style={{ padding: 2, }}>
                                    <Typography.Title level={4}>Pilih Jadwal</Typography.Title>
                                    <Row gutter={8}>
                                        <Col>
                                            <Select
                                                size="large"
                                                dropdownMatchSelectWidth={false}
                                                className="my-year-select"
                                                onChange={(newYear: number) => {
                                                    const now = value.clone().year(newYear);
                                                    onChange(now);
                                                }}
                                                value={year}
                                            >
                                                {options}
                                            </Select>
                                        </Col>
                                        <Col>
                                            <Select
                                                size="large"
                                                dropdownMatchSelectWidth={false}
                                                value={String(month)}
                                                onChange={selectedMonth => {
                                                    const newValue = value.clone();
                                                    newValue.month(parseInt(selectedMonth, 10));
                                                    onChange(newValue);
                                                }}
                                            >
                                                {monthOptions}
                                            </Select>
                                        </Col>
                                    </Row>
                                </div>
                            );
                        }}
                    />


                </Card>


                <Typography.Title level={4}>Jam Operasional</Typography.Title>

                {
                    loading && <Spin />
                }


                {
                    !loading && response.payload.records !== null ? <Row gutter={[16, 10]}>
                        {response.payload.records.map((item, key) => <Time time={item.time} value={item.value} />)}
                    </Row> : <Card><Typography.Text style={{ color: "gray", textAlign: "center" }}>Jam Operasional tersedia</Typography.Text></Card>
                }
            </div>
        </React.Fragment>)
}


const Time: React.FC<{ time: string, value?: any }> = (props) => {
    return <Col xs={{ span: 24 / 3, }} sm={{ span: 24 / 4 }} md={{ span: 24 / 6 }}>
        <div style={{ border: '1px solid #EEEEEE', paddingTop: '10px', paddingBottom: '10px', borderRadius: "5px", textAlign: 'center', fontSize: '16px', color: props.value !== null ? "#9E9E9E" : "#263238", background: props.value !== null ? "gray" : "white" }}>
            {props.time}
        </div>
    </Col>
}
export default Schedule;