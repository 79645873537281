import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router"
import {useLocation} from "react-router-dom"
import {IDetail, initialDataDetail, IOrderItem} from "../../listtransaction/transactionfood/ModelTransaction"
import {base_url} from "../../../../../config/url-config"
import {transactionfood} from "../../../../../config/edpoint"
import axios from "axios"
import {DetailPageLayout} from "../../../../templates"
import {Loading, OkButton, CancelButton, BackButton} from "../../../../../components/atoms"
import {Row, Col} from "antd"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"

const DetailTransactionFood: React.FC = () => {
    const history = useHistory()
    const location = useLocation()
    const orderID = location.search.split("=")[1]
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>(initialDataDetail)

    const fetchData = async(orderId:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionfood}/detil/${orderId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch(error) {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(orderID)
    }, [orderID])

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        {fields.status !== "CREATED" && fields.status !== "UNPAID" && fields.status !== "CANCELED" && fields.status !== "REJECTED" && fields.status !== "EXPIRED" && fields.status !== "DONE" && fields.status !== "FAILED" ? (
                            <div>
                                {/* {fields.status === "PAID" && fields.shopperID === 0 ? <Alert message="Hi! Kamu belum bisa APPROVED transaksi sebelum memilih shopper. Silahkan pilih shopper terlebih dahulu ya!" type="success" /> : null} */}
                                <Row style={{ marginTop: "10px" }}>
                                    <Col span={24}>
                                        <OkButton
                                            // onClick={() => handleUpdateStatus(fields.status === "PAID" ? "APPROVED" : fields.status === "APPROVED" ? "PROCESS" : fields.status === "PROCESS" ? "SEND" : "DONE")}
                                            disabled={fields.status === "PAID" ? true : false}
                                        >
                                            {fields.status === "PAID" ? "APPROVED" : fields.status === "APPROVED" ? "PROCESS" : fields.status === "PROCESS" ? "SEND" : "DONE"}
                                        </OkButton>
                                        <CancelButton
                                            label={fields.status === "PAID" ? "REJECTED" : "CANCELED"}
                                            // onClick={() => handleUpdateStatus(fields.status === "PAID" ? "REJECTED" : "CANCELED")}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Order" value={fields.invoiceNumber} />
                                <ItemDetail label="User" value={fields.username} />
                                <ItemDetail label="User Email" value={fields.userEmail === "" ? "-" : fields.userEmail} />
                                <ItemDetail label="Telepon User" value={fields.userPhone === "" ? "-" : fields.userPhone} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.createdAt)} />
                                <ItemDetail label="Status Order" value={fields.status} />
                                <ItemDetail label="Alamat Order" value={fields.userAddress} />
                                <ItemDetail label="Catatan Alamat" value={fields.userNote === "" ? "-" : fields.userNote} />
                                {/* <ItemDetail label="Estimasi Pengiriman" value={fields.estimasi === "" ? "-" : fields.estimasi} /> */}
                                {/* <ItemDetail label="Biaya Layanan" value={<div style={{textAlign:"right"}}>{fields.serviceFee !== null ? FormatRp(fields.serviceFee) : 0}</div>} /> */}
                                <ItemDetail label="Tanggal Diubah" value={FormatDate(fields.updatedAt)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Item Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Nama Item</Col>
                                            <Col span={7}>Note</Col>
                                            <Col span={4}>Kuantiti</Col>
                                            <Col span={4}>Diskon</Col>
                                            <Col span={4}><div style={{float: "right"}}>Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.orderItems.map((item: IOrderItem, key: number) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{ border: "1px solid black" }}>
                                                <Col span={5}>{item.productName}</Col>
                                                <Col span={7}>{item.note === "" ? "-" : item.note}</Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>{item.discountIsInPercentage ? `${item.discount}%` : FormatRp(item.discount)}</Col>
                                                <Col span={4}><div style={{ float: "right" }}>{FormatRp(item.price)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Total Order" value={<div style={{textAlign:"right"}}>{FormatRp(fields.totalRaw)}</div>} />
                                <ItemDetail label="Total Diskon" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalDiscount)}</div>} />
                                <ItemDetail label="Total Promo" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalPromo)}</div>} />
                                <ItemDetail label="Total" value={<div style={{textAlign:"right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.totalOrder)}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pengiriman" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Status Pengiriman" value={fields.deliveryState === "" ? "-" : fields.deliveryState} />
                                <ItemDetail label="Nama Driver" value={fields.driverName === "" ? "-" : fields.driverName} />
                                <ItemDetail label="Telepon Driver" value={fields.driverPhone === "" ? "-" : fields.driverPhone} />
                                <ItemDetail label="Biaya Pengiriman" value={FormatRp(fields.deliveryCost)} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Pembayaran" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Status Pembayaran" value={fields.paymentDetail.paymentState} />
                                <ItemDetail label="Metode Pembayaran" value={fields.paymentDetail.paymentMethod} />
                                <ItemDetail label="Provider Pembayaran" value={fields.paymentDetail.paymentProvider} />
                                <ItemDetail label="Total Pesananan" value={
                                    <div style={{ textAlign: "right", fontWeight: "bold", color: "black" }}>
                                        {FormatRp(fields.totalOrder)}
                                    </div>
                                } />
                                <ItemDetail label="Biaya Admin" value={<div style={{ textAlign: "right" }}>{FormatRp(fields.adminFee)}</div>} />
                                <ItemDetail label="Biaya Kirim" value={<div style={{ textAlign: "right" }}>{FormatRp(fields.deliveryCost)}</div>} />
                                <ItemDetail label="Total Bayar" value={<div style={{ textAlign: "right", color: "black", fontWeight: "bold" }}>{FormatRp(fields.totalBayar)}</div>} />
                            </Col>
                        </Row>
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                {/* <Link to="/order"> */}
                                    <BackButton
                                        onClick={() => history.goBack()}
                                    >
                                        Kembali
                                    </BackButton>
                                {/* </Link> */}
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailTransactionFood
