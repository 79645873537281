import React from 'react'
import {Button} from "antd"

import "./backbutton.scss"

interface Props {
    onClick?:() => void
}

const BackButton: React.FC<Props> = (props) => {
    return (
        <Button
            onClick={props.onClick}
            className="back-button"
        >
            {props.children}
        </Button>
    )
}

export default BackButton
