export const data = [
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Unpaid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Unpaid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Unpaid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
    {
      topupNumber: "TU-BGM-0001020920",
      totalTopup: 100000,
      status: "Paid",
      topupDate: "2020-11-05T16:35:10.255+07:00",
      nova: "3312231",
    },
];
  