import React from 'react'
import { Form, Row, Col, Input } from "antd"
import { BackButton, OkButton } from "../../../../../../../components/atoms"

interface Props {
    handleSubmit: (values: any) => void
    toggle: () => void
    values: any
}

const FormItem: React.FC<Props> = (props) => {
    const [form] = Form.useForm()

    return (
        <React.Fragment>
            <Form
                onFinish={props.handleSubmit}
                layout="vertical"
                requiredMark={false}
                form={form}
            >
                <Row className="row-edit-hobby">
                    <Col span={24}>
                        <Form.Item
                            name="name"
                            label="Nama"
                            initialValue={props.values.name}
                            rules={[{ required: true, message: 'Nama item wajib diisi!' }]}
                        >
                            <Input
                                className="form-input"
                                placeholder="Input nama item"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="row-edit-hobby">
                    <Col span={24}>
                        <Form.Item
                            name="qty"
                            label="Jumlah"
                            initialValue={props.values.qty}
                            rules={[{ required: true, message: 'Jumlah item wajib diisi!' }]}
                        >
                            <Input
                                className="form-input"
                                placeholder="Input jumlah item"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="row-edit-hobby">
                    <Col span={24}>
                        <Form.Item
                            name="price"
                            label="Harga"
                            initialValue={props.values.price}
                            rules={[{ required: true, message: 'Harga item wajib diisi!' }]}
                        >
                            <Input
                                className="form-input"
                                placeholder="Input harga item"
                                type="number"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                    <Col span={24}>
                        <Form.Item>
                            <BackButton
                                onClick={props.toggle}
                            >
                                Kembali
                            </BackButton>
                            <OkButton>Simpan</OkButton>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

export default FormItem
