import React, { useState, useEffect } from 'react'
import {useHistory} from "react-router"
import {base_url} from "../../../../../config/url-config"
import {merchantfood} from "../../../../../config/edpoint"
import axios from "axios"
import {notification} from "antd"
import { DetailPageLayout } from "../../../../templates"
import { Loading } from "../../../../../components/atoms"
import FormMerchant from "./FormMerchantFood"
// import { useQuery } from '../../../../helpers/Formatter'

const EditMerchantJasa: React.FC = () => {
    const history = useHistory()
    const id = localStorage.getItem("merchantID")
    const [loading, setLoading] = useState<boolean>(false)
    const [ fields, setFields ] = useState<{
        id?: string;
        createdAt?: string;
        updatedAt?: string;
        createdBy?: string;
        description: string;
        urlPhoto: string;
        phoneNumber: string;
        rating: number;
        email: string;
        status: boolean;
        open: boolean;
        orderFeeIsInPercentage: boolean;
        sharingFeeIsInPercentage: boolean;
        tags: any[];
        location?: {
            latitude: number | null
            longitude: number | null
        };
        distance?: number;
        operationHours: {
            active?:boolean
            day: number
            merchantID?: string | null | undefined
            openHour?: string | null
            closeHour?: null | string
        }[];
        categories?: {
            id: string ;
            name: string ;
        }[];
        code?: string;
        address?:string;
        orderFee?:Number | null
        sharingFee?:Number | null
        name?:string,
        latitude?:string,
        longitude?:string,
    }>({
        id: "",
        address: "",
        categories: [],
        description: "",
        email: "",
        location: {
            latitude: null,
            longitude: null
        },
        name: "",
        open: false,
        operationHours: [],
        orderFee: null,
        phoneNumber: "",
        rating: 0,
        sharingFee: null,
        distance:0,
        orderFeeIsInPercentage: false,
        sharingFeeIsInPercentage: false,
        status: false,
        tags: [],
        urlPhoto: "",
    });
    const [operational, setOperational] = useState<{
        active?:boolean
        day: number
        merchantID?: string | null | undefined
        openHour?: string | null
        closeHour?: null | string
    }[]>([
        { active: false, day: 0, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 1, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 2, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 3, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 4, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 5, merchantID: id, openHour: null, closeHour: null },
        { active: false, day: 6, merchantID: id, openHour: null, closeHour: null },
    ])

    const fetchData = async () => {
        setLoading(true)
        try {
            const url = `${base_url}/${merchantfood}/detil/${id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.get(url, { headers: headers });

            if (res.data.statusCode === 200) {
                setFields(res.data.payload)
                if (res.data.payload.operationHours !== undefined) {
                    let newOperationHours = [...operational]
                    for (let i = 0; i < res.data.payload.operationHours.length; i++) {
                        newOperationHours[res.data.payload.operationHours[i].day].active = true
                        newOperationHours[res.data.payload.operationHours[i].day].openHour = res.data.payload.operationHours[i].openHour
                        newOperationHours[res.data.payload.operationHours[i].day].closeHour = res.data.payload.operationHours[i].closeHour
                    }
                    setOperational(newOperationHours)
                } 
                setLoading(false)
            } else {
                throw new Error(res.data);
            }
        } catch (error) {
            if (error.response) {
                notification.error({
                    message: 'Wops!',
                    description:
                        'Terjadi kesalahan saat mengakses data merchant',
                    placement: "topRight"
                });
            }
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const submitOperationHourStatus = (e: any, index: number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].active = e.target.checked
        setOperational(newOperationHours)
    }

    const submitOperationHourOpenCLose = (time: any, timeString: any, index: number) => {
        const newOperationHours = [...operational]
        newOperationHours[index].openHour = timeString[0]
        newOperationHours[index].closeHour = timeString[1]
        setOperational(newOperationHours)
    }

    const submitOperationalHour = () => {
        // const weekdays: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const newOperationHours = (operational.filter(operationHour => operationHour.active === true))
        newOperationHours.forEach(newOperationHour => {
            delete newOperationHour.active
            newOperationHour.merchantID === null && delete newOperationHour.merchantID
        })
        console.log(newOperationHours);
        setFields({...fields, operationHours: newOperationHours})
    }

    const handleSubmit = async (values: any) => {
        const data = {
            ...fields,
            ...values
        }

        data.sharingFeeIsInPercentage = JSON.parse(data.sharingFeeIsInPercentage.toString());
        data.sharingFee = Number(values.sharingFee);
        data.location = {
            latitude: Number(values.latitude),
            longitude: Number(values.longitude)
        }
        data.urlPhoto = fields.urlPhoto;
        data.orderFee = Number(values.orderFee);
        data.orderFeeIsInPercentage = JSON.parse(data.orderFeeIsInPercentage.toString());
        
        setLoading(true)
        try {
            const url = `${base_url}/${merchantfood}/update/${id}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };

            const res = id !== null ? await axios.put(url, data, { headers: headers }) : await axios.post(url, data, { headers: headers })
            if (res.data.statusCode === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Data merchant berhasil diperbaharui.',
                });
                setLoading(false)
                history.goBack()
            } else {
                notification["error"]({
                    message: 'Ups!',
                    description: res.data.message,
                });
                setLoading(false)
            }

            throw new Error(res.data);
        } catch (error) {
            if (error.response) {

                notification["error"]({
                    message: 'Ups!',
                    description:
                        'Terjadi kesalahan pada saat memperbaharui data merchant.',
                });
            }
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormMerchant
                        data={fields}
                        setFields={setFields}
                        operational={operational}
                        submitOperationHourStatus={submitOperationHourStatus}
                        submitOperationHourOpenCLose={submitOperationHourOpenCLose}
                        submitOperationalHour={submitOperationalHour}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default EditMerchantJasa
