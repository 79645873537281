import React, {useState, useEffect} from 'react'
import {useLocation} from "react-router-dom"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itemfood} from "../../../../../config/edpoint"
import {DetailPageLayout} from "../../../../templates"
import {Loading, BackButton} from "../../../../../components/atoms"
import {Row, Col, notification} from "antd"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"

const Detail: React.FC = () => {
    const location = useLocation()
    const itemID = location.search.split("=")[1]
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        // categories?: ICategories[];
        description?: string;
        discount?: any;
        discountIsInPercentage: boolean;
        harga?: any;
        isPromo?: boolean;
        is_order_fee_percentage: boolean;
        is_sharing_fee_percentage: boolean;
        merchant_id?: string | null | undefined;
        name: string;
        order_fee: number;
        sharing_fee: number;
        status: boolean;
        tags: string[];
        urlphoto: string;
        createdAt: string;
        updatedAt: string;
    }>({
        description: "",
        discount: null,
        discountIsInPercentage: false,
        harga: null,
        isPromo: false,
        is_order_fee_percentage: false,
        is_sharing_fee_percentage: true,
        merchant_id: "",
        name: "",
        order_fee: 0,
        sharing_fee: 0,
        status: false,
        createdAt: "",
        updatedAt: "",
        tags: [],
        urlphoto: ""
    })

    const fetchData = async (itemId: string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemfood}/detil/${itemId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })

            setFields({ ...res.data.payload, tags: res.data.payload.tags === undefined ? [] : res.data.payload.tags })
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(itemID)
    }, [itemID])

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        <Row>
                            <Col span={4}>
                                <img src={fields.urlphoto} alt="item-pict" className="item-photo" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Item" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nama Item" value={fields.name} />
                                <ItemDetail label="Deskripsi" value={fields.description} />
                                <ItemDetail label="Harga" value={<div style={{ textAlign: "right" }}>{FormatRp(Number(fields.harga))}</div>} />
                                <ItemDetail label="Diskon" value={<div style={{ textAlign: "right" }}>{fields.discountIsInPercentage ? `${fields.discount}%` : FormatRp(fields.discount)}</div>} />
                                <ItemDetail 
                                    label="Tags" 
                                    value={
                                        <Row>
                                            {fields.tags.map((tag:string, key:number) => (
                                                <Col key={key} span={11} style={{padding: "5px 10px"}}>
                                                    <div className="tag">
                                                        {tag}
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    } 
                                />
                                <ItemDetail label="Status" value={fields.status ? "Aktif" : "Tidak Aktif"} />
                                <ItemDetail label="Promo" value={fields.isPromo ? "Aktif" : "Tidak Aktif"} />
                                <ItemDetail label="Dibuat Pada" value={FormatDate(fields.createdAt)} />
                                <ItemDetail label="Diubah Pada" value={FormatDate(fields.updatedAt)} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "20px" }}>
                            <Col>
                                <Link to={`/item`}>
                                    <BackButton>Kembali</BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Detail
