import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {orderItemJasa} from "../../../../../config/edpoint"
import {DetailPageLayout} from "../../../../templates"
import {Row, Col, notification} from "antd"
import {Loading, CancelButton, OkButton, BackButton} from "../../../../../components/atoms"
import {DetailTitleSection, ItemDetail} from "../../../../../components/molecules"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import EditOrder from "./editTransactionOrder/EditOrder"
import CreateOrder from "./createTransactionOrder/CreateOrder"

import "./detailtransactionjasa.scss"

const DetailTransactionJasa: React.FC = () => {
    const location = useLocation()
    const bookingId = location.search.split("=")[1]
    const [fields, setFields] = useState<{
        id:string,
        invoiceNumber:string,
        orderNumber:number,
        userName:string,
        orderDate:string,
        status:string,
        address:string,
        orderNote:string,
        totalPrice:number,
        totalDiscount:number,
        totalPromo:number,
        totalPay:string,
        sharingFee:number,
        paymentData: {
            paymentMethod:string,
            paymentProvider:string,
            paymentState:string,
            invoiceAmount:number,
            adminFee:number,
            totalAmount:number
        },
        items:{
            name:string,
            note:string,
            qty:number,
            discount:number,
            price:number
        }[],
        merchantID:string
    }>({
        id: "",
        invoiceNumber: "",
        orderNumber: 0,
        userName: "",
        orderDate: "",
        status: "",
        address: "",
        orderNote: "",
        totalPrice: 0,
        totalDiscount: 0,
        totalPromo: 0,
        totalPay: "",
        sharingFee: 0,
        paymentData: {
            paymentMethod: "",
            paymentProvider: "",
            paymentState: "",
            invoiceAmount: 0,
            adminFee: 0,
            totalAmount: 0
        },
        items: [],
        merchantID: "e"
    })
    const [loading, setLoading] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async (id:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${orderItemJasa}/${id}`

            const res = await axios.get(url, {
                headers: headers
            })
            setLoading(false)
            setFields(res.data.payload)
        } catch (error) {
            console.log(error.response);
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengakses data transaksi',
                placement: "topRight",
            });
        }
    }

    useEffect(() => {
        fetchData(bookingId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingId])

    const handleAccept = () => {
        handleUpdateTransaction("APPROVE")
    }

    const handleReject = () => {
        handleUpdateTransaction("CANCEL")
    }

    const handleUpdateTransaction = async(status:string) => {
        const value = {orderID: fields.id, state: status, merchantID: fields.merchantID}
        setLoading(true)
        try {
            const url = `${base_url}/${orderItemJasa}/byMerchant/confirm`

            await axios.post(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    const handleNextStep = async() => {
        const value = {orderID: fields.id, merchantID: fields.merchantID}
        setLoading(true)
        try {
            const url = `${base_url}/${orderItemJasa}/byMerchant/next`

            await axios.post(url, value, {
                headers: headers
            })
            notification["success"]({
                message: 'Yeay!',
                description:
                    'Memperbaharui status transaksi berhasil.',
            });
            setLoading(false)
            fetchData(bookingId)
        } catch (error) {
            setLoading(false)
            notification["error"]({
                message: 'Ups!',
                description:
                  'Terjadi kesalahan saat memperbaharui status transaksi.',
            });
        }
    }

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <div>
                        {fields.status === "UNPAID" ? (
                            <Row>
                                <Col span={24}>
                                    <OkButton
                                        onClick={handleAccept}
                                    >
                                        Terima
                                    </OkButton>
                                    <CancelButton 
                                        label="Batal" 
                                        onClick={handleReject} 
                                    />
                                </Col>
                            </Row>
                        ) : fields.status === "PAID" || fields.status === "PROCESS" ? (
                            <OkButton 
                                onClick={handleNextStep}
                            >
                                {fields.status === "PAID" ? "Proses" : "Selesai"}
                            </OkButton>
                        ) : null}
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title="Detail Order" />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <ItemDetail label="Nomer Order" value={fields.invoiceNumber === "" ? "-" : fields.invoiceNumber} />
                                <ItemDetail label="User" value={fields.userName === "" ? "-" : fields.userName} />
                                <ItemDetail label="Tanggal Order" value={FormatDate(fields.orderDate)} />
                                <ItemDetail label="Status Order" value={fields.status} />
                                <ItemDetail label="Alamat Order" value={fields.address} />
                                <ItemDetail label="Catatan Order" value={fields.orderNote === "" ? "-" : fields.orderNote} />
                                <ItemDetail label="Sharing Fee" value={<div style={{textAlign:"right"}}>{FormatRp(fields.sharingFee)}</div>} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <DetailTitleSection title={
                                    <Row>
                                        <Col span={24}>
                                            Detail Order
                                        </Col>
                                    </Row>
                                } />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row>
                                    <Col style={{fontSize: "22px"}} span={12}>
                                        Item Order 
                                    </Col>
                                    <Col span={12} style={{textAlign: "right", fontSize: "20px"}}>
                                        {fields.status === "UNPAID" || fields.status === "APPROVED" ? (
                                            <EditOrder 
                                                items={fields.items}
                                                fetchData={fetchData}
                                                bookingId={bookingId}
                                            />
                                        ) :  null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Nama Item</Col>
                                            <Col span={7}>Note</Col>
                                            <Col span={4}>Kuantiti</Col>
                                            <Col span={4}>Diskon</Col>
                                            <Col span={4}><div style={{float: "right"}}>Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.items.filter((item:any) => item.itemID !== null).map((item, key) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={5}>{item.name}</Col>
                                                <Col span={7}>{item.note === "" ? "-" : item.note}</Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>{FormatRp(item.discount)}</Col>
                                                <Col span={4}><div style={{float: "right"}}>{FormatRp(item.price)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        ))}
                        <Row style={{marginTop: "10px"}}>
                            <Col span={16}>
                                <Row>
                                    <Col style={{fontSize: "22px"}} span={12}>
                                        Item Lainnya 
                                    </Col>
                                    <Col span={12} style={{textAlign: "right", fontSize: "21px"}}>
                                        {fields.status === "PAID" || fields.status === "PROCESS" ? (
                                            <CreateOrder
                                                orderID={fields.id} 
                                                items={fields.items} 
                                                fetchData={fetchData} 
                                                bookingId={bookingId} 
                                            />
                                        ) : null}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Row className="custom-table-head-section">
                                    <Col span={24}>
                                        <Row className="custom-table-head-container">
                                            <Col span={5}>Nama Item</Col>
                                            <Col span={7}>Note</Col>
                                            <Col span={4}>Kuantiti</Col>
                                            <Col span={4}>Diskon</Col>
                                            <Col span={4}><div style={{float: "right"}}>Harga</div></Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {fields.items.filter((item:any) => item.itemID === null).length > 0 ? fields.items.filter((item:any) => item.itemID === null).map((item, key) => (
                            <Row key={key}>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={5}>{item.name}</Col>
                                                <Col span={7}>{item.note === "" ? "-" : item.note}</Col>
                                                <Col span={4}>{item.qty}</Col>
                                                <Col span={4}>{FormatRp(item.discount)}</Col>
                                                <Col span={4}><div style={{float: "right"}}>{FormatRp(item.price)}</div></Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )) : (
                            <Row>
                                <Col span={16}>
                                    <Row className="custom-table-body-section">
                                        <Col span={24}>
                                            <Row className="custom-table-body-container" style={{border: "1px solid black"}}>
                                                <Col span={24} style={{textAlign: "center"}}>- Tidak Terdapat Data -</Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        <Row style={{marginTop: "10px"}}>
                            <Col span={16}>
                                <ItemDetail label="Total Order" value={<div style={{textAlign:"right"}}>{FormatRp(fields.totalPrice)}</div>} />
                                <ItemDetail label="Total Diskon" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalDiscount)}</div>} />
                                <ItemDetail label="Total Promo" value={<div style={{textAlign:"right", color: "red"}}>{FormatRp(fields.totalPromo)}</div>} />
                                <ItemDetail label="Total" value={<div style={{textAlign:"right", fontWeight: "bold", color: "black"}}>{FormatRp(fields.totalPrice - fields.totalDiscount - fields.totalPromo)}</div>} />
                            </Col>
                        </Row>
                        {fields.paymentData !== null ? (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        <DetailTitleSection title="Detail Pembayaran" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={16}>
                                        <ItemDetail label="Status Pembayaran" value={fields.paymentData.paymentState} />
                                        <ItemDetail label="Metode Pembayaran" value={fields.paymentData.paymentMethod === "" ? "-" : fields.paymentData.paymentMethod} />
                                        <ItemDetail label="Provider Pembayaran" value={fields.paymentData.paymentProvider} />
                                        <ItemDetail label="Total Pesananan" value={<div style={{textAlign:"right"}}>{FormatRp(fields.paymentData.invoiceAmount)}</div>} />
                                        <ItemDetail label="Biaya Admin" value={<div style={{textAlign:"right"}}>{FormatRp(fields.paymentData.adminFee)}</div>} />
                                        <ItemDetail label="Total Bayar" value={<div style={{textAlign:"right", color: "black", fontWeight: "bold"}}>{FormatRp(fields.paymentData.totalAmount)}</div>} />
                                    </Col>
                                </Row>
                            </div>
                        ) : null}
                        <Row style={{paddingTop: "20px"}}>
                            <Col span={24}>
                                <Link to="/transaksi">
                                    <BackButton>
                                        Kembali
                                    </BackButton>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default DetailTransactionJasa
