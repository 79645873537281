import React, {useState, useEffect} from 'react'
import {IList, initialDataList} from "./ModelTransaction"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {transactionfood} from "../../../../../config/edpoint"
import {IDetail} from "./ModelTransaction"
import {FormatDate, FormatRp} from "../../../../../helpers/Formatter"
import {Row, Col, Pagination} from "antd"
import {Link} from "react-router-dom"
import {PageLayout} from "../../../../templates"
import Filter from "./FilterTransactionFood"
import {Search, Table, FeatureButton} from "../../../../../components/atoms"

const TransactionFood: React.FC = () => {
    const [fields, setFields] = useState<IList>(initialDataList)
    const [params, setParams] = useState<{
        page: number
        limit: number
        isSuperAdmin: boolean
        sortBy: string
        search: string
        merchantId: any
    }>({
        page: 0,
        limit: 10,
        isSuperAdmin: true,
        sortBy: "updated_at",
        search: "",
        merchantId: localStorage.getItem("merchantID")
    })
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${transactionfood}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields(res.data)
            setLoading(false)
        } catch (error) {

        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value})
        }
    }

    const handleFilter = (values:any) => {
        setParams({...params, ...values})
    }

    const handleReset = () => {
        setParams({
            page: 0,
            limit: 10,
            isSuperAdmin: true,
            sortBy: "updated_at",
            search: "",
            merchantId: localStorage.getItem("merchantID")
        })
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        const currentPage = page - 1;
        setParams({ ...params, page: currentPage })
    }

    const columns = [
        {
            title: "No. Order",
            render: (data:IDetail) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.invoiceNumber}</div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.merchantName}</div>
                    <div>{FormatDate(data.createdAt)}</div>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Total Pesanan",
            render: (data:IDetail) => (
                <div>
                    {FormatRp(data.totalPrice)}
                </div>
            ),
            width: "20%"
        },
        {
            title: "Biaya Layanan",
            render: (data:IDetail) => (
                <div>
                    {FormatRp(data.adminFee)}
                </div>
            ),
            width: "15%"
        },
        {
            title: "Pembayaran",
            render: (data:IDetail) => (
                <div>
                    <div>{data.paymentDetail !== null ? data.paymentDetail.paymentProvider : "-"}</div>
                    <div>{data.paymentDetail !== null ? data.paymentDetail.paymentMethod : "-"}</div>
                </div>
            ),
            width: "10%"
        },
        {
            title: "Status",
            render: (data:IDetail) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.status} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.paymentDetail !== null ? data.paymentDetail.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
        },
        {
            title: "Aksi",
            render: (data:IDetail) => {
                return (
                    <Link to={`/transaksi/detail?orderID=${data.id}`} >
                        <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                            <i className="far fa-eye" />
                        </div>
                    </Link>
                )
            }
        }
    ]

    return (
        <React.Fragment>
            <PageLayout 
                leftTop={
                    <div>
                        <Filter 
                            handleFilter={handleFilter}
                        />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                rightTop={<Search 
                    onKeyPress={(e: any) => handleSubmitSearch(e)}
                />}
                content={
                    <div>
                        <Table 
                            columns={columns}
                            data={fields.payload}
                            rowKey="id"
                            loading={loading}
                            pagination={false}
                            rowClassName={(record: { status: string }, index: number) => record.status === "PAID" || record.status === "APPROVED" || record.status === "PROCESS" || record.status === "SEND" ? 'new-transaction' : 'read-transaction'}
                        />
                        <Row style={{ padding: "20px 0px", color: "rgb(112, 112, 112)" }}>
                            <Col span={12}>
                                {`Total ${fields.pagination.totalData} Transaksi`}
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <Pagination
                                    current={fields.pagination.page + 1}
                                    pageSize={fields.pagination.limit}
                                    total={fields.pagination.totalData}
                                    onChange={handlePageChange}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default TransactionFood
