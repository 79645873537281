import {useState, useEffect} from "react"
import {IList} from "../ItemModel"
import {base_url} from "../../../../../config/url-config"
import {itemJasa} from "../../../../../config/edpoint"
import axios from "axios"
import {notification} from "antd"

export const useModelList = () => {
    const [fields, setFields] = useState<IList>({
        currentPage: 1,
        limit: 10, 
        totalPage: 1,
        payload: []
    })
    const [params, setParams] = useState<{}>({
        merchantID: localStorage.getItem("merchantID"),
        page: 1,
        limit: 10,
        q: ""
    })
    const [loading, setLoading] = useState<string>("")
    const [visible, setVisible] = useState<boolean>(false)
    const [hideMenu, setHideMenu] = useState<boolean>(false)
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(params:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${itemJasa}`

            const res = await axios.get(url, {
                headers: headers,
                params: params
            })
            setFields(res.data)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses item merchant',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value, page: 1})
         }
    }

    const handleFilter = (values:{}) => {
        setParams({...params, ...values, page: 1})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantID"),
            page: 1,
            limit: 10,
            q: ""
        })
    }

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    const handlePageChange = (page:number|undefined, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleUpdateStatus = async(id:string, isActive:boolean) => {
        setLoading("status")
        try {
            const url = `${base_url}/${itemJasa}/${id}`

            await axios.put(url, {isActive: isActive}, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            notification.success({
                message: "Yuhuuu",
                description:
                    'Memperbaharui status item berhasil!',
            });
            setLoading("")
            fetchData(params)
        } catch(error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan saat memperbaharui status item',
            });
            setLoading("")
            fetchData(params)
        }
    }

    const handleDelete = async(id:string) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${itemJasa}/${id}`

            await axios.delete(url, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            toggle()
            fetchData(params)
            notification.success({
                message: "Yuhuuu",
                description:
                    'Menghapus data item berhasil!',
                placement: "bottomRight",
            });
            setLoading("")
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam menghapus data item',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    return {
        fields,
        params,
        loading,
        visible,
        hideMenu,
        handleSubmitSearch,
        handleFilter,
        handleReset,
        handleHideMenu,
        handlePageChange,
        toggle,
        handleUpdateStatus,
        handleDelete
    }
}