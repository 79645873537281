import React, {useEffect, useState} from 'react'
import {useLocation} from "react-router-dom"
import {useHistory} from "react-router"
import {base_url} from "../../../../../config/url-config"
import {itemfood, itemfoodcategory} from "../../../../../config/edpoint"
import axios from "axios"
import {notification} from "antd"
import {DetailPageLayout} from "../../../../templates"
import {Loading} from "../../../../../components/atoms"
import FormItem from "../form/Form"

import "./edit.scss"

const Edit: React.FC = () => {
    const location = useLocation()
    const itemID = location.search.split("=")[1]
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<any>({})
    const [category, setCategory] = useState<any>([])

    const fetchData = async (itemId: string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemfood}/detil/${itemId}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers
            })
            setFields({ ...res.data.payload, tags: res.data.payload.tags === undefined ? [] : res.data.payload.tags })
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
        }
    }

    const fetchCategory = async () => {
        try {
            const url = `${base_url}/${itemfoodcategory}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
            
            const res = await axios.get(url, {
                headers: headers,
                params: {merchantId: localStorage.getItem("merchantID")}
            })
            setCategory(res.data.payload);
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
        }
    }

    useEffect(() => {
        fetchData(itemID)
        fetchCategory()
    }, [itemID])

    const submitPhoto = (path: string) => {
        setFields({ ...fields, urlphoto: path })
    }

    const handleSubmit = async (values: any) => {
        values["tags"] = fields.tags
        values["harga"] = parseInt(values.harga)
        values["discount"] = parseInt(values.discount)
        values["merchantId"] = localStorage.getItem("merchantID")
        
        setLoading(true)
        try {
            const url = `${base_url}/${itemfood}/update/${itemID}`
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.put(url, values, { headers: headers })

            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Menambahkan data merchant berhasil.',
                });
                setLoading(false)
                history.goBack();
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormItem
                        data={fields}
                        setData={setFields}
                        category={category}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Edit
