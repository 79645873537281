import React, {useState} from 'react'
import moment from "moment"
import {FeatureButton, Modal, OkButton, BackButton} from "../../../../../../components/atoms"
import {Form, Row, Col, Select, DatePicker} from "antd"

import "./filtertransactionjasa.scss"

const { RangePicker } = DatePicker;

interface Props {
    handleFilter:(values:{}) => void
}

const FilterTransactionJasa: React.FC<Props> = (props) => {
    const [form] = Form.useForm();
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{status:string, date:any[], startDate:any, endDate:any}) => {
        // props.handleFilter(values)
        console.log(values);
        
        if (values.status === undefined) {
            values["startDate"] = moment(values.date[0]).format("YYYY-MM-DD")
            values["endDate"] = moment(values.date[1]).format("YYYY-MM-DD")
            props.handleFilter({startDate: values.startDate, endDate: values.endDate})
        } else if (values.date === undefined) {
            props.handleFilter({status: values.status})
        } else {
            values["startDate"] = moment(values.date[0]).format("YYYY-MM-DD")
            values["endDate"] = moment(values.date[1]).format("YYYY-MM-DD")
            props.handleFilter({startDate: values.startDate, endDate: values.endDate, status: values.status})
        }
        toggle()
        form.resetFields();
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>} 
                onClick={toggle}
            />
            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row>
                        <Col span={24}>
                            <Row style={{marginBottom: "10px"}}>
                                <Col span={24}>
                                    <Form.Item
                                        name="status"
                                        label="Status Transaksi"
                                    >
                                        <Select placeholder="Pilih status transaksi" style={{width: "100%"}}>
                                        <Select.Option value="UNPAID">UNPAID</Select.Option>
                                            <Select.Option value="APPROVED">APPROVED</Select.Option>
                                            <Select.Option value="PAID">PAID</Select.Option>
                                            {/* <Select.Option value="READY">READY</Select.Option> */}
                                            {/* <Select.Option value="DELIVERY">DELIVERY</Select.Option> */}
                                            <Select.Option value="PROCESS">PROCESS</Select.Option>
                                            <Select.Option value="CANCELED">CANCELED</Select.Option>
                                            <Select.Option value="DONE">DONE</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="date"
                                        label="Tanggal Transaksi"
                                    >
                                        <RangePicker 
                                            style={{width: "100%"}} 
                                            placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "20px", textAlign: "center"}}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterTransactionJasa
