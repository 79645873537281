import React, {useEffect, useState} from 'react'
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification, Pagination} from "antd"
import {FormatDate} from "../../../../helpers/Formatter"
import {PageLayout} from "../../../templates"
import FilterReviewJasa from "./filterreviewjasa/FilterReviewJasa"
import {Search, Table, Loading, FeatureButton} from "../../../../components/atoms"

import "./reviewjasa.scss"

const ReviewJasa: React.FC = () => {
    const [fields, setFields] = useState<{
        currentPage:number,
        limit:number,
        data:{
            createdAt:string,
            comment:string,
            rate:number,
            order:{
                invoiceNumber:string,
                userName:string
            }
        }[],
        totalPage:number
    }>({
        currentPage: 1,
        limit: 10,
        data:[],
        totalPage: 1
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [params, setParams] = useState<{
        merchantID:string|null,
        page:number,
        q:string
    }>({
        merchantID: localStorage.getItem("merchantID"),
        page: 1,
        q: ""
    })

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/merchant/review`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data voucher',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, q: e.target.value})
         }
    }

    const handleFilter = (values:{}) => {
        setParams({...params, ...values})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantID"),
            page: 1,
            q: ""
        })
    }

    const columns = [
        {
            title: "No Order",
            render: (data:{order:{invoiceNumber:string}}) => (
                <div>{data.order.invoiceNumber}</div>
            ),
            width: "23%"
        },
        {
            title: "Review",
            dataIndex: "comment",
            width: "23%"
        },
        {
            title: "Rating",
            render: (data: {rate: number}) => {
                const renderStar = () => {
                    const stars = []
                    for (var i = 0; i < data.rate; i++) {
                        stars.push(<i key={i} className="fas fa-star" style={{color: "green"}} />)
                    }
                    
                    return stars
                }
                return (
                    <div>
                        {renderStar()}
                    </div>
                )
            },
            width: "15%"
        },
        {
            title: "Customer",
            render: (data:{order:{userName:string}}) => (
                <div>{data.order.userName}</div>
            )
        },
        {
            title: "Tanggal Review",
            render: (data: {createdAt: string}) => (
                <div>{FormatDate(data.createdAt)}</div>
            )
        },
    ];

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search onKeyPress={(e:any) => handleSubmitSearch(e)} />}
                leftTop={
                    <div>
                        <FilterReviewJasa handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        {loading ? <Loading /> : (
                            <div>
                                <Table 
                                    columns={columns} 
                                    data={fields.data} 
                                    rowKey="id"
                                    pagination={false}
                                    // scroll={{y: 550}}
                                />
                                <div style={{padding: "20px 0px", textAlign: "right"}}>
                                    <Pagination 
                                        current={fields.currentPage} 
                                        pageSize={10} 
                                        total={fields.totalPage * fields.limit} 
                                        onChange={handlePageChange} 
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default ReviewJasa
