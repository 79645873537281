import React, { useState, useEffect } from 'react'
import { Button, notification } from "antd"
import { Modal } from "../../../../../../../components/atoms"
import FormItem from "../form/Form"
import { base_url } from "../../../../../../../config/url-config"
import { orderItemJasa } from "../../../../../../../config/edpoint"
import axios from "axios"

interface Props {
    items: any
    fetchData: (id: string) => void
    bookingId: string
}

const Create: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        orderID: string,
        items: {
            price: number | null
            name: string
            qty: number | null
        }[]
    }>({
        orderID: "",
        items: [
            {
                price: null,
                name: "",
                qty: null
            }
        ]
    })
    const [values, setValues] = useState({
        price: null,
        name: "",
        qty: null
    })
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }

    const toggle = () => {
        setModal(!modal)
    }

    useEffect(() => {
        setFields(props.items)
        setValues({
            price: null,
            name: "",
            qty: null
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.items])

    const handleSubmit = async (values: any) => {
        // console.log(values);
        values["note"] = ""
        values["price"] = parseInt(values.price)
        values["qty"] = parseInt(values.qty)
        // console.log(values);

        fields["items"] = [...fields.items, values]

        try {
            const url = `${base_url}/${orderItemJasa}/byMerchant/update`

            await axios.put(url, fields, {
                headers: headers
            })
            // toggle()
            notification.success({
                message: "Yuhuuu",
                description:
                    'Memperbaharui data order berhasil!',
            });
            props.fetchData(props.bookingId)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan saat memperbaharui data order',
            });
        }
    }

    return (
        <React.Fragment>
            <Button
                style={{ width: "100%", height: "40px", borderRadius: "7px", backgroundColor: "green", color: "white" }}
                onClick={toggle}
            >
                Tambah
            </Button>

            <Modal
                title="Tambah Item Tambahan"
                visible={modal}
                onCancel={toggle}
            >
                <FormItem
                    toggle={toggle}
                    handleSubmit={handleSubmit}
                    values={values}
                />
            </Modal>
        </React.Fragment>
    )
}

export default Create
