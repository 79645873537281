import React, {useContext} from 'react'
import {useLocation} from "react-router-dom"
import {ContextStore} from "../../../context/context-store"
import {Row, Col, Dropdown, Menu} from "antd"
import ChangePassword from "../changepassword/ChangePassword"
import {Link} from "react-router-dom"
import ProfileLogo from "../../../assets/profil.png"
// import {UserOutlined} from "@ant-design/icons"

import "./useraccount.scss"

const UserAccount: React.FC = () => {
    const location = useLocation()
    const {setLoginStatus} = useContext(ContextStore)

    const clearLocalStorage = () => {
        localStorage.clear();
        setLoginStatus(false)
    }

    const menu = (
        <Menu style={{width: "150px"}}>
            <Menu.Item>
                <ChangePassword />
            </Menu.Item>
            <Menu.Item hidden={location.pathname === "/merchant" ? true : false}>
                <Link to="/merchant">
                    <Row>
                        <Col span={6}>
                            <i className="fas fa-arrow-alt-circle-left" /> 
                        </Col>
                        <Col span={18}>
                            Kembali
                        </Col>
                    </Row>
                </Link>        
            </Menu.Item>
            <Menu.Item>
                <Link to="/login" onClick={clearLocalStorage}>
                    <Row>
                        <Col span={6}>
                            <i className="fas fa-sign-out-alt" />
                        </Col>
                        <Col span={18}>
                            Keluar
                        </Col>
                    </Row>
                </Link>        
          </Menu.Item>
        </Menu>
    );
    
    return (
        <Row className="user-account-container" align="middle">
            <Col span={24} className="user-container">
                <Row className="account-section">
                    <Col span={23} style={{display: "flex"}}>
                        <div style={{paddingTop: "3px", marginRight: "7px"}}>
                            <img src={ProfileLogo} alt="profile-logo"  style={{width: "36px", height: "36px"}} />
                        </div>
                        <div>
                            <div className="user-name">{localStorage.getItem("userName")}</div>
                            <div className="user-role">{localStorage.getItem("role")}</div>
                        </div>
                    </Col>
                    <Col span={1} className="menu-section">
                        <Dropdown overlay={menu} placement="bottomLeft">
                            <i className="fas fa-ellipsis-v" style={{cursor: "pointer"}} />
                        </Dropdown>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default UserAccount
