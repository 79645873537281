import React from 'react'

import "./pagetitle.scss"

interface Props {
    pageTitle: string
}

const PageTitle: React.FC<Props> = (props) => {
    return (
        <div className="page-title">{props.pageTitle}</div>
    )
}

export default PageTitle
