import {
    Beranda,
    Akun,
    Item,
    Service,
    DetailService,
    CreateService,
    EditService,
    Transaction,
    DetailTransaction,
    Voucher,
    Topup,
    DetailTopup,
    Settlement,
    DetailSettlement,
    Review,
    ScheduleService
} from "../containers/pages";

const routes = [
    { name: "Beranda", path: "/beranda", component: Beranda },
    { name: "Akun", path: "/akun", component: Akun },
    { name: "Item", path: "/item", component: Item },
    { name: "Service", path: "/service", component: Service },
    { name: "DetailService", path: "/service/detail", component: DetailService },
    { name: "CreateService", path: "/service/tambah", component: CreateService },
    { name: "EditService", path: "/service/edit", component: EditService },
    { name: "Transaction", path: "/transaksi", component: Transaction },
    { name: "Detail Transaction", path: "/transaksi/detail", component: DetailTransaction },
    { name: "Voucher", path: "/voucher", component: Voucher },
    { name: "Topup", path: "/topup", component: Topup },
    { name: "DetailTopup", path: "/topup/detail", component: DetailTopup },
    { name: "Settlement", path: "/settlement", component: Settlement },
    { name: "DetailSettlement", path: "/settlement/detail", component: DetailSettlement },
    { name: "Review", path: "/review", component: Review },
    { name: "Jadwal", path: "/service/jadwal", component: ScheduleService },
];

export default routes;
