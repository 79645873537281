import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {FeatureButton, Modal, Loading} from "../../../../../components/atoms"
import {notification} from "antd"
import CreateCategory from "./createcategory/CreateCategory"
import CategoryBox from "./CategoryBox"

import "./category.scss"

const Category: React.FC = () => {
    const [modal, setModal] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        name:string,
        id:string
    }[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async() => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/merchant/item/category`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            // console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            // console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    const handleEdit = async(id:string, value:string) => {
        try {
            const res = await axios.put(`${base_url}/v3/merchant/item/category/${id}`, {name: value}, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            if (res.status === 200) {
                fetchData()
            }
            // console.log(res);
            
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat menghapus data kategori.',
            });
        }
    }

    const handleDelete = async(id:string) => {
        try {
            const res = await axios.delete(`${base_url}/v3/merchant/item/category/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: {entityID: localStorage.getItem("merchantId")}
            })
            console.log(res);
            if (res.status === 200) {
                fetchData()
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat meghapus data kategori.',
            });
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    const toggle = () => {
        setModal(!modal)
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-clipboard-list" /> Kategori</div>} 
                onClick={toggle} 
            />

            <Modal
                title="Kategori"
                onCancel={toggle}
                visible={modal}
            >
                {/* <Button className="btn-create-category">Tambah Kategori</Button> */}
                <CreateCategory fetchData={fetchData} />
                {loading ? <Loading /> : (
                    <div>
                        {fields.map((field, key) => (
                            <CategoryBox 
                                key={key}
                                name={field.name}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
            </Modal>
        </React.Fragment>
    )
}

export default Category
