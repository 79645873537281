import moment from "moment"

export const FormatRp = (number: number) => {
    if (number !== undefined && number !== null) {
        return number.toLocaleString('id-DE', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 2
        });
    }
    return `-`
};

export const FormatDate = (date: string) => {
    return moment(date).format("DD MMMM YYYY, HH:mm:ss");
};