import React, {useState} from 'react'
import {Button, Row, Col, Input} from "antd"
import {Modal} from "../../../../../components/atoms"
import FormNewBankAccount from "./FormNewBankAccount"

interface Props {
    data?: null|undefined|{bankName:string, accountName:string, accountNumber:string}
    name?: string|undefined
    id?: string|undefined
}

const ModalBankAccountHobby: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    console.log(props.data);
    console.log(props.name);
    console.log(props.id);

    return (
        <React.Fragment>
            <Button className="button-modal-edit-hobby" onClick={toggle}>
                <i className="far fa-eye" style={{fontSize: "16px"}} />
            </Button>
            <Modal 
                title="Informasi Akun Bank"
                visible={modal}
                onCancel={toggle}
            >
                {props.data === null ? (
                    <div style={{textAlign: "center"}}>
                        <i className="far fa-folder-open fa-4x" style={{color: "red"}} />
                        <div style={{paddingTop: "10px", color: "rgb(179, 179, 179)"}}>Maaf, informasi akun bank anda masih kosong. Silahkan menghubungi pihak kami untuk menambahkan informasi bank anda.</div>
                    </div>
                ) : (
                    <div>
                        <Row style={{paddingBottom: "15px"}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        Nama Bank
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama bank"
                                            value={props.data !== undefined ? props.data.bankName : ""} 
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: "15px"}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        Nama Akun
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama akun"
                                            value={props.data !== undefined ? props.data.accountName : ""} 
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row style={{paddingBottom: "15px"}}>
                            <Col span={24}>
                                <Row>
                                    <Col span={24}>
                                        Nomor Akun
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={24}>
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nomor akun"
                                            value={props.data !== undefined ? props.data.accountNumber : ""} 
                                            disabled
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                )}
                <Row style={{paddingTop: "15px"}}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Button className="back-button-modal-bank" onClick={toggle}>Kembali</Button>{" "}
                        <FormNewBankAccount
                            id={props.id}
                            name={props.name}
                        />
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default ModalBankAccountHobby
