const staging = 'https://staging-api.manyoption.co.id'
const production = 'https://api.manyoption.co.id'

export const base_url = process.env.REACT_APP_ENV === "production" ? production : staging
// export const base_url = staging

// console.log(process.env);

// console.log(process.env.REACT_APP_ENV)
// console.log(base_url);
