import React from 'react'
import {Row, Col, Spin} from "antd"

import "./loading.scss"

const Loading: React.FC = () => {
    return (
        <Row justify="center" align="middle" className="loading-container">
            <Col xs={14} sm={8} lg={12} xl={12} style={{textAlign: "center"}}>
                <Spin size="large" />
            </Col>
        </Row>
    )
}

export default Loading
