import React from 'react'
import EditMerchantHobby from "./editmerchanthobby/EditMerchantHobby"
import EditMerchantJasa from "./editmerchantjasa/EditMerchantJasa"
import EditMerchantFood from "./editmerchantfood/EditMerchantFood"

import "./editmerchant.scss"

const EditMerchant: React.FC = () => {
    return (
        <div className="edit-merchant-page">
            {localStorage.getItem("merchantType") === "hobi" ? <EditMerchantHobby /> : localStorage.getItem("merchantType") === "food" ? <EditMerchantFood /> : <EditMerchantJasa /> }
        </div>
    )
}

export default EditMerchant
