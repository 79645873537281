import React, {useState} from 'react'
import moment from "moment"
import {FeatureButton, Modal, BackButton, OkButton} from "../../../../../components/atoms"
import {Form, Row, Col, DatePicker, Select} from "antd"

interface Props {
    handleFilter: (values:any) => void
}

const FilterTransactionFood: React.FC<Props> = (props) => {
    const {RangePicker} = DatePicker
    const [form] = Form.useForm()
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values: any) => {
        for (var key in values) {
            if (values[key] === undefined) {
                delete values[key]
            } else {
                if (key === "createdAt") {
                    values["createdFrom"] = moment(values.createdAt[0]).format("YYYY-MM-DD");
                    values["createdTo"] = moment(values.createdAt[1]).format("YYYY-MM-DD");
                    delete values.createdAt
                    // props.handleFilter(values)
                }
            }
        }
        props.handleFilter(values)
        toggle()
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-filter" /> Filter</div>}
                // onClick={handleShowModal}
                onClick={toggle}
            />

            <Modal
                title="Filter Transaksi"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                >
                    <Row style={{marginBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                name="createdAt"
                                label="Tanggal Transaksi"
                            >
                                <RangePicker
                                    style={{ width: "100%" }}
                                    format="DD-MM-YYYY"
                                    placeholder={["Tanggal Awal", "Tanggal Akhir"]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col span={24}>
                            <Form.Item
                                name="status"
                                label="Status Transaksi"
                            >
                                <Select placeholder="Pilih status transaksi" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="APPROVED">APPROVED</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="SEND">SEND</Select.Option>
                                    <Select.Option value="DONE">DONE</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="REJECTED">REJECTED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={24}>
                            <Form.Item
                                name="paymentState"
                                label="Status Pembayaran"
                            >
                                <Select placeholder="Pilih status pembayaran" style={{ width: "100%" }}>
                                    <Select.Option value="CREATED">CREATED</Select.Option>
                                    <Select.Option value="UNPAID">UNPAID</Select.Option>
                                    <Select.Option value="PAID">PAID</Select.Option>
                                    <Select.Option value="PROCESS">PROCESS</Select.Option>
                                    <Select.Option value="CANCELED">CANCELED</Select.Option>
                                    <Select.Option value="EXPIRED">EXPIRED</Select.Option>
                                    <Select.Option value="REFUND">REFUND</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: "10px" }}>
                        <Col span={24}>
                            <Form.Item
                                name="paymentMethod"
                                label="Metode Pembayaran"
                            >
                                <Select placeholder="Pilih metode pembayaran" style={{ width: "100%" }}>
                                    <Select.Option value="ewallet">ewallet</Select.Option>
                                    <Select.Option value="va">va</Select.Option>
                                    <Select.Option value="cash">cash</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "20px", textAlign: "center" }}>
                        <Col span={24}>
                            <Form.Item>
                                <BackButton
                                    onClick={toggle}
                                >
                                    Kembali
                                </BackButton>{" "}
                                <OkButton>Simpan</OkButton>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FilterTransactionFood
