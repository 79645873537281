import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itemfood} from "../../../../../config/edpoint"
import {PageLayout} from "../../../../templates"
import FilterItem from "../filter/Filter"
import Category from "../category/Category"
import TableColumns from "./Columns"
import {Link} from "react-router-dom"
import {Search, Table, FeatureButton} from "../../../../../components/atoms"
import {Pagination, notification} from "antd"

const List: React.FC = () => {
    const [loading, setLoading] = useState<string>("")
    const [fields, setFields] = useState<{
        pagination: {
            page: number;
            limit: number;
            totalPage: number;
            totalData: number;
        }
        payload: {}[]
    }>({
        payload: [],
        pagination: {
            page: 0,
            limit: 0,
            totalData: 0,
            totalPage: 0
        }
    })
    const [params, setParams] = useState<{}>({
        merchantId: localStorage.getItem("merchantId"),
        page: 0,
        limit: 10
    })
    const [visible, setVisible] = useState<boolean>(false)
    const [hideMenu, setHideMenu] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading("fetch")
        try {
            const url = `${base_url}/${itemfood}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.get(url, {
                headers: headers,
                params: queryparams
            })
            setFields(res.data)
            setLoading("")
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data item pasar',
                placement:"topRight"
            });
            setLoading("")
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            setParams({ ...params, keyword: e.target.value })
        }
    }

    const handleFilter = (values: {}) => {
        setParams({ ...params, ...values })
    }

    const handleReset = () => {
        setParams({
            merchantId: localStorage.getItem("merchantId"),
            page: 0,
            limit: 10
        })
    }

    const handleHideMenu = () => {
        setHideMenu(!hideMenu)
    }

    const handlePageChange = (page: number | undefined, pageSize: number | undefined) => {
        let currentPage = Number(page) - 1;
        setParams({ ...params, page: currentPage })
    }

    const handleUpdateStatus = async(id:string, status:boolean) => {
        setLoading(`switch${id}`)
        try {
            const url = `${base_url}/${itemfood}/updatestatus`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            await axios.put(url, {id: id, status: status}, {
                headers: headers
            })
            setLoading("")
            notification["success"]({
                message: 'Yeay!',
                description:
                  `Berhasil memperbaharui data`,
            });
            fetchData(params)
        } catch (error) {
            setLoading("")
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengupdate status item',
                placement:"topRight"
            });
            fetchData(params)
        }
    }

    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async (id: string) => {
        setLoading("delete")
        try {
            const url = `${base_url}/${itemfood}/delete/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.delete(url, {
                headers: headers
            })
            // console.log(res);
            if (res.data.statusCode === 200) {
                toggle()
                fetchData(params)
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data service berhasil!',
                    placement: "bottomRight",
                });
                setLoading("")
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            setLoading("")
        }
    }

    const {columns} = TableColumns(
        loading,
        visible,
        toggle,
        handleDelete,
        handleUpdateStatus
    )

    return (
        <PageLayout 
            leftTop={
                <div>
                    <Link to="/item/tambah">
                        <FeatureButton label={<div><i className="fas fa-plus-square" /> Tambah</div>} /> 
                    </Link>
                    <FilterItem handleFilter={handleFilter} />
                    <FeatureButton 
                        label={<div><i className="fas fa-undo" /> Reset</div>} 
                        onClick={handleReset}
                    />
                    {hideMenu ? (
                        <span>
                            <Category path="/v3/food/product_category" isItem={true} />
                            <i 
                                className="fas fa-chevron-circle-left"
                                style={{cursor: "pointer", color: "#009b00"}}
                                onClick={handleHideMenu} 
                            />
                        </span>
                    ) : (
                        <i 
                            className="fas fa-chevron-circle-right" 
                            style={{cursor: "pointer", color: "#009b00"}}
                            onClick={handleHideMenu}
                        />
                    )}
                </div>
            }
            rightTop={<Search 
                onKeyPress={(e:any) => handleSubmitSearch(e)} 
            />}
            content={
                <div>
                    <Table 
                        columns={columns}
                        data={fields.payload}
                        rowKey="id"
                        pagination={false}
                        // scroll={{y: 550}}
                        loading={loading === "fetch" ? true : false}
                    />
                    <div style={{padding: "20px 0px", textAlign: "right"}}>
                        <Pagination 
                            current={fields.pagination.page + 1} 
                            pageSize={fields.pagination.limit} 
                            total={fields.pagination.totalData} 
                            onChange={handlePageChange} 
                        />
                    </div>
                </div>
            }
        />
    )
}

export default List
