import React, {useEffect, useState} from 'react'
// import {MerchantStore} from "../../../../../context/context-store"
import {base_url} from "../../../../../config/url-config"
import axios from "axios"
import {Row, Col, Pagination, notification} from "antd"
// import {data} from "./MerchantModel"
import {MerchantBox} from "../../../../../components/molecules"

const ListMerchantHobby: React.FC = () => {
    // const {setMerchantId} = useContext(MerchantStore)
    const [fields, setFields] = useState<{page:number, limit:number, records:[], total_record:number}>({
        page: 1, 
        limit: 10,
        records: [],
        total_record: 0
    })
    const [params, setParams] = useState<{}>({
        pageLimit: 10,
        pageNumber: 1,
        id: "me"
    })

    const fetchData = async (queryparams:{}) => {
        try {
            const res = await axios.get(`${base_url}/v3/hobby/merchant`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: queryparams
            })
            // console.log(res);

            if (res.status === 200) {
                setFields(res.data.payload)
            }
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data merchant',
                placement:"topRight"
            });
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number|undefined, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleClick = (id:string, accountID:string) => {
        localStorage.setItem("merchantID", id)
        localStorage.setItem("accountID", accountID)
    }
    
    return (
        <div>
            <Row gutter={[0, 32]} style={{paddingTop: "10px"}}>
                {fields.records.map((field:{Image: string, Name: string, ID: string, MerchantAccountID: string}, key:number) => (
                    <Col key={key} span={4} offset={key !== 0 && key !== 5 ? 1 : 0}>
                        <MerchantBox 
                            logo={field.Image} 
                            name={field.Name} 
                            link="/"
                            editLink={`/merchant/edit?id=${field.ID}`}
                            onClick={() => handleClick(field.ID, field.MerchantAccountID)} 
                        />
                    </Col>
                ))}
                {/* <Col span={4} offset={fields.length % 5 !== 0 ? 1 : 0}>
                    <div className="box-create-merchant-container">
                        <div style={{textAlign: "center", paddingTop: "45px"}}>
                            <i className="fas fa-store fa-4x"  />
                        </div>
                        <div style={{textAlign: "center", fontSize: "18px", paddingTop: "20px"}}>
                            <div>Tambah</div>
                            <div>Merchant</div>
                        </div>
                    </div>
                </Col> */}
            </Row>
            {fields.records.length > 10 ? (
                <div className="pagination-container">
                    <Pagination current={fields.page} pageSize={10} total={fields.total_record} onChange={handlePageChange} />
                </div>
            ) : null }
        </div>
    )
}

export default ListMerchantHobby
