import React from 'react'
import ListMerchantHobby from "./listmerchanthobby/ListMerchantHobby"
import ListMerchantJasa from "./listmerchantjasa/ListMerchantJasa"
import ListMerchantFood from "./listmerchantfood/ListMerchantFood"

import "./listmerchant.scss"

const ListMerchant: React.FC = () => {
    return (
        <div>
            {localStorage.getItem("merchantType") === "hobi" ? <ListMerchantHobby /> : localStorage.getItem("merchantType") === "food" ? <ListMerchantFood /> : <ListMerchantJasa />}
        </div>
    )
}

export default ListMerchant
