import React from 'react'
import {Form, Input, Select, Button} from "antd"

interface Props {
    onFinish?: (values: any) => any
    toggle?: () => any
}

const AccountForm: React.FC<Props> = (props) => {
    return (
        <div>
            <Form 
                layout="vertical" 
                requiredMark={false}
                onFinish={props.onFinish}
                preserve={false}
            >
                <Form.Item 
                label="Nama" 
                name="name"
                rules={[
                    { required: true, message: "Silahkan input nama!" },
                ]} 
                >
                    <Input name="name" placeholder="Input Nama" />
                </Form.Item>
                <Form.Item
                label="No Handphone" 
                name="phone"
                rules={[
                    { required: true, message: "Silahkan input nomer handphone!" },
                ]} 
                >
                    <Input name="phone" placeholder="Input Nomor Handphone" />
                </Form.Item>
                <Form.Item 
                label="Email" 
                name="email"
                rules={[
                    { required: true, message: "Silahkan input nomer email!" },
                ]} 
                >
                    <Input name="email" placeholder="Input Email" />
                </Form.Item>
                <Form.Item 
                label="Password" 
                name="password"
                rules={[
                    { required: true, message: "Silahkan input nomer password!" },
                ]} 
                >
                    <Input name="password" placeholder="Input Password" type="password" />
                </Form.Item>
                <Form.Item 
                label="Role" 
                name="role"
                rules={[
                    { required: true, message: "Silahkan input nomer password!" },
                ]} 
                >
                    <Select placeholder="Pilih Role">
                        <Select.Option value="owner">Owner</Select.Option>
                        <Select.Option value="manager">Manager</Select.Option>
                        <Select.Option value="cashier">Cashier</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ marginBottom: "0px" }}>
                    <div style={{ textAlign: "center" }}>
                        <Button key="back" className="cancel-button" onClick={props.toggle}>
                            Batal
                        </Button>{" "}
                        <Button
                            key="submit"
                            type="primary"
                            className="ok-button"
                            htmlType="submit"
                            // loading={loading}
                        >
                            Oke
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </div>
    )
}

export default AccountForm
