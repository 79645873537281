import React, { useState, useEffect } from "react";
import axios from "axios"
import { base_url } from "./config/url-config"
import { Switch, Route, HashRouter, Redirect } from "react-router-dom";
import { ContextStore } from "./context/context-store"
import Login from "./containers/pages/login/Login";
import Merchant from "./containers/pages/merchant/Merchant"
import { MainLayout } from "./containers/templates";

import "./App.scss"
import "./style/custom-antd.scss"

function PrivateRoute({ component: Component, props }: any) {
  return (
    <Route
      {...props}
      render={props =>
        localStorage.getItem("refreshToken") !== null ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
      }
    />
  )
}

const App: React.FC = () => {
  const [login, setLogin] = useState<{ isLogin: boolean }>({
    isLogin: false
  })
  const [contextData, setContextData] = useState<{ refreshToken: string | null, merchantID: string | null }>({
    refreshToken: null,
    merchantID: null
  })

  const setLoginStatus = (status: boolean) => {
    setLogin({ ...login, isLogin: status })
  }

  const setRefreshToken = (refreshToken: string) => {
    setContextData({ ...contextData, refreshToken: refreshToken })
  }

  const setMerchantID = (merchantID: string) => {
    setContextData({ ...contextData, merchantID: merchantID })
  }

  const data = {
    login,
    setLoginStatus,
    contextData,
    setRefreshToken,
    setMerchantID
  }

  const fetchData = async () => {
    try {
      const data = new FormData()
      data.append("refreshToken", `${localStorage.getItem("refreshToken")}`)
      const res = await axios.post(`${base_url}/v3/client/auth/refresh`, data, { headers: { 'Content-Type': 'multipart/form-data' } })

      // if (res.status === 200) {
      localStorage.setItem("accessToken", res.data.payload.token.accessToken)
      localStorage.setItem("refreshToken", res.data.payload.token.refreshToken)
      // }
    } catch (error) {
      // if (error.response.status !== 200) {
      localStorage.clear();
      // }
    }
  }

  useEffect(() => {
    if (localStorage.getItem("refreshToken") !== null) {
      fetchData()
      // setContextData({...contextData, refreshToken: localStorage.getItem("refreshToken")})
    }
  }, [])

  return (
    <React.Fragment>
      <HashRouter>
        <ContextStore.Provider value={data}>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute path="/merchant" component={Merchant} />
            <PrivateRoute path="/" component={MainLayout} />
          </Switch>
        </ContextStore.Provider>
        {/* {localStorage.getItem("refreshToken") === null ? <Redirect to="/login" /> : <Redirect to="/merchant" />} */}
      </HashRouter>
    </React.Fragment>
  );
};

export default App;
