import React, {useState, useEffect} from 'react'
import {Row, Col, Checkbox} from "antd"
import TimeRates from "./TimeRates"

interface Props {
    days: string[],
    operation: {day:number, active?:boolean, timeRate:{}[]},
    handleCheckedDay: (index:number, active:boolean) => void,
    handleSubmitTimeRate:(values:{
        deposit:any, 
        discount:any, 
        timeStart:string,
        timeEnd:string,
        isDiscPercentage:any,
        rate:any,
        hour:any
    }, indextime:number, indexday:number) => void
}

const Operations:React.FC<Props> = (props) => {
    const [timeRates, setTimeRates] = useState<number[]>([0])
    const [formActive, setFormActive] = useState<boolean>(false)
    const [operations, setOperations] = useState<{day:number, timeRate:{}[]}>({
        day: 0,
        timeRate: []
    })

    const addRateAmounts = (i:number) => {
        const rateAmounts:number[] = []
        for (let x = 0;x <= i; x++ ) {
            rateAmounts.push(x)
        }
        if (rateAmounts.length < 5) {
            setTimeRates(rateAmounts)
        }
    }

    useEffect(() => {
        const rateAmounts:number[] = []
        for (let x = 0;x <= props.operation.timeRate.length - 1; x++ ) {
            rateAmounts.push(x)
        }
        if (rateAmounts.length < 5) {
            setTimeRates(rateAmounts)
            props.operation.active ? setFormActive(true) :setFormActive(false)
        }
    }, [props.operation.active, props.operation.timeRate])

    const handleCheck = (e:any) => {
        // index day
        // console.log(e.target.checked);
        // console.log(e.target.name);
        setFormActive(e.target.checked)
        setOperations({...operations, day: parseInt(e.target.name)})
        props.handleCheckedDay(props.operation.day, e.target.checked)
    }
    
    return (
        <div>
            <Row className="table-body-section">
                <Col span={24}>
                    <Row className="table-body-container">
                        <Col span={2}>
                            <Checkbox 
                                name={`${props.operation.day}`} 
                                defaultChecked={props.operation.active}
                                onChange={handleCheck}
                                className={props.operation.active ? "checkbox-day" : ""}
                            >
                                {props.days[props.operation.day]}
                            </Checkbox>
                        </Col>
                        <Col span={22}>
                            {timeRates.map((timeRate, key) => (
                                <TimeRates 
                                    key={key}
                                    indexTimeRate={timeRate}
                                    timeRate={props.operation.timeRate[timeRate] === undefined ? {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""} : props.operation.timeRate[timeRate]}  
                                    day={props.operation.day}
                                    formActive={formActive}
                                    addRateAmounts={addRateAmounts}
                                    timeRates={timeRates}
                                    handleSubmitTimeRate={props.handleSubmitTimeRate}
                                />
                            ))}
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default Operations