import React, { useState, useEffect } from 'react'
import {base_url} from "../../../../../config/url-config"
import {itemfoodcategory} from "../../../../../config/edpoint"
import axios from "axios"
import { FeatureButton, Modal, Loading } from "../../../../../components/atoms"
import CreateCategory from "./createcategory/CreateCategory"
import CategoryBox from "./CategoryBox"
import {Pagination, notification} from "antd"

// import "./category.scss"

interface IProps {
    path: string,
    isItem?: boolean
}

const Category = (props: IProps) => {
    const [categories, setCategories] = useState<{
        pagination: {
            page: number
            totalData: number
        }
        payload: {
            id: string,
            merchant_id: string,
            name: string
        }[]
    }>({
        pagination: {
            page: 0,
            totalData: 0
        },
        payload: []
    })
    const [params, setParams] = useState<{}>({
        merchantId: localStorage.getItem("merchantID"),
        page: 0
    })
    const [loading, setLoading] = useState<boolean>(false)
    const [modal, setModal] = useState<boolean>(false)

    const fetchData = async(queryparams:{}) => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemfoodcategory}`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }

            const res = await axios.get(url, { 
                headers: headers,
                params: queryparams
            })
            if (res.data.statusCode === 200) {
                setCategories(res.data)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });
            }
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data kategori.',
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const toggle = () => {
        setModal(!modal)
    }

    const handleEdit = async (id: string, value: string) => {
        try {
            const data = props.isItem ? { name: value, merchant_id: localStorage.getItem("merchantID") } : { name: value };
            const url = `${base_url}/${itemfoodcategory}/update/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.put(url, data, {
                headers: headers
            })
            if (res.data.statusCode === 200) {
                fetchData(params)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });
            }
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat menghapus data kategori.',
            });
        }
    }

    const handleDelete = async (id: string) => {
        try {
            const url = `${base_url}/${itemfoodcategory}/delete/${id}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

            const res = await axios.delete(url, {
                headers: headers
            })
            if (res.data.statusCode === 200) {
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data kategori berhasil!',
                    placement: "bottomRight",
                });
                fetchData(params)
            } else {
                notification.error({
                    message: 'Ups!',
                    description: res.data.message,
                });
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat meghapus data kategori.',
            });
        }
    }

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, page: page - 1})
    }

    return (
        <React.Fragment>
            <FeatureButton
                label={<div><i className="fas fa-clipboard-list" /> Kategori</div>} 
                onClick={toggle}
            />

            <Modal
                title="Kategori"
                onCancel={toggle}
                visible={modal}
            >
                {/* <Button className="btn-create-category">Tambah Kategori</Button> */}
                <CreateCategory 
                    fetchData={fetchData} 
                    path={props.path} 
                    isItem={props.isItem} 
                    params={params}
                />
                {loading ? <Loading /> : (
                    <div>
                        {categories.payload.map((field:{id:string, name:string}, key:number) => (
                            <CategoryBox
                                key={key}
                                name={field.name}
                                id={field.id}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                            />
                        ))}
                    </div>
                )}
                <div style={{paddingTop: "10px", paddingBottom: "20px", textAlign: "right"}}>
                    <Pagination 
                        current={categories.pagination.page + 1} 
                        pageSize={5} 
                        total={categories.pagination.totalData} 
                        onChange={handlePageChange} 
                    />
                </div>
            </Modal>
        </React.Fragment>
    )
}

export default Category
