import React from 'react'
import {Row, Col} from "antd"

import "./itemdetail.scss"

interface Props {
    label: string,
    value?: any
}

const ItemDetail: React.FC<Props> = (props) => {
    return (
        <Row className="item-detail-container">
            <Col span={12} className="label-container">
                {props.label}
            </Col>
            <Col span={12}>
                <div
                    className="value-container"
                >
                    {props.value}
                </div>
            </Col>
        </Row>
    )
}

export default ItemDetail
