import React, {useState, useEffect} from 'react'
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {notification, Row, Col, Pagination} from "antd"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {Search, FeatureButton, Loading, Table} from "../../../../../components/atoms"
import {PageLayout} from "../../../../templates"
import FilterTransactionHobby from "./filtertransactionhobby/FilterTransactionHobby"

import "./transactionhobby.scss"

const TransactionHobby: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        page:number,
        total_page:number,
        total_record:number,
        limit:number,
        records:{}[]
    }>({
        page: 1,
        total_page: 0,
        total_record: 0,
        limit: 10,
        records: []
    })
    const [params, setParams] = useState<{
        merchantID:string|null,
        pageNumber:number,
        search:string
    }>({
        merchantID: localStorage.getItem("merchantID"),
        pageNumber: 1,
        search: ""
    })

    const fetchData = async(params:{}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/hobby/booking`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: params
            })
            console.log(res);
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) {
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data transaksi',
                placement:"topRight"
            });
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const handlePageChange = (page:number, pageSize:number|undefined) => {
        setParams({...params, pageNumber: page})
    }

    const handleSubmitSearch = (e:any) => {
        if (e.which === 13) {
            setParams({...params, search: e.target.value})
         }
    }

    const handleFilter = (values:{}) => {
        console.log(values);
        setParams({...params, ...values})
    }

    const handleReset = () => {
        setParams({
            merchantID: localStorage.getItem("merchantID"),
            pageNumber: 1,
            search: ""
        })
    }

    const columns = [
        {
            title: "No. Order",
            render: (data:{booking:{invoiceNumber:string, CreatedAt:string}, merchant:{Name:string}}) => (
                <div>
                    <div style={{fontWeight: "bold", color: "black"}}>{data.booking.invoiceNumber}</div>
                    <div>{data.merchant.Name}</div>
                    <div>{FormatDate(data.booking.CreatedAt)}</div>
                </div>
            ),
            width: "25%"
        },
        {
            title: "Total Pesanan",
            render: (data:{booking:{totalPriceDetail:{totalPrice:number}}}) => (
                <div>{FormatRp(data.booking.totalPriceDetail.totalPrice)}</div>
            ),
            width: "17%"
        },
        {
            title: "Biaya Layanan",
            render: (data:{payment:{adminFee:number}}) => (
                <div>{data.payment !== null ? FormatRp(data.payment.adminFee) : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Provider Pembayaran",
            render: (data:{payment:{paymentProvider:string}}) => (
                <div>{data.payment !== null ? data.payment.paymentProvider : "-"}</div>
            ),
            width: "15%"
        },
        {
            title: "Status",
            render: (data:{payment:{paymentState:string}, booking:{bookingState:string}}) => (
                <div>
                    <Row>
                        <Col span={12}>
                            Order :
                        </Col>
                        <Col span={12}>
                            {data.booking.bookingState} 
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            Payment :
                        </Col>
                        <Col span={12}>
                            {data.payment !== null ? data.payment.paymentState : "-"}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            render: (data:{booking:{ID:string}}) => (
                <Link to={`/transaksi/detail?bookingID=${data.booking.ID}`} >
                    <div className="button-action" style={{color:  "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer"}}>
                        <i className="far fa-eye" />
                    </div>
                </Link>
            )
        }
    ]

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search 
                    onKeyPress={(e:any) => handleSubmitSearch(e)} 
                />}
                leftTop={
                    <div>
                        <FilterTransactionHobby handleFilter={handleFilter} />
                        <FeatureButton 
                            label={<div><i className="fas fa-undo" /> Reset</div>} 
                            onClick={handleReset}
                        />
                    </div>
                }
                content={
                    <div>
                        {loading ? <Loading /> : (
                            <div>
                                <Table 
                                    columns={columns}
                                    data={fields.records}
                                    // rowKey={(record:{booking:{ID:string}}) => record.booking.ID}
                                    // rowKey="key"
                                    pagination={false}
                                    rowClassName={(record:{booking:{bookingState:string}}, index:number) => record.booking.bookingState === "REQUESTED" || record.booking.bookingState === "RESCHEDULE-REQUESTED" ? 'new-transaction' :  'read-transaction'}
                                    // scroll={{y: 550}}
                                />
                                <div style={{padding: "20px 0px", textAlign: "right"}}>
                                    <Pagination 
                                        current={fields.page} 
                                        pageSize={10} 
                                        total={fields.total_record} 
                                        onChange={handlePageChange} 
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                }
            />
        </React.Fragment>
    )
}

export default TransactionHobby