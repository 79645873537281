import React, { useState, useEffect } from 'react'
import { Modal } from "../../../../../../../components/atoms"
import FormItem from "../form/Form"
import { base_url } from "../../../../../../../config/url-config"
import { orderItemJasa } from "../../../../../../../config/edpoint"
import axios from "axios"
import { notification } from "antd"

interface Props {
    item: any
    fields: any
    fetchData: (id: string) => void
    bookingId: string
}

const Edit: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)
    const [value, setValue] = useState({
        price: null,
        name: "",
        qty: null
    })
    const [fields, setFields] = useState<any>({})
    const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` }

    const toggle = () => {
        setModal(!modal)
    }

    useEffect(() => {
        setValue(props.item)
        setFields(props.fields)
    }, [props.item, props.fields])

    const handleSubmit = async (values: any) => {
        // console.log(values);
        values["note"] = ""
        values["price"] = parseInt(values.price)
        values["qty"] = parseInt(values.qty)
        console.log(values);
        fields["items"] = [...fields.items.filter((item: any) => item.name !== value.name), values]
        console.log(fields);
        try {
            const url = `${base_url}/${orderItemJasa}/byMerchant/update`

            await axios.put(url, fields, {
                headers: headers
            })
            // toggle()
            notification.success({
                message: "Yuhuuu",
                description:
                    'Memperbaharui data order berhasil!',
            });
            props.fetchData(props.bookingId)
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan saat memperbaharui data order',
            });
        }
    }

    return (
        <React.Fragment>
            <i
                className="far fa-edit"
                style={{ cursor: "pointer" }}
                onClick={toggle}
            />

            <Modal
                title="Edit Item Lainnya"
                visible={modal}
                onCancel={toggle}
            >
                <FormItem
                    toggle={toggle}
                    handleSubmit={handleSubmit}
                    values={value}
                />
            </Modal>
        </React.Fragment>
    )
}

export default Edit
