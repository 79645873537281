import React, {useState} from 'react'
import {FeatureButton, Modal} from "../../../../components/atoms"
import AccountForm from "../accountForm/AccountForm"

const CreateAccount: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmit = (values: any) => {
        console.log(values);
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-user-plus" /> Tambah</div>}
                onClick={toggle}
            />

            <Modal
                visible={visible}
                title="Tambah Akun"
                onCancel={toggle}
            >
                <AccountForm onFinish={handleSubmit} toggle={toggle} />
            </Modal>
        </React.Fragment>
    )
}

export default CreateAccount
