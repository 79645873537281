import React, {useState} from 'react'
import {Form ,Button, Row, Col, Input} from "antd"
import {Modal} from "../../../../../../components/atoms"

import "./formbankaccount.scss"

interface Props {
    data?: {bankName:string, accountName:string, accountNumber:string}|undefined
    name?: string|undefined
    id?: string|undefined
    submitBank:(values:{bankName: string, accountName: string, accountNumber: string}) => void
}

const FormBankAccount: React.FC<Props> = (props) => {
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSubmit = (values:{bankName: string, accountName: string, accountNumber: string}) => {
        props.submitBank(values)
        toggle()
    }

    return (
        <React.Fragment>
            <Button className="button-modal-edit-hobby" onClick={toggle}>
                <i className="far fa-eye" style={{fontSize: "16px"}} />
            </Button>
            <Modal 
                title="Form Akun Bank"
                visible={modal}
                onCancel={toggle}
            >
                <Form
                    onFinish={handleSubmit}
                >
                    <Row style={{paddingBottom: "15px"}}>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    Nama Bank
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="bankName"
                                        initialValue={props.data !== undefined ? props.data.bankName : ""}
                                        rules={[{ required: true, message: 'Nama bank wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama bank"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "15px"}}>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    Nama Akun
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="accountName"
                                        initialValue={props.data !== undefined ? props.data.accountName : ""}
                                        rules={[{ required: true, message: 'Nama akun wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nama akun"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingBottom: "15px"}}>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    Nomor Akun
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        name="accountNumber"
                                        initialValue={props.data !== undefined ? props.data.accountNumber : ""}
                                        rules={[{ required: true, message: 'Nomor akun wajib diisi!' }]}
                                    >
                                        <Input 
                                            className="form-input" 
                                            placeholder="Input nomor akun"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row style={{paddingTop: "15px"}}>
                        <Col span={24} style={{textAlign: "center"}}>
                            <Button 
                                className="back-button-modal-bank" 
                                onClick={toggle}
                            >
                                Kembali
                            </Button>{" "}
                            <Button
                                htmlType="submit"
                                className="save-button-modal-bank"
                            >
                                Simpan
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default FormBankAccount
