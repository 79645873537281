import React from 'react'
import {Switch, Route, Redirect} from "react-router-dom"
import routes from "../../../utils/routes"

import "./maincontent.scss"

const MainContent: React.FC = () => {
    return (
        <div className="maincontent-container">
            <Switch>
                {routes.map((route, key) => {
                    return <Route key={key} path={route.path} exact={route.path === "/item" ? false : true} component={route.component} />;
                })}
                <Redirect from="/" to={localStorage.getItem("merchantType") === "hobi" ? "/service" : "/item"} />
            </Switch>
        </div>
    )
}

export default MainContent
