import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router"
import {useLocation} from "react-router-dom"
import axios from "axios"
import {base_url} from "../../../../config/url-config"
import {notification} from "antd"
import moment from "moment"
import {Loading} from "../../../../components/atoms"
import FormService from "../formservice/FormService"

import "./editservice.scss"

const EditService: React.FC = () => {
    const history = useHistory()
    const id = parseInt(useLocation().search.split("=")[1])
    const [fields, setFields] = useState<{
        venue:{
            merchantID:string|null
            picURL:string[],
            name: string,
            address:string,
            cancelationFee:any,
            category:string|null,
            description:string,
            durationBfrReservation:number|null,
            isActive:boolean,
            isOpen:boolean,
            isCancelationPercentage:any,
            maxBooking:any,
            maxDuration:any,
            minDuration:any,
            phone:string
        }, 
        operation:{
            day:number,
            openHour:string,
            closeHour:string
            timeRate:{}[]
        }[]
    }>({
        operation:[],
        venue:{
            merchantID: localStorage.getItem("merchantID"),
            name: "",
            picURL: [""],
            address: "",
            cancelationFee: null,
            category: null,
            description: "",
            durationBfrReservation: null,
            isActive: false,
            isOpen: false, 
            isCancelationPercentage: null,
            maxBooking: null,
            maxDuration: null,
            minDuration: null,
            phone: ""
        }
    })
    const [operations, setOperations] = useState<{
        day:number,
        openHour:string,
        closeHour:string,
        active?:boolean, 
        timeRate:any,
        id?:number
    }[]>([
        {active:false, day: 0, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 1, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 2, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 3, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 4, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 5, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
        {active:false, day: 6, openHour:"00:00", closeHour:"00:00", timeRate: [
            {timeStart:"", timeEnd: "", deposit: null, rate: null, discount: null, isDiscPercentage: ""}
        ]},
    ])
    const [loading, setLoading] = useState<boolean>(false)

    

    useEffect(() => {
        const fetchData = async (venueId:number) => {
            setLoading(true)
            try {
                const res = await axios.get(`${base_url}/v3/hobby/venue/${venueId}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                    },
                })
                // console.log(res);
                if (res.status === 200) {
                    setFields(res.data.payload)
                    setLoading(false)
                    for (let i = 0; i < res.data.payload.operation.length; i++) {
                        operations[res.data.payload.operation[i].day].active = true
                        operations[res.data.payload.operation[i].day].openHour = res.data.payload.operation[i].openHour
                        operations[res.data.payload.operation[i].day].closeHour = res.data.payload.operation[i].closeHour
                        operations[res.data.payload.operation[i].day].timeRate = res.data.payload.operation[i].timeRate
                        operations[res.data.payload.operation[i].day].id =res.data.payload.operation[i].id
                    }
                }
            } catch {
                setLoading(true)
            }
        }

        fetchData(id)
    }, [id, operations])

    const handleSetImgURL = (url:string, index:number) => {
        fields.venue.picURL[index] = url
    }

    const addUploadPhoto = () => {
        setFields({...fields, venue: {...fields.venue, picURL: [...fields.venue.picURL, ""]}})
    }

    const handleCheckedDay = (index:number, active:boolean) => {
        // console.log(index);
        // console.log(active);
        const newOperations = [...operations]
        newOperations[index].active = active
        setOperations(newOperations)
    }

    const handleSubmitTimeRate = (
        values:{
            deposit:any, 
            discount:any, 
            timeStart:string,
            timeEnd:string,
            isDiscPercentage:any,
            rate:any,
            hour:any
        }, indextime:number, indexday:number) => {
            values["deposit"] = parseInt(values.deposit)
            values["discount"] = parseInt(values.discount)
            values["timeStart"] = moment(values.hour[0]).format("H:mm")
            values["timeEnd"] = moment(values.hour[1]).format("H:mm")
            values["isDiscPercentage"] = values.isDiscPercentage === "%" ? true : false
            values["rate"] = parseInt(values.rate)
            delete values.hour
            // console.log(indextime);
            // console.log(indexday);
            // console.log(values);
            operations[indexday].timeRate[indextime] = values
    
            // to set closeHour
            let hoursEnd = values.timeEnd.split(":")
            let minutesEnd = parseInt(hoursEnd[0]) * 60 + parseInt(hoursEnd[1])
            let hoursClose = operations[indexday].closeHour.split(":")
            let minutesClose = parseInt(hoursClose[0]) * 60 + parseInt(hoursClose[1])
            let newMinutesClose = Math.max(minutesEnd, minutesClose)
            let newCloseHour = `${Math.floor(newMinutesClose/60)}:${newMinutesClose%60 >= 10 ? newMinutesClose%60 : `0${newMinutesClose%60}`}`
            operations[indexday].closeHour = newCloseHour
    
            // to set openHour
            let hoursStart = values.timeStart.split(":")
            let minutesStart = parseInt(hoursStart[0]) * 60 + parseInt(hoursStart[1])
            let hoursOpen = operations[indexday].openHour.split(":")
            let minutesOpen = parseInt(hoursOpen[0]) * 60 + parseInt(hoursOpen[1])
            let newMinutesOpen = Math.min(minutesStart, minutesOpen)
            let newOpenHour = `${Math.floor(newMinutesOpen/60)}:${newMinutesOpen%60 >= 10 ? newMinutesOpen%60 : `0${newMinutesOpen%60}`}`
            operations[indexday].openHour = operations[indexday].openHour === "00:00" ? values.timeStart : newOpenHour
        }

    const handleSubmitSchedule = () => {
        console.log(operations);
        const newOperations = (operations.filter(operation => operation.active === true))
        newOperations.forEach(newOperation => delete newOperation.active)
        console.log(newOperations);
        setFields({...fields, operation: newOperations})
    }

    const handleSubmitVenue = async(values: {
        merchantID:string|null,
        picURL:string[],
        name: string, 
        address:string,
        cancelationFee:any,
        category:string|null, 
        description:string,
        durationBfrReservation:number|null,
        isActive:boolean,
        isOpen:boolean,
        isCancelationPercentage:any, 
        maxBooking:any, 
        maxDuration:any, 
        minDuration:any,
        phone:string
    }) => {
        values["cancelationFee"] = parseInt(values.cancelationFee)
        values["isCancelationPercentage"] = values.isCancelationPercentage === "%" ? true : false
        values["maxBooking"] = parseInt(values.maxBooking)
        values["maxDuration"] = parseInt(values.maxDuration) * 3600
        values["minDuration"] = parseInt(values.minDuration) * 3600
        values["merchantID"] = localStorage.getItem("merchantID")
        values["picURL"] = fields.venue.picURL
        setFields({...fields, venue: {...fields.venue, ...values}})
        console.log(values);
        fields["venue"] = values
        console.log(fields);

        setLoading(true)
        try {
            const res = await axios.patch(`${base_url}/v3/hobby/venue/${id}`, fields, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
            })
            console.log(res);
            if (res.status === 200) {
                notification.success({
                    message: "Yuhuuu",
                    description:
                      'Mengubah data service berhasil!',
                    placement: "bottomRight",
                });
                setLoading(false)
                history.goBack()
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                  'Terjadi kesalahan dalam mengubah data service',
                placement: "bottomRight",
            });
            setLoading(false)
        }
    }

    // console.log(id);
    console.log(fields);
    // console.log(operations);

    return (
        <React.Fragment>
            {loading ? <Loading /> : (
                <FormService 
                    onFinish={handleSubmitVenue} 
                    fields={fields} 
                    addUploadPhoto={addUploadPhoto} 
                    handleSetImgURL={handleSetImgURL}
                    handleCheckedDay={handleCheckedDay}
                    operations={operations}
                    handleSubmitTimeRate={handleSubmitTimeRate}
                    handleSubmitSchedule={handleSubmitSchedule}
                />
            )}
        </React.Fragment>
    )
}

export default EditService
