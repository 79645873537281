import React, {useState, useEffect} from 'react'
import {useHistory} from "react-router"
import {base_url} from "../../../../../config/url-config"
import {itemfoodcategory} from "../../../../../config/edpoint"
import axios from "axios"
import {notification} from "antd"
import {DetailPageLayout} from "../../../../templates"
import {Loading} from "../../../../../components/atoms"
import FormItem from "../form/Form"

const Create: React.FC = () => {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<{
        categories?: [];
        description?: string;
        discount?: any;
        discountIsInPercentage: boolean;
        harga?: any;
        isPromo?: boolean;
        is_order_fee_percentage: boolean;
        is_sharing_fee_percentage: boolean;
        merchant_id?: string | null | undefined;
        name: string;
        order_fee: number;
        sharing_fee: number;
        status: boolean;
        tags: string[];
        urlphoto: string;
        createdAt?: string;
        updatedAt?: string;
    }>({
        categories: [],
        description: "",
        discount: null,
        discountIsInPercentage: false,
        harga: null,
        isPromo: false,
        is_order_fee_percentage: false,
        is_sharing_fee_percentage: true,
        merchant_id: "",
        name: "",
        order_fee: 0,
        sharing_fee: 0,
        status: false,
        createdAt: "",
        updatedAt: "",
        tags: [],
        urlphoto: ""
    })
    const [category, setCategory] = useState<any>([])

    console.log(category);

    const fetchCategory = async () => {
        try {
            const url = `${base_url}/${itemfoodcategory}`
            const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}
            
            const res = await axios.get(url, {
                headers: headers,
                params: {merchantId: localStorage.getItem("merchantID")}
            })
            console.log(res);
            
            setCategory(res.data.payload);
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
        }
    }

    useEffect(() => {
        fetchCategory()
    }, [])

    const submitPhoto = (path: string) => {
        setFields({ ...fields, urlphoto: path })
    }

    const handleSubmit = async (values: any) => {
        values["tags"] = fields.tags
        values["harga"] = parseInt(values.harga)
        values["discount"] = parseInt(values.discount)
        values["merchantId"] = localStorage.getItem("merchantID")
        
        setLoading(true)
        try {
            const url = `${base_url}/v3/food/product`;
            const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
            const res = await axios.post(url, values, { headers: headers })

            if (res.status === 200) {
                notification["success"]({
                    message: 'Yeay!',
                    description:
                        'Menambahkan data merchant berhasil.',
                });
                setLoading(false)
                history.goBack();
            }
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                    'Terjadi kesalahan saat mengakses data item.',
            });
            setLoading(false)
        }
    }

    return (
        <React.Fragment>
            <DetailPageLayout>
                {loading ? <Loading /> : (
                    <FormItem
                        data={fields}
                        setData={setFields}
                        category={category}
                        submitPhoto={submitPhoto}
                        handleSubmit={handleSubmit}
                    />
                )}
            </DetailPageLayout>
        </React.Fragment>
    )
}

export default Create
