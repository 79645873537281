import React, {useState, useEffect, useContext} from 'react'
import {ContextStore, MerchantStore} from "../../../context/context-store"
import {Layout, Row, Col, notification} from "antd"
import {Header} from "../../organisms"
import {Switch, Route} from "react-router-dom"
import ListMerchant from "./listmerchant/ListMerchant"
import EditMerchant from "./editmerchant/EditMerchant"

import "./merchant.scss"
const {Content} = Layout

const Merchant: React.FC = () => {
    const {login} = useContext(ContextStore)
    const [store, setStore] = useState<{merchantId:string}>({
        merchantId: ""
    })
    
    useEffect(() => {
        if (login.isLogin === true) {
            notification.open({
                message: 'Hai !!!',
                description: `Selamat datang di dashboard manyoption ${localStorage.getItem("userName")}`,
                icon: <i className="fas fa-hand-holding-heart" style={{color: "green"}} />,
                placement:"bottomRight"
            });
        }
    }, [login.isLogin])

    const setMerchantId = (id:string) => {
        setStore({...store, merchantId: id})
    }

    const value = {
        store,
        setMerchantId
    }
    
    return (
        <React.Fragment>
            <Layout>
                <Content>
                    <Header />
                    <Row className="page-container">
                        <Col span={24} className="page">
                            <MerchantStore.Provider value={value}>
                                <Switch>
                                    <Route exact path="/merchant" component={ListMerchant} />;
                                    <Route exact path='/merchant/edit' component={EditMerchant} />;
                                </Switch>
                            </MerchantStore.Provider>
                        </Col>
                    </Row>
                </Content>
            </Layout>
        </React.Fragment>
    )
}

export default Merchant
