import React from 'react'
import {Row, Col} from "antd"

import "./detailtitlesection.scss"

interface Props {
    title: any
}

const DetailTitleSection: React.FC<Props> = (props)=>  {
    return (
        <Row className="detail-title-container">
            <Col span={24}>
                <div className="detail-section-title">{props.title}</div>
                <div className="detail-section-line" />
            </Col>
        </Row>
    )
}

export default DetailTitleSection
