import React from "react"
import {FormatRp, FormatDate} from "../../../../../helpers/Formatter"
import {Row, Col, Menu, Dropdown, Switch, Tag} from "antd"
import {CheckOutlined, CloseOutlined} from "@ant-design/icons"
import {Link} from "react-router-dom"
import {ModalConfirmation} from "../../../../../components/atoms"

function TableColumns(
    loading: string,
    visible: boolean,
    toggle: () => void,
    handleDelete: (id:string) => void,
    // handleSetMerchantId: (id:string) => void,
    handleUpdateStatus: (id:string, status:boolean) => void
) {
    // const query = useQuery()

    const columns = [
        {
            title: "Foto",
            render: (data: { urlphoto: string }) => (
                <img src={data.urlphoto} alt="merchant-pict" style={{ width: "80px", borderRadius: "10px" }} />
            ),
            // width: "12%"
        },
        {
            title: "Nama Item",
            render: (data: { name: string, categories:{name:string}[] }) => (
                <div>
                    <div style={{ fontWeight: "bold", color: "black" }}>{data.name}</div>
                    <div style={{marginTop: "5px"}}>
                        {data.categories.map((category:{name:string}, key:number) => (
                            <Tag key={key} color="green" style={{marginBottom: "5px"}}>{category.name}</Tag>
                        ))}
                    </div>
                </div>
            ),
            // width: "15%"
        },
        {
            title: "Deskripsi",
            dataIndex: "description",
            // width: "25%"
        },
        {
            title: "Harga",
            render: (data: { harga: number }) => (
                <div>{FormatRp(data.harga)}</div>
            ),
            width: "15%"
        },
        {
            title: "Status",
            render: (data: { id:string, status: boolean }) => {
                const handleChange = (checked:boolean) => {
                    handleUpdateStatus(data.id, checked)
                }

                return (
                    <Switch
                        loading={loading === `status${data.id}` ? true : false}
                        defaultChecked={data.status}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        onChange={handleChange}
                    />
                )
            },
            // width: "17%"
        },
        {
            title: "Tanggal & Waktu",
            render: (data: { createdAt: string, updatedAt: string }) => (
                <div>
                    <Row>
                        <Col span={24} style={{ color: "black" }}>
                            Dibuat pada :
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {FormatDate(data.createdAt)}
                        </Col>
                    </Row>
                    <Row style={{ paddingTop: "15px" }}>
                        <Col span={24} style={{ color: "black" }}>
                            Diubah pada :
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {FormatDate(data.updatedAt)}
                        </Col>
                    </Row>
                </div>
            ),
            width: "20%"
        },
        {
            title: "Aksi",
            render: (data: { id: string }) => {

                const menu = (
                    <Menu style={{ width: "90px" }}>
                        <Menu.Item>
                            <Link to={`/item/edit?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-edit" />
                                    </Col>
                                    <Col span={16}>
                                        Ubah
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/item/detail?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Detail
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Row
                                onClick={toggle}
                            >
                                <Col span={8}>
                                    <i className="far fa-trash-alt" />
                                </Col>
                                <Col span={16}>
                                    Hapus
                                </Col>
                            </Row>
                            <ModalConfirmation
                                // loading={loading === "delete" ? true : false}
                                visible={visible}
                                toggle={toggle}
                                submitDelete={() => handleDelete(data.id)}
                            />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-action" style={{ color: "rgb(4, 163, 4)", fontSize: "16px", cursor: "pointer" }}>
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ]

    return {
        columns
    }
}

export default TableColumns