import React from 'react'

import "./reportbox.scss"

interface Props {
    boxicon: any,
    boxname: string,
    boxvalue?: any 
}

const ReportBox: React.FC <Props>= (props) => {
    return (
        <div className="report-box-container">
            <div className="report-box-icon">
                {props.boxicon}
            </div>
            <div className="report-box-name">
                {props.boxname}
            </div>
            <div className="report-value-section">
                {props.boxvalue}
            </div>
        </div>
    )
}

export default ReportBox
