import {useState, useEffect} from "react"
import {useHistory} from "react-router"
import {IDetail} from "../ItemModel"
import axios from "axios"
import {base_url} from "../../../../../config/url-config"
import {itemJasa} from "../../../../../config/edpoint"
import {notification} from "antd"

export const useModelDetail = (itemId:string = "") => {
    const history = useHistory()
    const [loading, setLoading] = useState<boolean>(false)
    const [fields, setFields] = useState<IDetail>({
        image: "",
        name: "",
        description: "",
        maxQty: null,
        price: null,
        discount: null,
        createdAt: "",
        createdByName: "",
        updatedAt: "",
        updatedByName: "",
        id: "",
        isActive: false,
        itemCategories: [],
        rangePrice: "",
    })
    const [category, setCategory] = useState<{
        label:string, 
        value:string
    }[]>([])
    const headers = {'Authorization': `Bearer ${localStorage.getItem("accessToken")}`}

    const fetchData = async(itemId:string) => {
        setLoading(true)
        try {
            const url = `${base_url}/${itemJasa}/${itemId}`

            const res = await axios.get(url, {
                headers: headers
            })
            setFields(res.data.payload)
            setLoading(false)
        } catch (error) { 
            setLoading(false)
            notification.error({
                message: 'Wops!',
                description:
                  'Terjadi kesalahan saat mengakses data item merchant',
                placement:"topRight"
            });
        }
    }

    const fetchCategory = async() => {
        try {
            const url = `${base_url}/${itemJasa}/category`

            const res = await axios.get(url, {
                headers: headers,
                params: {entityID: localStorage.getItem("merchantId")}
            })
            setCategory(res.data.payload.map((datum:{name:string, id:string}) => {
                return {label: datum.name, value: datum.id}
            }))
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                'Terjadi kesalahan saat mengakses data item.',
            });
        }
    }

    useEffect(() => {
        itemId !== "" && fetchData(itemId)
        fetchCategory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemId])

    const submitPhoto = (path:string) => {
        setFields({...fields, image: path})
    }

    const handleSubmit = async(values:{image:string, maxQty:any, price:any, discount:any, itemCategories: any}) => {
        values["image"] = fields.image
        values["maxQty"] = parseInt(values.maxQty)
        values["price"] = parseInt(values.price)
        values["discount"] = parseInt(values.discount)
        values["itemCategories"] = [values.itemCategories]
        setLoading(true)
        try {
            const url = itemId !== "" ? `${base_url}/${itemJasa}/${itemId}` : `${base_url}/${itemJasa}`

            itemId !== "" ? (
                await axios.put(url, values, {
                    headers: headers,
                    params: {entityID: localStorage.getItem("merchantId")}
                })
            ) : (
                await axios.post(url, values, {
                    headers: headers,
                    params: {entityID: localStorage.getItem("merchantId")}
                })
            )
            notification["success"]({
                message: 'Yeay!',
                description:
                    `${itemId !== "" ? "Memperbaharui" : "Menambahkan"} data item berhasil.`,
            });
            setLoading(false)
            history.goBack()
        } catch (error) {
            notification.error({
                message: 'Ups!',
                description:
                `Terjadi kesalahan saat ${itemId !== "" ? "Memperbaharui" : "Menambahkan"} data item.`,
            });
            setLoading(false)
        }
    }

    return {
        loading,
        fields,
        category,
        submitPhoto,
        handleSubmit
    }
}