import React, {useState} from 'react'
import {Button} from "antd"
import {Row, Col, Modal} from "antd"
import Operations from "./Operations"

import "./schedule.scss"

interface Props {
    handleCheckedDay:(index:number, active:boolean) => void,
    operations:{day:number, active?:boolean, timeRate:{}[]}[],
    handleSubmitTimeRate:(values:{
        deposit:any, 
        discount:any, 
        timeStart:string,
        timeEnd:string,
        isDiscPercentage:any,
        rate:any,
        hour:any
    }, indextime:number, indexday:number) => void,
    handleSubmitSchedule:() => void
}

const Schedule: React.FC<Props> = (props) => {
    const days = ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
    const [modal, setModal] = useState<boolean>(false)

    const toggle = () => {
        setModal(!modal)
    }

    const handleSave = () => {
        props.handleSubmitSchedule()
        toggle()
    }

    return (
        <React.Fragment>
            <Button className="button-modal-set-schedule" onClick={toggle}>
                <i className="fas fa-cog" style={{fontSize: "16px"}} />
            </Button>
            <Modal
                visible={modal}
                title="Jadwal dan Harga"
                onCancel={toggle}
                className="schedule-modal"
                footer={[<div key="footer"></div>]}
            >
                <Row className="table-head-section">
                    <Col span={24}>
                        <Row className="table-head-container">
                            <Col span={2} className="head-col-day">Hari</Col>
                            <Col span={4} className="head-col-hours">Jam</Col>
                            <Col span={4} className="head-col-deposit">Deposit (IDR)</Col>
                            <Col span={5} className="head-col-price">Harga (IDR)</Col>
                            <Col span={9} className="head-col-discount">Discount</Col>
                        </Row>
                    </Col>
                </Row>
                {props.operations.map((operation, keydays) => (
                    <Operations 
                        days={days}
                        operation={operation}
                        key={keydays}
                        handleCheckedDay={props.handleCheckedDay}
                        handleSubmitTimeRate={props.handleSubmitTimeRate}
                    />
                ))}
                <Row style={{paddingTop: "20px"}}>
                    <Col span={24} style={{textAlign: "center"}}>
                        <Button 
                            className="button-save-schedule"
                            onClick={handleSave}
                        >
                            Simpan
                        </Button>
                        <Button 
                            className="button-cancel-schedule"
                            onClick={toggle}
                        >
                            Batal
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </React.Fragment>
    )
}

export default Schedule
