import React from 'react'
import {FormatRp, FormatDate} from "../../../helpers/Formatter"
import {Link} from "react-router-dom"
import {PageLayout} from "../../templates"
import {Search, Table} from "../../../components/atoms"
import FilterTopUp from "./filtertopup/FilterTopUp"
import ExportTopUp from "./exporttopup/ExportTopup"
import {data} from "./TopupModel"

import "./topup.scss"

const Topup: React.FC = () => {
    const columns = [
        {
          title: "Nomor Topup",
          dataIndex: "topupNumber",
        },
        {
          title: "Jumlah Topup",
          render: (datum: { totalTopup: number }) => (
            <div>{FormatRp(datum.totalTopup)}</div>
          ),
        },
        {
          title: "Status",
          dataIndex: "status",
        },
        {
          title: "Tanggal Topup",
          render: (data: {topupDate: string}) => (
            <div>{FormatDate(data.topupDate)}</div>
          )
        },
        {
          title: "Nomor VA",
          dataIndex: "nova",
        },
        {
          title: "Aksi",
          render: () => (
            <Link to="/topup/detail">
              <i className="far fa-eye topup-detail-button" />
            </Link>
          ),
        },
    ];

    return (
        <React.Fragment>
            <PageLayout 
                rightTop={<Search />}
                leftTop={
                    <div>
                        <FilterTopUp />
                        <ExportTopUp />
                    </div>
                }
                content={<Table columns={columns} data={data} />}
            />
        </React.Fragment>
    )
}

export default Topup
