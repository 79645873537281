import React from 'react'
import {useLocation} from "react-router-dom"
import {Row, Col} from "antd"
import {PageTitle} from "../../../components/atoms"
import {UserAccount} from "../../../components/molecules"

import "./header.scss"

const Header: React.FC = () => {
    const location = useLocation();
    const pageTitle = location.pathname.split("/").filter((arr) => arr !== "").map((word) => word[0].toUpperCase() + word.substr(1)).join(" ");

    return (
        <Row className="header-container">
            <Col span={18} className="page-title-container">
                <Row align="middle" style={{height: "100%"}}>
                    <Col>
                        <PageTitle pageTitle={pageTitle} />
                    </Col>
                </Row>
            </Col>
            <Col span={6} className="user-info-container">
                <div className="user-info">
                    <Row align="middle" style={{height: "100%"}}>
                        <Col span={24}>
                            <UserAccount />
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    )
}

export default Header
