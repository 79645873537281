import React, {useState} from 'react'
import {FeatureButton, Modal} from "../../../../components/atoms"
import {Form, DatePicker, Button} from "antd"

const { RangePicker } = DatePicker;

const ExportSettlement: React.FC = () => {
    const [visible, setVisible] = useState<boolean>(false)

    const toggle = () => {
        setVisible(!visible)
    }

    const handleSubmit = (values: {}) => {
        console.log(values);
    }

    return (
        <React.Fragment>
            <FeatureButton 
                label={<div><i className="fas fa-file-export" /> Export</div>}
                onClick={toggle} 
            />

            <Modal visible={visible} title="Export Settlement" onCancel={toggle}>
                <Form 
                    layout="vertical" 
                    requiredMark={false}
                    onFinish={handleSubmit}
                    preserve={false}
                >
                    <Form.Item 
                        label="Tanggal Transfer" 
                        name="createdAt"
                    >
                        <RangePicker 
                            className="date-create-update" 
                            format="DD MMMM YYYY" 
                            placeholder={["Pilih tanggal", "Pilih tanggal"]}
                        />
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <div style={{ textAlign: "center" }}>
                            <Button key="back" className="cancel-button" onClick={toggle}>
                                Batal
                            </Button>{" "}
                            <Button
                                key="submit"
                                type="primary"
                                className="ok-button"
                                htmlType="submit"
                                // loading={loading}
                            >
                                Oke
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </Modal>
        </React.Fragment>
    )
}

export default ExportSettlement
