import React, { useEffect, useState } from 'react'
import axios from "axios"
import { base_url } from "../../../../../config/url-config"
import { Form, Row, Col, Input, Select, Switch, notification } from "antd"
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import FormOperationalHour from "./formoperationalhour/FormOperationalHour"
import { BackButton, OkButton, UploadPhoto } from "../../../../../components/atoms"
import { Tags } from '../../../../organisms';
import {Link} from "react-router-dom"
const { Option } = Select;

interface Props {
    data: any
    operational: {
        active?:boolean
        day: number
        merchantID?: string | null | undefined
        openHour?: string | null
        closeHour?: null | string
    }[],
    submitOperationHourStatus: (e: any, index: number) => void,
    submitOperationHourOpenCLose: (time: any, timeString: any, index: number) => void,
    submitOperationalHour: () => void,
    handleSubmit: (values: any) => void,
    setFields: any
}

const FormMerchant: React.FC<Props> = (props) => {
    // const navigate: { goBack: () => void } = useNavigate();
    const [category, setCategory] = useState<{ "id": String, "name": String }[]>([]);

    useEffect(() => {
        let isRendered = true;
        async function fetchCategory() {
            try {
                const url = `${base_url}/v3/food/category/get_all`;
                const headers = { 'Authorization': `Bearer ${localStorage.getItem("accessToken")}` };
                const resp = await axios.get(url, { headers: headers });
                // let categories = resp.data.payload.map((value: any) => ({ id: value.id, name: value.name }));
                if (isRendered) {
                    setCategory(resp.data.payload);
                }
                return null;
            } catch (error) {
                notification.error({
                    message: "Ups",
                    description:
                        'Terjadi kesalahan dalam menghapus data service',
                    placement: "bottomRight",
                });
            }
        }

        fetchCategory();

        return () => {
            isRendered = false;
        }

    }, [])

    function handleChange(value: any) {
        const categories = value.map((category: any) => JSON.parse(category));
        props.setFields({ ...props.data, categories: categories });
    }

    const handleTags = (tags: string[]) => {
        props.setFields({
            ...props.data,
            tags: tags
        })
    }

    return (
        <React.Fragment>
            <Form
                onFinish={props.handleSubmit}
            >
                <Row>
                    <Col span={12} className="left-section">
                        <Row className="row-edit-hobby">
                            <Col>
                                <Form.Item
                                    name="urlPhoto"
                                    valuePropName="file"
                                    initialValue={props.data.urlPhoto}
                                >
                                    <UploadPhoto
                                        featureName="food"
                                        getLink={(url) => props.setFields({ ...props.data, urlPhoto: url })}
                                        imageURL={props.data.urlPhoto}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row className="row-edit-hobby">
                            <Col span={7}>Nama</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input}
                                    name="name"
                                    initialValue={props.data.name}
                                    placeholder="Masukkan nama merchant"
                                    message={[{ required: true, message: 'Nama merchant wajib diisi!' }]}
                                />
                            </Col>
                        </Row>
                        {/* <RowCol label="Nama">
                            
                        </RowCol> */}

                        <Row className="row-edit-hobby">
                            <Col span={7}>Deskripsi</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input.TextArea}
                                    name="description"
                                    initialValue={props.data.description}
                                    placeholder="Masukkan deskripsi"
                                    rows={3}
                                />
                            </Col>
                        </Row>
                        {/* <RowCol label="Deskripsi">
                            
                        </RowCol> */}

                        <Row className="row-edit-hobby">
                            <Col span={7}>Kategori</Col>
                            <Col span={14}>
                                <Select
                                    mode="multiple"
                                    placeholder="Pilih Kategori"
                                    defaultValue={props.data.categories?.map((value:any) => JSON.stringify(value))}
                                    onChange={handleChange}
                                    style={{ width: '100%' }}
                                >
                                    {category.map((value, key) => <Option value={JSON.stringify(value)} key={key}>{value.name}</Option>)}
                                </Select>
                            </Col>
                        </Row>
                        {/* <RowCol label="Kategori">
                            <Select
                                mode="multiple"
                                placeholder="Pilih Kategori"
                                defaultValue={props.data.categories?.map(value => JSON.stringify(value))}
                                onChange={handleChange}
                                style={{ width: '100%' }}
                            >
                                {category.map((value, key) => <Option value={JSON.stringify(value)} key={key}>{value.name}</Option>)}
                            </Select>
                        </RowCol> */}

                        <Row className="row-edit-hobby">
                            <Col span={7}>Tags</Col>
                            <Col span={14}>
                                <Tags
                                    tags={props.data.tags}
                                    add={handleTags}
                                    remove={handleTags}
                                />
                            </Col>
                        </Row>
                        {/* <RowCol label="Tags">
                            
                        </RowCol> */}

                        <Row className="row-edit-hobby">
                            <Col span={7}>Sharing Fee</Col>
                            <Col span={10}>
                                <TextInput
                                    component={Input}
                                    initialValue={props.data.sharingFee}
                                    name="sharingFee"
                                    type="number"
                                    placeholder="Masukkan sharing fee"
                                    message={[
                                        { required: true, message: 'Sharing fee wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!' }
                                    ]}
                                />

                            </Col>
                            <Col span={4} style={{ paddingLeft: "10px" }}>
                                <Form.Item
                                    name="sharingFeeIsInPercentage"
                                    initialValue={props.data.sharingFeeIsInPercentage.toString()}
                                    rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                >
                                    <Select>
                                        <Select.Option value={'true'}>%</Select.Option>
                                        <Select.Option value={'false'}>idr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Order Fee</Col>
                            <Col span={10}>
                                <TextInput
                                    component={Input}
                                    initialValue={props.data.orderFee}
                                    name="orderFee"
                                    type="number"
                                    placeholder="Masukkan sharing fee"
                                    message={[
                                        { required: true, message: 'Sharing fee wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]{0,6}$"), message: 'Sharing fee yang anda input melebihi batas!' }
                                    ]}
                                />

                            </Col>
                            <Col span={4} style={{ paddingLeft: "10px" }}>
                                <Form.Item
                                    name="orderFeeIsInPercentage"
                                    initialValue={props.data.orderFeeIsInPercentage.toString()}
                                    rules={[{ required: true, message: 'Satuan sharing fee wajib dipilih!' }]}
                                >
                                    <Select>
                                        <Select.Option value={'true'}>%</Select.Option>
                                        <Select.Option value={'false'}>idr</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Alamat</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input.TextArea}
                                    placeholder="Masukkan alamat merchant"
                                    name="address"
                                    initialValue={props.data.address}
                                    message={[{ required: true, message: 'Alamat wajib diisi!' }]}
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Email</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input}
                                    placeholder="Masukkan email merchant"
                                    name="email"
                                    initialValue={props.data.email}
                                    message={[
                                        { required: true, message: 'Email merchant wajib diisi!' },
                                        { type: "email", message: 'Format email yang kamu isi salah!' },
                                    ]}
                                />

                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>No. Telepon</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input}
                                    placeholder="Masukkan nomor telepon merchant"
                                    name="phoneNumber"
                                    initialValue={props.data.phoneNumber}
                                    message={[
                                        { required: true, message: 'Nomor Telepon merchant wajib diisi!' },
                                        { pattern: new RegExp("^[0-9]*$"), message: 'Format Nomor Telepon harus berupa angka!' }
                                    ]}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} className="right-section">
                        <Row className="row-edit-hobby">
                            <Col span={7}>Latitude</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input}
                                    placeholder="Masukkan latitude"
                                    name="latitude"
                                    initialValue={props.data.location?.latitude}
                                    message={[{ required: true, message: 'Latitude wajib diisi!' }]}
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Longitude</Col>
                            <Col span={14}>
                                <TextInput
                                    component={Input}
                                    placeholder="Masukkan longitude"
                                    name="longitude"
                                    initialValue={props.data.location?.longitude}
                                    message={[{ required: true, message: 'Longitude wajib diisi!' }]}
                                />
                            </Col>
                        </Row>
                        <Row className="row-edit-hobby">
                            <Col span={7}>Jam Operasional</Col>
                            <Col span={12}>
                                <FormOperationalHour
                                    data={props.operational}
                                    submitOperationHourStatus={props.submitOperationHourStatus}
                                    submitOperationHourOpenCLose={props.submitOperationHourOpenCLose}
                                    submitOperationalHour={props.submitOperationalHour}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col span={7}>Status Aktif</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="status"
                                    valuePropName="checked"
                                    initialValue={props.data.status}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked={props.data.status}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={7}>Buka/Tutup</Col>
                            <Col span={12}>
                                <Form.Item
                                    name="open"
                                    valuePropName="checked"
                                    initialValue={props.data.open ? true : false}
                                >
                                    <Switch
                                        checkedChildren={<CheckOutlined />}
                                        unCheckedChildren={<CloseOutlined />}
                                        defaultChecked={props.data.open ? true : false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{ paddingTop: "20px" }}>
                    <Col span={24}>
                        <Form.Item>
                            <OkButton>Simpan</OkButton>
                            <Link to="/merchant">
                                <BackButton>Kembali</BackButton>
                            </Link>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )
}

interface ITextInput {
    name: string,
    initialValue: any,
    message?: {}[],
    placeholder?: string,
    component: any,
    rows?: number,
    type?: string
}

function TextInput(props: ITextInput) {

    const children = props.component === Input.TextArea ?
        <Input.TextArea className="form-input" rows={props.rows ? props.rows : 3} placeholder={props.placeholder} /> :
        <props.component className="form-input" placeholder={props.placeholder} type={props.type} />



    return <Form.Item
        name={props.name}
        initialValue={props.initialValue}
        rules={props.message}

    >
        {children}
    </Form.Item>
}

export default FormMerchant
