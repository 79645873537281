import React, { useState, useEffect } from 'react'
import axios from "axios"
import { base_url } from "../../../config/url-config"
import { Link } from "react-router-dom"
import { Row, Col, Dropdown, Menu, Pagination, notification } from "antd"
import { FormatDate } from "../../../helpers/Formatter"
import { PageLayout } from "../../templates"
import { Search, Table, ModalConfirmation, FeatureButton, Loading } from "../../../components/atoms"
import moment from 'moment';
// import {data} from "./ServiceModel"

import "./service.scss"
import { CalendarOutlined } from '@ant-design/icons'

const Venue: React.FC = () => {
    // const params = {merchantID: localStorage.getItem("merchantID")}
    const [visible, setVisible] = useState<boolean>(false)
    const [fields, setFields] = useState<{ page: number, total_record: number, limit: number, records: [] }>({
        page: 1,
        total_record: 0,
        limit: 10,
        records: []
    })
    const [params, setParams] = useState<{}>({
        merchantID: localStorage.getItem("merchantID"),
        pageNumber: 1,
        pageLimit: 10,
        search: ""
    })
    const [loading, setLoading] = useState<boolean>(false)

    const fetchData = async (queryparams: {}) => {
        setLoading(true)
        try {
            const res = await axios.get(`${base_url}/v3/hobby/venue`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                },
                params: queryparams
            })

            if (res.status === 200) {
                setFields(res.data.payload)
                setLoading(false)
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    useEffect(() => {
        fetchData(params)
    }, [params])

    const toggle = () => {
        setVisible(!visible)
    }

    const handleDelete = async (id: number) => {
        console.log(id);
        setLoading(true)
        try {
            const res = await axios.delete(`${base_url}/v3/hobby/venue/${id}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
                }
            })
            console.log(res);
            if (res.status === 200) {
                toggle()
                fetchData(params)
                notification.success({
                    message: "Yuhuuu",
                    description:
                        'Menghapus data service berhasil!',
                    placement: "bottomRight",
                });
                setLoading(false)
            }
        } catch (error) {
            notification.error({
                message: "Ups",
                description:
                    'Terjadi kesalahan dalam menghapus data service',
                placement: "bottomRight",
            });
            setLoading(false)
        }
    }

    const handlePageChange = (page: number | undefined, pageSize: number | undefined) => {
        setParams({ ...params, pageNumber: page })
    }

    const handleSubmitSearch = (e: any) => {
        if (e.which === 13) {
            setParams({ ...params, search: e.target.value })
        }
    }
    // console.log(fields);
    // query.set('startDate', value.hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'));
    // query.set('endDate', value.hours(23).minutes(59).seconds(59).format('YYYY-MM-DDTHH:mm:ss'));

    const startDate = moment().hours(0).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss');
    const endDate = moment().hours(23).minutes(59).seconds(59).format('YYYY-MM-DDTHH:mm:ss');

    const columns = [
        {
            title: "Foto",
            render: (data: { picURL: string[] }) => (
                <img src={data.picURL.length > 0 ? data.picURL[0] : ""} alt="merchant's venue" style={{ width: "90px", borderRadius: "10px" }} />
            ),
            width: "12%"
        },
        {
            title: "Nama Venue",
            dataIndex: "name",
            width: "12%"
        },
        {
            title: "Kategori",
            dataIndex: "category",
        },
        {
            title: "Maksimal Pemesanan",
            dataIndex: "maxBooking",
            width: "10%"
        },
        {
            title: "Deskripsi",
            dataIndex: "description",
            width: "25%"
        },
        {
            title: "Status",
            render: (data: { isOpen: boolean }) => (
                <div>{data.isOpen ? "Buka" : "Tutup"}</div>
            )
        },
        {
            title: "Tanggal",
            render: (data: { createdAt: string, updatedAt: string }) => {
                return (
                    <div>
                        <Row>
                            <Col span={24} style={{ color: "black" }}>
                                Dibuat pada :
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {FormatDate(data.createdAt)}
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: "15px" }}>
                            <Col span={24} style={{ color: "black" }}>
                                Diubah pada :
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                {FormatDate(data.updatedAt)}
                            </Col>
                        </Row>
                    </div>
                )
            },
            width: "16%"
        },
        {
            title: "Aksi",
            render: (data: { id: number }) => {
                const menu = (
                    <Menu style={{ width: "90px" }}>
                        <Menu.Item>
                            <Link to={`/service/edit?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-edit" />
                                    </Col>
                                    <Col span={16}>
                                        Ubah
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/service/detail?id=${data.id}`}>
                                <Row>
                                    <Col span={8}>
                                        <i className="far fa-eye" />
                                    </Col>
                                    <Col span={16}>
                                        Detail
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Link to={`/service/jadwal?venueID=${data.id}&isTimeShowed=true&startDate=${startDate}&endDate=${endDate}`}>
                                <Row>
                                    <Col span={8}>
                                        <CalendarOutlined />
                                    </Col>
                                    <Col span={16}>
                                        Jadwal
                                    </Col>
                                </Row>
                            </Link>
                        </Menu.Item>
                        <Menu.Item>
                            <Row onClick={toggle}>
                                <Col span={8}>
                                    <i className="far fa-trash-alt" />
                                </Col>
                                <Col span={16}>
                                    Hapus
                                </Col>
                            </Row>
                            <ModalConfirmation
                                visible={visible}
                                toggle={toggle}
                                submitDelete={() => handleDelete(data.id)}
                            />
                        </Menu.Item>
                    </Menu>
                );

                return (
                    <Dropdown overlay={menu} placement="bottomCenter">
                        <div className="button-delete">
                            <i className="fas fa-list-ul" />
                        </div>
                    </Dropdown>
                )
            }
        },
    ];

    return (
        <React.Fragment>
            <PageLayout
                rightTop={<Search onKeyPress={(e: any) => handleSubmitSearch(e)} />}
                leftTop={
                    <div>
                        <Link to="/service/tambah">
                            <FeatureButton label={<div><i className="fas fa-plus-square" /> Tambah</div>} />
                        </Link>
                    </div>
                }
                content={
                    <React.Fragment>
                        {loading ? <Loading /> : (
                            <div>
                                <div>
                                    <Table
                                        columns={columns}
                                        data={fields.records}
                                        rowKey="id"
                                        pagination={false}
                                    />
                                </div>
                                <div style={{ paddingTop: "20px", textAlign: "right" }}>
                                    <Pagination current={fields.page} pageSize={10} total={fields.total_record} onChange={handlePageChange} />
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    )
}

export default Venue
