import React, { useState, useRef, useEffect } from 'react';
import { Tag, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

interface IProps {
    tags: string[],
    add: (tags: string[]) => void
    remove: (tags: string[]) => void
}

const Tags: React.FC<IProps> = (props) => {
    const inputRef = useRef<any>(null);
    const [tags, setTags] = useState<string[]>([]);
    const [inputVisible, setinputVisible] = useState<boolean>(false);
    const [inputValue, setinputValue] = useState<any>("");

    useEffect(() => {
        var isRendered = true;
        if (isRendered) {
            setTags(props.tags);
        }
        return () => {
            isRendered = false;
        }
    }, [setTags, props.tags])


    const handleClose = (removedTag: any) => {
        const listTags = tags?.filter(tag => tag !== removedTag);
        setTags(listTags);
        props.remove(listTags);
    }

    const showInput = async () => {
        await setinputVisible(true);
        inputRef.current.focus();

    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setinputValue(e.target.value.toString());
    };

    const handleInputConfirm = () => {
        if (inputValue && tags?.indexOf(inputValue) === -1) {
            setTags([...tags, inputValue]);
            props.add([...tags, inputValue]);
        }
        setinputVisible(false);
        setinputValue('');


    };


    const forMap = (tag: any) => {
        const tagElem = (
            <Tag
                closable
                onClose={(e:any) => {
                    e.preventDefault();
                    handleClose(tag);
                }}
            >
                {tag}
            </Tag>
        );
        return (
            <span key={tag} style={{ display: 'inline-block', marginBottom: "5px" }}>
                {tagElem}
            </span>
        );
    };

    const tagChild = tags !== undefined ? tags.map(forMap) : [];

    return (
        <>
            <div >
                {tagChild}
                {inputVisible && (
                    <Input
                        ref={inputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue.toString()}
                        onChange={handleInputChange}
                        onBlur={handleInputConfirm}
                        onPressEnter={handleInputConfirm}
                    />
                )}
                {!inputVisible && (
                    <Tag onClick={showInput} className="site-tag-plus">
                        <PlusOutlined /> New Tag
                    </Tag>
                )}
            </div>
        </>
    );

}

export default Tags
