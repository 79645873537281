import Axios from 'axios';
import { useEffect, useState } from 'react';
import { base_url } from '../../../config/url-config';

export interface Record {
    time: string;
    value?: any;
}

export interface Payload {
    total_record: number;
    total_page: number;
    records: Record[];
    offset: number;
    limit: number;
    page: number;
    prev_page: number;
    next_page: number;
}

export interface RootObject {
    statusCode: number;
    message: string;
    payload: Payload;
}

const dummyData = {
    "statusCode": 200,
    "message": "OK",
    "payload": {
        "total_record": 0,
        "total_page": 0,
        "records": [],
        "offset": 0,
        "limit": 0,
        "page": 0,
        "prev_page": 0,
        "next_page": 0
    }
}

function useSchedule(query?: string) {
    const [loading, setLoading] = useState<boolean>(true);
    const [response, setResponse] = useState<RootObject>(dummyData);

    console.log(query);

    useEffect(() => {

        const url = `${base_url}/v3/hobby/booking?${query}`;
        const headers = {
            'Authorization': `Bearer ${localStorage.getItem("accessToken")}`
        }

        async function fetchData() {
            setLoading(true);
            try {
                const res = await Axios.get(url, {
                    headers: headers
                })

                setResponse(res.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        }
        fetchData();

    }, [query])



    return {
        response,
        setResponse,
        loading,
        setLoading
    }
}

export default useSchedule;