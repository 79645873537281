import React from 'react'
import {Route} from "react-router-dom"
import List from "./list/List"
import Detail from "./detail/Detail"
import Create from "./create/Create"
import Edit from "./edit/Edit"

const ItemFood: React.FC = () => {
    return (
        <React.Fragment>
            <Route path="/item" exact={true} render={() => <List />} />
            <Route path="/item/detail" exact={true} render={() => <Detail />} />
            <Route path="/item/tambah" exact={true} render={() => <Create />} />
            <Route path="/item/edit" exact={true} render={() => <Edit />} />
        </React.Fragment>
    )
}

export default ItemFood
