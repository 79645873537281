import React from "react"
import {
    // HomeOutlined,
    // UserOutlined,
    // UnorderedListOutlined,
    ContainerOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    PicLeftOutlined,
    // PlusSquareOutlined,
    // CarryOutOutlined,
    MessageOutlined
  } from "@ant-design/icons";

const menus = [
    // { name: "Beranda", url: "/beranda", icon: <HomeOutlined /> },
    // { name: "Akun", url: "/akun", icon: <UserOutlined /> },
    { name: "Item", url: "/item", icon: <ContainerOutlined /> },
    { name: "Service", url: "/service", icon: <ShopOutlined /> },
    { name: "Transaksi", url: "/transaksi", icon: <ShoppingCartOutlined /> },
    { name: "Voucher", url: "/voucher", icon: <PicLeftOutlined /> },
    // { name: "Top Up", url: "/topup", icon: <PlusSquareOutlined /> },
    // { name: "Settlement", url: "/settlement", icon: <CarryOutOutlined /> },
    { name: "Review", url: "/review", icon: <MessageOutlined /> },
]

export default menus
